
interface Props {
  text?: string;
  disabled?: boolean;
  colorRed?: boolean;
  loading?: boolean;
  nuxt?: boolean;
  to?: string;
  replace?: boolean;
}
// @ts-ignore
const __sfc_main = {};
__sfc_main.props = {
  text: {
    key: "text",
    required: false,
    type: String
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean
  },
  colorRed: {
    key: "colorRed",
    required: false,
    type: Boolean
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean
  },
  nuxt: {
    key: "nuxt",
    required: false,
    type: Boolean,
    default: false
  },
  to: {
    key: "to",
    required: false,
    type: String,
    default: undefined
  },
  replace: {
    key: "replace",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
