import { gql } from '@apollo/client/core';
import { getGuidanceHistoriesByDateRange } from '~/src/infrastructure/apis/graphql/query/queries';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidanceHistoryRepo = {
  /**
   * 日付範囲ごとの指導履歴を取得
   */
  fetchByDateRange: async (studentId: number, startDate: string, endDate: string) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, startDate, endDate },
      query: gql(getGuidanceHistoriesByDateRange)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getGuidanceHistoriesByDateRange, variables', {
      studentId,
      startDate,
      endDate
    });
    logger?.debug(
      'getGuidanceHistoriesByDateRange date',
      data.getGuidanceHistoriesByDateRange
    );
    return [...data.getGuidanceHistoriesByDateRange];
  }
} as const;
