import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { Query } from '~/src/domain/entities/schema';
import { ClassroomGuidanceDateRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/classroomGuidanceDate';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { getClassroomGuidanceDatesByDateRange } from '~/src/infrastructure/apis/graphql/query/queries';

export const classroomGuidanceDateRepo: ClassroomGuidanceDateRepositoryInterface = {
  fetchByDateRange: async (classroomId, startDate, endDate) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { classroomId, startDate, endDate },
      query: gql(getClassroomGuidanceDatesByDateRange)
    });
    if (errors?.length && errors[0]?.message) return err(errors);
    logger?.debug(
      'getClassroomGuidanceDatesByDateRange',
      data.getClassroomGuidanceDatesByDateRange
    );
    return ok([...data.getClassroomGuidanceDatesByDateRange]);
  }
};
