import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  applyGuidanceKomasFromStudentContract,
  createStudentContract,
  deleteGuidanceKomasFromStudentContract,
  deleteStudentContract,
  updateStudentContract
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  getStudentContract,
  getStudentContractsByDateRange
} from '~/src/infrastructure/apis/graphql/query/queries';
import {
  GuidanceKomasApplyInput,
  GuidanceKomasDeleteInput,
  Mutation,
  Query,
  StudentContractCreateInput,
  StudentContractDeleteInput,
  StudentContractUpdateInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const studentContractRepo = {
  // 契約を取得する
  fetch: async (id: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { id },
      query: gql(getStudentContract)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getStudentContract', data.getStudentContract);
    return { ...data.getStudentContract };
  },

  // 日付範囲ごとに契約を取得する
  fetchListByDateRange: async (
    studentId: number,
    startedDate?: string | null,
    endDate?: string | null
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, startedDate, endDate },
      query: gql(getStudentContractsByDateRange)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getStudentContractsByDateRange',
      'variables: ',
      { studentId, startedDate, endDate },
      'data: ',
      data.getStudentContractsByDateRange
    );
    return [...data.getStudentContractsByDateRange];
  },

  // 契約を作成する
  create: async (input: StudentContractCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createStudentContract)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createStudentContract input', input);
    logger?.debug('createStudentContract response', data.createStudentContract);
    return { ...data.createStudentContract };
  },
  // 契約を編集する
  update: async (input: StudentContractUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateStudentContract)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateStudentContract input', input);
    logger?.debug('updateStudentContract data', data.updateStudentContract);
    return { ...data.updateStudentContract };
  },
  // 生徒契約を削除する
  delete: async (input: StudentContractDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteStudentContract)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteStudentContract input', input);
    logger?.debug('deleteStudentContract data', data.deleteStudentContract);
    return { ...data.deleteStudentContract };
  },
  /**
   * 契約情報の指導コマ反映
   */
  apply: async (input: GuidanceKomasApplyInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(applyGuidanceKomasFromStudentContract)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('applyGuidanceKomasFromStudentContract input', { ...input });
    logger?.debug(
      'applyGuidanceKomasFromStudentContract response',
      data.applyGuidanceKomasFromStudentContract
    );
    return { ...data.applyGuidanceKomasFromStudentContract };
  },
  /**
   * 契約からコマを一括削除
   */
  bulkDeleteGuidanceKomas: async (input: GuidanceKomasDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteGuidanceKomasFromStudentContract)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteGuidanceKomasFromStudentContract input', { ...input });
    logger?.debug(
      'deleteGuidanceKomasFromStudentContract response',
      data.deleteGuidanceKomasFromStudentContract
    );
    return data.deleteGuidanceKomasFromStudentContract;
  }
} as const;
