import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  createTeacherShiftKoma,
  deleteTeacherShiftKoma,
  updateTeacherShiftKoma
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  Mutation,
  TeacherShiftKomaCreateInput,
  TeacherShiftKomaDeleteInput,
  TeacherShiftKomaUpdateInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const teacherShiftKomaRepo = {
  create: async (input: TeacherShiftKomaCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createTeacherShiftKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createTeacherShiftKoma input', input);
    logger?.debug('createTeacherShiftKoma data', data.createTeacherShiftKoma);
    return { ...data.createTeacherShiftKoma };
  },

  update: async (input: TeacherShiftKomaUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateTeacherShiftKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateTeacherShiftKoma input', input);
    logger?.debug('updateTeacherShiftKoma data', data.updateTeacherShiftKoma);
    return { ...data.updateTeacherShiftKoma };
  },

  delete: async (input: TeacherShiftKomaDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteTeacherShiftKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteTeacherShiftKoma input', input);
    logger?.debug('deleteTeacherShiftKoma data', data.deleteTeacherShiftKoma);
    return { ...data.deleteTeacherShiftKoma };
  }
} as const;
