import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  getStudyHistoriesByStudentId,
  getStudyHistoriesByStudentIdAndDate
} from '~/src/infrastructure/apis/graphql/query/queries';
import { deepCopy } from '~/src/shared/objectOperationUtil';
import { Query } from '~/src/domain/entities/schema';
import { StudyHistoryRepositoryInterface } from '~/src/domain/interfaces/repositories/aim/studyHistory';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const studyHistoryRepo: StudyHistoryRepositoryInterface = {
  fetchByStudentId: async (after: number | null, first: number, studentId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { after, first, studentId },
      query: gql(getStudyHistoriesByStudentId)
    });
    if (errors?.length) return err(errors);
    logger?.debug('studyHistoriesByStudentIdRepo', data.getStudyHistoriesByStudentId);
    return ok(deepCopy(data.getStudyHistoriesByStudentId));
  },

  fetchByStudentIdAndByDate: async (studentId: number, date: string) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, date },
      query: gql(getStudyHistoriesByStudentIdAndDate)
    });
    if (errors?.length) return err(errors);
    logger?.debug(
      'getStudyHistoriesByStudentIdAndDate data',
      data.getStudyHistoriesByStudentIdAndDate
    );
    return ok([...data.getStudyHistoriesByStudentIdAndDate]);
  }
} as const;
