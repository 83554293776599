import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VApp, {
    attrs: {
      "dark": ""
    }
  }, [_c('AppHeader'), _vm._v(" "), _c(VMain, {
    staticClass: "background overflow-hidden"
  }, [_c(VContainer, {
    staticClass: "full-height pa-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('nuxt', {
    attrs: {
      "keep-alive": "",
      "keep-alive-props": {
        includes: _vm.KEEP_ALIVE_PAGE_PATHS
      }
    }
  })], 1)], 1), _vm._v(" "), _c('Loading', {
    attrs: {
      "is-loading": _vm.isLoading,
      "message": "読み込んでいます"
    }
  }), _vm._v(" "), _c('NotificationSnackBar', {
    attrs: {
      "title": _vm.snackbarCtx.snackbarItem.title,
      "link": _vm.snackbarCtx.snackbarItem.link
    },
    on: {
      "close": _vm.snackbarCtx.closeSnackbar
    },
    model: {
      value: _vm.snackbarCtx.snackbarItem.isOpened,
      callback: function ($$v) {
        _vm.$set(_vm.snackbarCtx.snackbarItem, "isOpened", $$v);
      },
      expression: "snackbarCtx.snackbarItem.isOpened"
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.errorDialogCtx.isOpenedErrorDialog.value,
      "error-message": _vm.errorDialogCtx.errorMessage.value
    },
    on: {
      "close": _vm.errorDialogCtx.closeErrorDialog
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.offlineErrorDialogStore.get(),
      "error-message": _vm.i18n.errors.offlineError.$t()
    },
    on: {
      "close": _vm.offlineErrorDialogStore.close
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.notAuthenticatedErrorDialogStore.get(),
      "error-message": _vm.i18n.errors.refreshedAuthTokenError.$t()
    },
    on: {
      "close": _vm.closeNotAuthenticatedErrorDialog
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.notAuthenticatedClassroomErrorDialogStore.get(),
      "error-message": _vm.i18n.errors.classroomLoginError.$t()
    },
    on: {
      "close": _vm.closeNotAuthenticatedClassroomErrorDialog
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }