import { render, staticRenderFns } from "./BaseCard.vue?vue&type=template&id=39e5475b&scoped=true"
var script = {}
import style0 from "./BaseCard.vue?vue&type=style&index=0&id=39e5475b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17._ocrbj5776pypvsmgnsodguqvgi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e5475b",
  null
  
)

export default component.exports