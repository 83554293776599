import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { registerParentReport } from '~/src/infrastructure/apis/graphql/query/mutations';
import { Mutation } from '~/src/domain/entities/schema';
import { ParentReportRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/guidanceReport/parentReport';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const parentReportRepo: ParentReportRepositoryInterface = {
  register: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(registerParentReport)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('registerParentReport', data.registerParentReport);
    return ok({ ...data.registerParentReport });
  }
} as const;
