import { defineStore } from 'pinia';
import { computed, ref } from '@nuxtjs/composition-api';
import { ManagementUserUnion } from '~/src/infrastructure/repositories/user/managementUser';

export const UserRoleEnum = {
  Manager: 'Manager',
  ClassroomMaster: 'ClassroomMaster',
  Teacher: 'Teacher',
  ClassroomViewer: 'ClassroomViewer'
} as const;

export type UserRole = (typeof UserRoleEnum)[keyof typeof UserRoleEnum];

export const createDefaultUser = (): ManagementUserUnion =>
  ({
    id: -1,
    username: '',
    jukuId: 0
  } as ManagementUserUnion);

export const useUserStore = defineStore('user', () => {
  const currentUser = ref<ManagementUserUnion>(createDefaultUser());

  const get = (): Readonly<ManagementUserUnion> => currentUser.value;
  const set = (newCurrentUser: ManagementUserUnion): void => {
    currentUser.value = newCurrentUser;
  };
  const userRole = computed<UserRole>(() => {
    if (currentUser.value.__typename === 'Manager') return UserRoleEnum.Manager;

    // prettier-ignore
    if (currentUser.value.position === 'classroomMaster') return UserRoleEnum.ClassroomMaster;

    // prettier-ignore
    if (currentUser.value.position === 'classroomViewer') return UserRoleEnum.ClassroomViewer;

    return UserRoleEnum.Teacher;
  });

  return { get, set, userRole };
});
