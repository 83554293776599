import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { getJukuConfigByJukuId } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { JukuConfigInterface } from '~/src/domain/interfaces/repositories/user/jukuConfig';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const jukuConfigRepo: JukuConfigInterface = {
  fetch: async (jukuId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { jukuId },
      query: gql(getJukuConfigByJukuId)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getJukuConfigByJukuId', data.getJukuConfigByJukuId);
    return ok({ ...data.getJukuConfigByJukuId });
  }
} as const;
