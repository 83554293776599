import { gql } from '@apollo/client/core';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { Query } from '~/src/domain/entities/schema';
import { TextbookTagRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/tag/textbookTag';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getTextbookTagsByJukuId } from '~/src/infrastructure/apis/graphql/query/queries';

export const textbookTagRepo: TextbookTagRepositoryInterface = {
  fetchByJukuId: async (jukuId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { jukuId },
      query: gql(getTextbookTagsByJukuId)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getTextbookTagsByJukuId', data.getTextbookTagsByJukuId);
    return [...data.getTextbookTagsByJukuId];
  }
};
