/* eslint-disable import/named */
// import { eventWithTime } from '@sentry/replay/types/types';
import type { ReplayFrameEvent } from '@sentry/replay/types/types';
import { Manager, Student, Teacher } from '~/src/domain/entities/schema';

type MaybeJson = JustJson | NotJson;
type JustJson = { type: 'json'; value: object };
type NotJson = { type: 'not-json'; value: string };
const parseMaybeJson = (maybeJson: string): MaybeJson => {
  try {
    const parsed = JSON.parse(maybeJson);
    return { type: 'json', value: parsed };
  } catch (e) {
    return { type: 'not-json', value: maybeJson };
  }
};

const isRecord = (arg: unknown): arg is Record<string, unknown> =>
  typeof arg === 'object' && arg !== null;
const isManager = (arg: unknown): arg is Manager =>
  isRecord(arg) && arg.__typename === 'Manager';
const isTeacher = (arg: unknown): arg is Teacher =>
  isRecord(arg) && arg.__typename === 'Teacher';
const isStudent = (arg: unknown): arg is Student =>
  isRecord(arg) && arg.__typename === 'Student';

/**
 * 以下のケースでマスク化をしている
 * __typename: 'Manager'の name, furigana
 * __typename: 'Teacher'の name, furigana
 * __typename: 'Student'の name, furigana
 */
export const maskPropertiesOfEncryptionRequirements = (data: unknown): unknown => {
  try {
    if (Array.isArray(data))
      return data.map(item => maskPropertiesOfEncryptionRequirements(item));

    if (isManager(data) || isTeacher(data) || isStudent(data))
      // 暗号化対象propertyを含むobjectの場合
      return { ...data, name: '[Masked]', furigana: '[Masked]' };

    if (isRecord(data) && data.password) return { ...data, password: '[Masked]' };

    if (isRecord(data))
      return Object.entries(data).reduce(
        (
          maskedData: Record<string, unknown>,
          [key, value]: [key: string, value: unknown]
        ) => {
          maskedData[key] = maskPropertiesOfEncryptionRequirements(value);
          return maskedData;
        },
        {}
      );

    return data;
  } catch {
    return 'Failed to mask';
  }
};

export const sanitizerForReplayFrameEvent = (event: ReplayFrameEvent) => {
  try {
    /** Networkのrequest/response bodyをmask化 */
    if (
      isRecord(event.data) &&
      // event は SpanFrameEvent interface
      event.data.tag === 'performanceSpan' &&
      isRecord(event.data.payload) &&
      event.data.payload.op &&
      // event.data.payload は RequestFrame interface
      ['resource.fetch', 'resource.xhr'].includes(event.data.payload.op as string) &&
      isRecord(event.data.payload.data) &&
      'response' in event.data.payload.data &&
      isRecord(event.data.payload.data.response) &&
      event.data.payload.data.response.body
    ) {
      // レスポンスボディ
      const parsed: MaybeJson =
        typeof event.data.payload.data.response.body === 'string'
          ? parseMaybeJson(event.data.payload.data.response.body)
          : {
              type: 'not-json',
              value: Object.toString.call(event.data.payload.data.response.body)
            };

      switch (parsed.type) {
        case 'json': {
          event.data.payload.data.response.body = Object.entries(parsed.value).map(
            ([_, value]) => maskPropertiesOfEncryptionRequirements(value)
          );
          break;
        }
        case 'not-json': {
          // do nothing
          // NOTE: JSONでないものに関しては特にマスキングしない
        }
      }
    }
    return event;
  } catch {
    return event;
  }
};
