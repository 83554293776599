import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getOptionsForTeacherShiftKoma } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const optionsForTeacherShiftKomaRepo = {
  /**
   * 講師シフトコマ登録フォームに必要な選択肢をまとめて取得
   */
  fetch: async (jukuId: number, classroomId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { jukuId, classroomId },
      query: gql(getOptionsForTeacherShiftKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getOptionsForTeacherShiftKoma', data.getOptionsForTeacherShiftKoma);
    return { ...data.getOptionsForTeacherShiftKoma };
  }
} as const;
