


import { SnackBarLink } from '~/src/shared/composables/snackBar';
interface Props {
  isOpened: boolean;
  title: string;
  link?: SnackBarLink;
}
import { defineComponent, useRouter } from '@nuxtjs/composition-api';
const __sfc_main = defineComponent({
  name: 'NotificationSnackBar',
  model: {
    prop: 'isOpened',
    event: 'close'
  }
});
__sfc_main.props = {
  isOpened: {
    key: "isOpened",
    required: true,
    type: Boolean
  },
  title: {
    key: "title",
    required: true,
    type: String
  },
  link: {
    key: "link",
    required: false,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const props = __props;
  const goToLink = (): void => {
    if (!props.link) return;
    router.push({
      path: props.link.path,
      query: props.link.query
    });
  };
  return {
    goToLink
  };
};
export default __sfc_main;
