import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';

import { StudentRepositoryInterface } from '~/src/domain/interfaces/repositories/user/student';
import {
  Query,
  StudentSemesterUpdateInput,
  Mutation
} from '~/src/domain/entities/schema';

import { updateStudentSemester } from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  getStudentsByClassroomId,
  getStudent,
  getStudentsNotScheduled
} from '~/src/infrastructure/apis/graphql/query/queries';
import { DATE_FORMATS, dayjs } from '~/src/shared/date';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const studentRepo: StudentRepositoryInterface = {
  fetch: async (id: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { id },
      query: gql(getStudent)
    });
    if (errors?.length) return err(errors);

    logger?.debug('getStudent', data.getStudent);
    return ok({ ...data.getStudent });
  },

  fetchByClassroomId: async (classroomId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { classroomId },
      query: gql(getStudentsByClassroomId)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getStudentsByClassroomId', data.getStudentsByClassroomId);
    return ok([...data.getStudentsByClassroomId]);
  },

  fetchNotScheduled: async (classroomId: number) => {
    // FIXME: バックで算出すつろうにしてinputからは消す
    const currentDate = dayjs().format(DATE_FORMATS.iso);
    const { data, errors } = await apolloClient.query<Query>({
      variables: { komaDate: currentDate, classroomId },
      query: gql(getStudentsNotScheduled)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getStudentsNotScheduled', data.getStudentsNotScheduled);
    return ok([...data.getStudentsNotScheduled]);
  },

  updateSemester: async (input: StudentSemesterUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateStudentSemester)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('updateStudentSemester', data);
    return ok({ ...data.updateStudentSemester });
  }
} as const;
