import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { bulkRegisterSchoolExamSubjects } from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  Mutation,
  SchoolExamSubjectsBulkRegisterInput
} from '~/src/domain/entities/schema';
import { SchoolExamSubjectRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/exam/school/schoolExamSubject';
import { DataNotExistError } from '~/src/shared/errors/errors';

export const schoolExamSubjectRepo: SchoolExamSubjectRepositoryInterface = {
  // 一括登録
  bulkRegister: async (inputs: SchoolExamSubjectsBulkRegisterInput[]) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { inputs },
      mutation: gql(bulkRegisterSchoolExamSubjects)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    return ok([...data.bulkRegisterSchoolExamSubjects]);
  }
} as const;
