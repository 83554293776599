import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { getOptionsForStudentContractRegistration } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { OptionsForStudentContractRegistrationRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/contract/optionsForStudentContractRegistration';
import { deepCopy } from '~/src/shared/objectOperationUtil';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const optionsForStudentContractRegistrationRepo: OptionsForStudentContractRegistrationRepositoryInterface =
  {
    fetch: async (jukuId: number, classroomId: number) => {
      const { data, errors } = await apolloClient.query<Query>({
        variables: { jukuId, classroomId },
        query: gql(getOptionsForStudentContractRegistration)
      });
      if (errors?.length) err(errors);

      logger?.debug(
        'getOptionsForStudentContractRegistration',
        data.getOptionsForStudentContractRegistration
      );
      const resClone = deepCopy(data.getOptionsForStudentContractRegistration);

      // FIXME: バックエンドでソートしたいが、暗号化・複合化の実装の都合上、現状はフロントエンドでソートする
      return ok({
        ...resClone,
        managementUsersGroupedByRole: {
          __typename: 'ManagementUsersGroupedByRole',
          mainTeachers: resClone.managementUsersGroupedByRole.mainTeachers.sort((a, b) =>
            b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
          ),
          mainClassroomMasters:
            resClone.managementUsersGroupedByRole.mainClassroomMasters.sort((a, b) =>
              b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
            ),
          subTeachers: resClone.managementUsersGroupedByRole.subTeachers.sort((a, b) =>
            b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
          ),
          subClassroomMasters:
            resClone.managementUsersGroupedByRole.subClassroomMasters.sort((a, b) =>
              b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
            ),
          managers: resClone.managementUsersGroupedByRole.managers.sort((a, b) =>
            b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
          )
        }
      });
    }
  } as const;
