export type LocalStorageInterface = {
  getItem: <T extends string>(key: LocalStorageKey) => T | null;

  setItem: (key: LocalStorageKey, value: string) => void;

  removeItem: (key: LocalStorageKey) => void;
};

export enum LocalStorageKey {
  currentClassroomId = 'currentClassroomId',
  komaViewState = 'komaViewState',
  bulkUpdateGuidanceKomasApplyEndDate = 'bulkUpdateGuidanceKomasApplyEndDate',
  guidanceScheduleAttendanceFilterOption = 'guidanceScheduleAttendanceFilterOption',
  guidanceJukuTestWithSummariesFilterOption = 'guidanceJukuTestWithSummariesFilterOption'
}
