import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { Classroom } from '~/src/domain/entities/schema';

export const useClassroomOptionsStore = defineStore('classroomOptions', () => {
  const classroomOptions = ref<Classroom[]>([]);

  const get = (): Readonly<Classroom[]> => classroomOptions.value;
  const set = (newClassroomOptions: Classroom[]): void => {
    classroomOptions.value = newClassroomOptions;
  };

  return { get, set };
});
