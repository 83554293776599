export const components = {
  selectModeDialog: {
    loginInfo: {
      $t: ({ classroomName }: { classroomName: string }) =>
        `${classroomName}教室にログインしました。`
    },
    askMode: {
      $t: ({ newline }: { newline: string }) =>
        `指導管理アプリとして使いますか？${newline}入退室アプリとして使いますか？`
    }
  }
} as const;
