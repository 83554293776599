import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  createTeacherSharedComment,
  updateTeacherSharedComment,
  deleteTeacherSharedComment,
  pinTeacherSharedComment,
  reactTeacherSharedComment,
  unpinTeacherSharedComment,
  unReactTeacherSharedComment,
  bulkReadTeacherSharedComments
} from '~/src/infrastructure/apis/graphql/query/mutations';
import { getTeacherSharedCommentsByStudentId } from '~/src/infrastructure/apis/graphql/query/queries';
import {
  Mutation,
  Query,
  TeacherSharedCommentDeleteInput
} from '~/src/domain/entities/schema';
import { TeacherSharedCommentRepositoryInterface } from '~/src/domain/interfaces/repositories/user/teacherSharedComment';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const teacherSharedCommentRepo: TeacherSharedCommentRepositoryInterface = {
  fetchByStudentId: async studentId => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId },
      query: gql(getTeacherSharedCommentsByStudentId)
    });
    if (errors?.length) return err(errors);
    logger?.debug(
      'getTeacherSharedCommentsByStudentId',
      data.getTeacherSharedCommentsByStudentId
    );
    // 配列とオブジェクトの参照を切る
    return ok([...data.getTeacherSharedCommentsByStudentId.map(item => ({ ...item }))]);
  },

  pin: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(pinTeacherSharedComment)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('pinTeacherSharedComment', data.pinTeacherSharedComment);
    return ok({ ...data.pinTeacherSharedComment });
  },

  unpin: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(unpinTeacherSharedComment)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('unpinTeacherSharedComment', data.unpinTeacherSharedComment);
    return ok({ ...data.unpinTeacherSharedComment });
  },

  react: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(reactTeacherSharedComment)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('reactTeacherSharedComment', data.reactTeacherSharedComment);
    return ok({ ...data.reactTeacherSharedComment });
  },

  unReact: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(unReactTeacherSharedComment)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('unReactTeacherSharedComment', data.unReactTeacherSharedComment);
    return ok({ ...data.unReactTeacherSharedComment });
  },

  bulkRead: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(bulkReadTeacherSharedComments)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());

    logger?.debug(
      'bulkReadTeacherSharedComments data',
      data.bulkReadTeacherSharedComments
    );
    return ok(data.bulkReadTeacherSharedComments);
  },

  create: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createTeacherSharedComment)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('createTeacherSharedComment', data.createTeacherSharedComment);
    return ok({ ...data.createTeacherSharedComment });
  },

  update: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateTeacherSharedComment)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    // MEMO: 作成後にinputの初期化処理が入るので、参照を切って表示させる
    logger?.debug('input', { ...input });
    logger?.debug('updateTeacherSharedComment', data.updateTeacherSharedComment);
    return ok({ ...data.updateTeacherSharedComment });
  },

  delete: async (input: TeacherSharedCommentDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteTeacherSharedComment)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('deleteTeacherSharedComment', data.deleteTeacherSharedComment);
    return ok({ ...data.deleteTeacherSharedComment });
  }
} as const;
