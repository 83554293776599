import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import {
  GetContractedKomaSubjectsByDateRangeArgsDateRange,
  Query
} from '~/src/domain/entities/schema';
import { SubjectRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/subject/subject';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { getContractedKomaSubjectsByDateRange } from '~/src/infrastructure/apis/graphql/query/queries';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const subjectRepo: SubjectRepositoryInterface = {
  fetchContractedByDateRange: async (
    studentId: number,
    dateRanges: GetContractedKomaSubjectsByDateRangeArgsDateRange[]
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, dateRanges },
      query: gql(getContractedKomaSubjectsByDateRange)
    });
    if (errors?.length) return err(errors);
    logger?.debug(
      'getContractedKomaSubjectsByDateRange',
      data.getContractedKomaSubjectsByDateRange
    );
    return ok([...data.getContractedKomaSubjectsByDateRange]);
  }
} as const;
