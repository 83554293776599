import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VSnackbar, {
    staticClass: "notification-snackbar",
    attrs: {
      "value": _vm.isOpened,
      "content-class": "text-subtitle-1 px-6",
      "left": "",
      "timeout": "4000",
      "elevation": "0",
      "rounded": "pill"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('close', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('div', [_vm.link ? _c(VBtn, _vm._b({
          staticClass: "font-weight-bold text-subtitle-2",
          attrs: {
            "color": "primary",
            "text": "",
            "nuxt": ""
          },
          on: {
            "click": _vm.goToLink
          }
        }, 'v-btn', attrs, false), [_c('span', {
          staticClass: "text-decoration-underline"
        }, [_vm._v(_vm._s(_vm.link.text))])]) : _vm._e(), _vm._v(" "), _c(VBtn, {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('close', false);
            }
          }
        }, [_c('i', {
          staticClass: "mdi mdi-close white--text text-h5"
        })])], 1)];
      }
    }])
  }, [_vm._v("\n  " + _vm._s(_vm.title) + "\n  ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }