import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { registerAimAtReports } from '~/src/infrastructure/apis/graphql/query/mutations';
import { Mutation, AimAtReportsRegisterInput } from '~/src/domain/entities/schema';
import { AimAtReportRepositoryInterface } from '~/src/domain/interfaces/repositories/aim/aimAtReport';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const aimAtReportRepo: AimAtReportRepositoryInterface = {
  /**
   * 学習履歴登録
   */
  register: async (input: AimAtReportsRegisterInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(registerAimAtReports)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());

    logger?.debug('registerAimAtReports input', input);
    logger?.debug('registerAimAtReports data', data.registerAimAtReports);
    return ok([...data.registerAimAtReports]);
  }
} as const;
