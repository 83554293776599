export const pageTitles = {
  students: { $t: () => `生徒一覧` },
  student: { $t: ({ studentName }: { studentName?: string }) => `${studentName}` },
  teachers: { $t: () => `講師一覧` },
  teacher: { $t: ({ teacherName }: { teacherName: string }) => `${teacherName}` },
  schedules: { $t: () => `指導予定` },
  shifts: { $t: () => `講師シフト一覧` },
  teacherTasks: { $t: () => `講師タスク一覧` },
  teacherTask: { $t: () => `講師タスク` }
};
