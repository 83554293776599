export const BaseCard = () => import('../../src/shared/components/BaseCard.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const Circular = () => import('../../src/shared/components/Circular.vue' /* webpackChunkName: "components/circular" */).then(c => wrapFunctional(c.default || c))
export const FooterTab = () => import('../../src/shared/components/FooterTab.vue' /* webpackChunkName: "components/footer-tab" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../src/shared/components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../src/shared/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const NotificationSnackBar = () => import('../../src/shared/components/NotificationSnackBar.vue' /* webpackChunkName: "components/notification-snack-bar" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../src/shared/components/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const TextMarkedLeft = () => import('../../src/shared/components/TextMarkedLeft.vue' /* webpackChunkName: "components/text-marked-left" */).then(c => wrapFunctional(c.default || c))
export const IconTextBtn = () => import('../../src/shared/components/buttons/IconTextBtn.vue' /* webpackChunkName: "components/icon-text-btn" */).then(c => wrapFunctional(c.default || c))
export const PreviousBtn = () => import('../../src/shared/components/buttons/PreviousBtn.vue' /* webpackChunkName: "components/previous-btn" */).then(c => wrapFunctional(c.default || c))
export const RoundedBtn = () => import('../../src/shared/components/buttons/RoundedBtn.vue' /* webpackChunkName: "components/rounded-btn" */).then(c => wrapFunctional(c.default || c))
export const SwitchBtn = () => import('../../src/shared/components/buttons/SwitchBtn.vue' /* webpackChunkName: "components/switch-btn" */).then(c => wrapFunctional(c.default || c))
export const TeacherSharedCommentReactionBtn = () => import('../../src/shared/components/buttons/TeacherSharedCommentReactionBtn.vue' /* webpackChunkName: "components/teacher-shared-comment-reaction-btn" */).then(c => wrapFunctional(c.default || c))
export const TextBtn = () => import('../../src/shared/components/buttons/TextBtn.vue' /* webpackChunkName: "components/text-btn" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationDialog = () => import('../../src/shared/components/dialogs/ConfirmationDialog.vue' /* webpackChunkName: "components/confirmation-dialog" */).then(c => wrapFunctional(c.default || c))
export const ErrorDialog = () => import('../../src/shared/components/dialogs/ErrorDialog.vue' /* webpackChunkName: "components/error-dialog" */).then(c => wrapFunctional(c.default || c))
export const PlainDialog = () => import('../../src/shared/components/dialogs/PlainDialog.vue' /* webpackChunkName: "components/plain-dialog" */).then(c => wrapFunctional(c.default || c))
export const ComboboxInput = () => import('../../src/shared/components/inputs/ComboboxInput.vue' /* webpackChunkName: "components/combobox-input" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../src/shared/components/inputs/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DatePickerInput = () => import('../../src/shared/components/inputs/DatePickerInput.vue' /* webpackChunkName: "components/date-picker-input" */).then(c => wrapFunctional(c.default || c))
export const MultiSubjectsSelectInputs = () => import('../../src/shared/components/inputs/MultiSubjectsSelectInputs.vue' /* webpackChunkName: "components/multi-subjects-select-inputs" */).then(c => wrapFunctional(c.default || c))
export const RadioInput = () => import('../../src/shared/components/inputs/RadioInput.vue' /* webpackChunkName: "components/radio-input" */).then(c => wrapFunctional(c.default || c))
export const RoundedMultipleSelect = () => import('../../src/shared/components/inputs/RoundedMultipleSelect.vue' /* webpackChunkName: "components/rounded-multiple-select" */).then(c => wrapFunctional(c.default || c))
export const RoundedSelect = () => import('../../src/shared/components/inputs/RoundedSelect.vue' /* webpackChunkName: "components/rounded-select" */).then(c => wrapFunctional(c.default || c))
export const SubjectSelectInputs = () => import('../../src/shared/components/inputs/SubjectSelectInputs.vue' /* webpackChunkName: "components/subject-select-inputs" */).then(c => wrapFunctional(c.default || c))
export const TextInput = () => import('../../src/shared/components/inputs/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(c => wrapFunctional(c.default || c))
export const TextareaInput = () => import('../../src/shared/components/inputs/TextareaInput.vue' /* webpackChunkName: "components/textarea-input" */).then(c => wrapFunctional(c.default || c))
export const TextareaReadOnly = () => import('../../src/shared/components/inputs/TextareaReadOnly.vue' /* webpackChunkName: "components/textarea-read-only" */).then(c => wrapFunctional(c.default || c))
export const AttendanceStatusLabel = () => import('../../src/shared/components/labels/AttendanceStatusLabel.vue' /* webpackChunkName: "components/attendance-status-label" */).then(c => wrapFunctional(c.default || c))
export const CompletionStatusLabel = () => import('../../src/shared/components/labels/CompletionStatusLabel.vue' /* webpackChunkName: "components/completion-status-label" */).then(c => wrapFunctional(c.default || c))
export const ShiftLabel = () => import('../../src/shared/components/labels/ShiftLabel.vue' /* webpackChunkName: "components/shift-label" */).then(c => wrapFunctional(c.default || c))
export const SubjectLabel = () => import('../../src/shared/components/labels/SubjectLabel.vue' /* webpackChunkName: "components/subject-label" */).then(c => wrapFunctional(c.default || c))
export const TeacherAttendanceStatusLabel = () => import('../../src/shared/components/labels/TeacherAttendanceStatusLabel.vue' /* webpackChunkName: "components/teacher-attendance-status-label" */).then(c => wrapFunctional(c.default || c))
export const TotalLabel = () => import('../../src/shared/components/labels/TotalLabel.vue' /* webpackChunkName: "components/total-label" */).then(c => wrapFunctional(c.default || c))
export const ValidLabel = () => import('../../src/shared/components/labels/ValidLabel.vue' /* webpackChunkName: "components/valid-label" */).then(c => wrapFunctional(c.default || c))
export const PullToAndPaginationContainer = () => import('../../src/shared/components/pullTo/PullToAndPaginationContainer.vue' /* webpackChunkName: "components/pull-to-and-pagination-container" */).then(c => wrapFunctional(c.default || c))
export const PullToContainer = () => import('../../src/shared/components/pullTo/PullToContainer.vue' /* webpackChunkName: "components/pull-to-container" */).then(c => wrapFunctional(c.default || c))
export const PullToLoading = () => import('../../src/shared/components/pullTo/PullToLoading.vue' /* webpackChunkName: "components/pull-to-loading" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../src/domain/fragments/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const StudyHistoryAimCard = () => import('../../src/domain/fragments/aim/StudyHistoryAimCard.vue' /* webpackChunkName: "components/study-history-aim-card" */).then(c => wrapFunctional(c.default || c))
export const TodoProgressAimCard = () => import('../../src/domain/fragments/aim/TodoProgressAimCard.vue' /* webpackChunkName: "components/todo-progress-aim-card" */).then(c => wrapFunctional(c.default || c))
export const CustomSubjectLabel = () => import('../../src/domain/fragments/reco/exam/CustomSubjectLabel.vue' /* webpackChunkName: "components/custom-subject-label" */).then(c => wrapFunctional(c.default || c))
export const GuidanceNavBtnGroup = () => import('../../src/domain/fragments/reco/guidance/GuidanceNavBtnGroup.vue' /* webpackChunkName: "components/guidance-nav-btn-group" */).then(c => wrapFunctional(c.default || c))
export const GuidancePolicyUpdateDialog = () => import('../../src/domain/fragments/reco/guidance/GuidancePolicyUpdateDialog.vue' /* webpackChunkName: "components/guidance-policy-update-dialog" */).then(c => wrapFunctional(c.default || c))
export const GroupedContractedKomaSubjectsInputs = () => import('../../src/domain/fragments/reco/subject/GroupedContractedKomaSubjectsInputs.vue' /* webpackChunkName: "components/grouped-contracted-koma-subjects-inputs" */).then(c => wrapFunctional(c.default || c))
export const ClassroomChangeDialog = () => import('../../src/domain/fragments/user/account/ClassroomChangeDialog.vue' /* webpackChunkName: "components/classroom-change-dialog" */).then(c => wrapFunctional(c.default || c))
export const ClassroomSelectDialog = () => import('../../src/domain/fragments/user/account/ClassroomSelectDialog.vue' /* webpackChunkName: "components/classroom-select-dialog" */).then(c => wrapFunctional(c.default || c))
export const RoomAccessCompletedDialog = () => import('../../src/domain/fragments/user/account/RoomAccessCompletedDialog.vue' /* webpackChunkName: "components/room-access-completed-dialog" */).then(c => wrapFunctional(c.default || c))
export const UserLogin = () => import('../../src/domain/fragments/user/account/UserLogin.vue' /* webpackChunkName: "components/user-login" */).then(c => wrapFunctional(c.default || c))
export const LoginStatusLabel = () => import('../../src/domain/fragments/user/student/LoginStatusLabel.vue' /* webpackChunkName: "components/login-status-label" */).then(c => wrapFunctional(c.default || c))
export const StudentCard = () => import('../../src/domain/fragments/user/student/StudentCard.vue' /* webpackChunkName: "components/student-card" */).then(c => wrapFunctional(c.default || c))
export const StudentInfoPanel = () => import('../../src/domain/fragments/user/student/StudentInfoPanel.vue' /* webpackChunkName: "components/student-info-panel" */).then(c => wrapFunctional(c.default || c))
export const StudentNavBtnGroup = () => import('../../src/domain/fragments/user/student/StudentNavBtnGroup.vue' /* webpackChunkName: "components/student-nav-btn-group" */).then(c => wrapFunctional(c.default || c))
export const UpdateSharedInfoDialog = () => import('../../src/domain/fragments/user/student/UpdateSharedInfoDialog.vue' /* webpackChunkName: "components/update-shared-info-dialog" */).then(c => wrapFunctional(c.default || c))
export const GroupedManagementUserSelectInput = () => import('../../src/domain/fragments/user/teacher/GroupedManagementUserSelectInput.vue' /* webpackChunkName: "components/grouped-management-user-select-input" */).then(c => wrapFunctional(c.default || c))
export const TeacherCard = () => import('../../src/domain/fragments/user/teacher/TeacherCard.vue' /* webpackChunkName: "components/teacher-card" */).then(c => wrapFunctional(c.default || c))
export const TeacherInfoPanel = () => import('../../src/domain/fragments/user/teacher/TeacherInfoPanel.vue' /* webpackChunkName: "components/teacher-info-panel" */).then(c => wrapFunctional(c.default || c))
export const TeacherSharedCommentCard = () => import('../../src/domain/fragments/user/teacherSharedInfo/TeacherSharedCommentCard.vue' /* webpackChunkName: "components/teacher-shared-comment-card" */).then(c => wrapFunctional(c.default || c))
export const TeacherSharedCommentCreateDialog = () => import('../../src/domain/fragments/user/teacherSharedInfo/TeacherSharedCommentCreateDialog.vue' /* webpackChunkName: "components/teacher-shared-comment-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const TeacherSharedCommentEditDialog = () => import('../../src/domain/fragments/user/teacherSharedInfo/TeacherSharedCommentEditDialog.vue' /* webpackChunkName: "components/teacher-shared-comment-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanGuidanceKomaCalendar = () => import('../../src/domain/fragments/reco/contents/studyPlan/StudyPlanGuidanceKomaCalendar.vue' /* webpackChunkName: "components/study-plan-guidance-koma-calendar" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanHeader = () => import('../../src/domain/fragments/reco/contents/studyPlan/StudyPlanHeader.vue' /* webpackChunkName: "components/study-plan-header" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanTextbooksPanel = () => import('../../src/domain/fragments/reco/contents/studyPlan/StudyPlanTextbooksPanel.vue' /* webpackChunkName: "components/study-plan-textbooks-panel" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaTextbookStudyCreateDialogForTextbookDetailPage = () => import('../../src/domain/fragments/reco/contents/textbook/GuidanceKomaTextbookStudyCreateDialogForTextbookDetailPage.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-create-dialog-for-textbook-detail-page" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaTextbookStudyEditDialogForTextbookDetailPage = () => import('../../src/domain/fragments/reco/contents/textbook/GuidanceKomaTextbookStudyEditDialogForTextbookDetailPage.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-edit-dialog-for-textbook-detail-page" */).then(c => wrapFunctional(c.default || c))
export const OriginalExamCard = () => import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamCard.vue' /* webpackChunkName: "components/original-exam-card" */).then(c => wrapFunctional(c.default || c))
export const OriginalExamCreateDialog = () => import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamCreateDialog.vue' /* webpackChunkName: "components/original-exam-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const OriginalExamDataTable = () => import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamDataTable.vue' /* webpackChunkName: "components/original-exam-data-table" */).then(c => wrapFunctional(c.default || c))
export const OriginalExamEditDialog = () => import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamEditDialog.vue' /* webpackChunkName: "components/original-exam-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const OriginalExamSubjectCreateDialog = () => import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamSubjectCreateDialog.vue' /* webpackChunkName: "components/original-exam-subject-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const OriginalExamSubjectEditDialog = () => import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamSubjectEditDialog.vue' /* webpackChunkName: "components/original-exam-subject-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const OriginalExamSubjectsBulkRegisterDialog = () => import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamSubjectsBulkRegisterDialog.vue' /* webpackChunkName: "components/original-exam-subjects-bulk-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const KomaLabel = () => import('../../src/domain/fragments/reco/guidance/koma/KomaLabel.vue' /* webpackChunkName: "components/koma-label" */).then(c => wrapFunctional(c.default || c))
export const DatePickerForGuidanceHistory = () => import('../../src/domain/fragments/reco/guidance/report/DatePickerForGuidanceHistory.vue' /* webpackChunkName: "components/date-picker-for-guidance-history" */).then(c => wrapFunctional(c.default || c))
export const GuidanceIndividualTasksMoveDialog = () => import('../../src/domain/fragments/reco/guidance/report/GuidanceIndividualTasksMoveDialog.vue' /* webpackChunkName: "components/guidance-individual-tasks-move-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceIndividualTasksRegisterDialogForGuidanceReport = () => import('../../src/domain/fragments/reco/guidance/report/GuidanceIndividualTasksRegisterDialogForGuidanceReport.vue' /* webpackChunkName: "components/guidance-individual-tasks-register-dialog-for-guidance-report" */).then(c => wrapFunctional(c.default || c))
export const GuidanceIndividualTasksRegisterDialogForGuidanceSchedule = () => import('../../src/domain/fragments/reco/guidance/report/GuidanceIndividualTasksRegisterDialogForGuidanceSchedule.vue' /* webpackChunkName: "components/guidance-individual-tasks-register-dialog-for-guidance-schedule" */).then(c => wrapFunctional(c.default || c))
export const GuidanceReportValidationErrorDialog = () => import('../../src/domain/fragments/reco/guidance/report/GuidanceReportValidationErrorDialog.vue' /* webpackChunkName: "components/guidance-report-validation-error-dialog" */).then(c => wrapFunctional(c.default || c))
export const ParentReportRegisterDialog = () => import('../../src/domain/fragments/reco/guidance/report/ParentReportRegisterDialog.vue' /* webpackChunkName: "components/parent-report-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomasBulkUpdateBottomDrawer = () => import('../../src/domain/fragments/reco/guidance/schedule/GuidanceKomasBulkUpdateBottomDrawer.vue' /* webpackChunkName: "components/guidance-komas-bulk-update-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const TeacherSelectForRegisterShiftBottomDrawer = () => import('../../src/domain/fragments/reco/guidance/shift/TeacherSelectForRegisterShiftBottomDrawer.vue' /* webpackChunkName: "components/teacher-select-for-register-shift-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const TeacherShiftCalendar = () => import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftCalendar.vue' /* webpackChunkName: "components/teacher-shift-calendar" */).then(c => wrapFunctional(c.default || c))
export const TeacherShiftKomaRegisterCard = () => import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftKomaRegisterCard.vue' /* webpackChunkName: "components/teacher-shift-koma-register-card" */).then(c => wrapFunctional(c.default || c))
export const TeacherShiftRegisterDialogForTeacherDetailPage = () => import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftRegisterDialogForTeacherDetailPage.vue' /* webpackChunkName: "components/teacher-shift-register-dialog-for-teacher-detail-page" */).then(c => wrapFunctional(c.default || c))
export const TeacherShiftRegisterDialogForTeachersShiftsPage = () => import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftRegisterDialogForTeachersShiftsPage.vue' /* webpackChunkName: "components/teacher-shift-register-dialog-for-teachers-shifts-page" */).then(c => wrapFunctional(c.default || c))
export const TeacherShiftsBulkRegisterDialog = () => import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftsBulkRegisterDialog.vue' /* webpackChunkName: "components/teacher-shifts-bulk-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const ContractKomaRegisterPanel = () => import('../../src/domain/fragments/reco/guidance/studentContract/ContractKomaRegisterPanel.vue' /* webpackChunkName: "components/contract-koma-register-panel" */).then(c => wrapFunctional(c.default || c))
export const StudentContractCard = () => import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractCard.vue' /* webpackChunkName: "components/student-contract-card" */).then(c => wrapFunctional(c.default || c))
export const StudentContractCreateDialog = () => import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractCreateDialog.vue' /* webpackChunkName: "components/student-contract-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const StudentContractDetailPanel = () => import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractDetailPanel.vue' /* webpackChunkName: "components/student-contract-detail-panel" */).then(c => wrapFunctional(c.default || c))
export const StudentContractEditDialog = () => import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractEditDialog.vue' /* webpackChunkName: "components/student-contract-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const EditTeacherTaskStudentsDialog = () => import('../../src/domain/fragments/reco/task/teacherTask/EditTeacherTaskStudentsDialog.vue' /* webpackChunkName: "components/edit-teacher-task-students-dialog" */).then(c => wrapFunctional(c.default || c))
export const StudentSelectTableForCreatingTeacherTask = () => import('../../src/domain/fragments/reco/task/teacherTask/StudentSelectTableForCreatingTeacherTask.vue' /* webpackChunkName: "components/student-select-table-for-creating-teacher-task" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskCard = () => import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskCard.vue' /* webpackChunkName: "components/teacher-task-card" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskCreateDialog = () => import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskCreateDialog.vue' /* webpackChunkName: "components/teacher-task-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskDetailCard = () => import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskDetailCard.vue' /* webpackChunkName: "components/teacher-task-detail-card" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskEditDialog = () => import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskEditDialog.vue' /* webpackChunkName: "components/teacher-task-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskStudentsSelectTable = () => import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskStudentsSelectTable.vue' /* webpackChunkName: "components/teacher-task-students-select-table" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskCheckCard = () => import('../../src/domain/fragments/reco/task/teacherTaskCheck/TeacherTaskCheckCard.vue' /* webpackChunkName: "components/teacher-task-check-card" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskCheckDetailCard = () => import('../../src/domain/fragments/reco/task/teacherTaskCheck/TeacherTaskCheckDetailCard.vue' /* webpackChunkName: "components/teacher-task-check-detail-card" */).then(c => wrapFunctional(c.default || c))
export const TeacherTaskCheckTable = () => import('../../src/domain/fragments/reco/task/teacherTaskCheck/TeacherTaskCheckTable.vue' /* webpackChunkName: "components/teacher-task-check-table" */).then(c => wrapFunctional(c.default || c))
export const NotScheduledStudentCard = () => import('../../src/domain/fragments/user/student/guidanceSchedule/NotScheduledStudentCard.vue' /* webpackChunkName: "components/not-scheduled-student-card" */).then(c => wrapFunctional(c.default || c))
export const NotScheduledStudentPanel = () => import('../../src/domain/fragments/user/student/guidanceSchedule/NotScheduledStudentPanel.vue' /* webpackChunkName: "components/not-scheduled-student-panel" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanProgress = () => import('../../src/domain/fragments/reco/contents/studyPlan/progressBar/StudyPlanProgress.vue' /* webpackChunkName: "components/study-plan-progress" */).then(c => wrapFunctional(c.default || c))
export const StudentTagsEditDialog = () => import('../../src/domain/fragments/reco/contents/tag/studentTag/StudentTagsEditDialog.vue' /* webpackChunkName: "components/student-tags-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const ImportantLabel = () => import('../../src/domain/fragments/reco/contents/textbook/common/ImportantLabel.vue' /* webpackChunkName: "components/important-label" */).then(c => wrapFunctional(c.default || c))
export const TextbookCard = () => import('../../src/domain/fragments/reco/contents/textbook/common/TextbookCard.vue' /* webpackChunkName: "components/textbook-card" */).then(c => wrapFunctional(c.default || c))
export const TextbookCardForCreated = () => import('../../src/domain/fragments/reco/contents/textbook/common/TextbookCardForCreated.vue' /* webpackChunkName: "components/textbook-card-for-created" */).then(c => wrapFunctional(c.default || c))
export const TextbookDetailCard = () => import('../../src/domain/fragments/reco/contents/textbook/common/TextbookDetailCard.vue' /* webpackChunkName: "components/textbook-detail-card" */).then(c => wrapFunctional(c.default || c))
export const TextbookDetailCardForGuidanceReport = () => import('../../src/domain/fragments/reco/contents/textbook/common/TextbookDetailCardForGuidanceReport.vue' /* webpackChunkName: "components/textbook-detail-card-for-guidance-report" */).then(c => wrapFunctional(c.default || c))
export const TextbookDetailCardPanel = () => import('../../src/domain/fragments/reco/contents/textbook/common/TextbookDetailCardPanel.vue' /* webpackChunkName: "components/textbook-detail-card-panel" */).then(c => wrapFunctional(c.default || c))
export const TextbookListHeader = () => import('../../src/domain/fragments/reco/contents/textbook/common/TextbookListHeader.vue' /* webpackChunkName: "components/textbook-list-header" */).then(c => wrapFunctional(c.default || c))
export const LessonPageRangeSelectFormDialog = () => import('../../src/domain/fragments/reco/contents/textbook/form/LessonPageRangeSelectFormDialog.vue' /* webpackChunkName: "components/lesson-page-range-select-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const TotalTextbookStudyRangeUpdateDialog = () => import('../../src/domain/fragments/reco/contents/textbook/form/TotalTextbookStudyRangeUpdateDialog.vue' /* webpackChunkName: "components/total-textbook-study-range-update-dialog" */).then(c => wrapFunctional(c.default || c))
export const TextbookProgress = () => import('../../src/domain/fragments/reco/contents/textbook/progressBar/TextbookProgress.vue' /* webpackChunkName: "components/textbook-progress" */).then(c => wrapFunctional(c.default || c))
export const SchoolLessonProgressBtn = () => import('../../src/domain/fragments/reco/contents/textbook/progressBtn/SchoolLessonProgressBtn.vue' /* webpackChunkName: "components/school-lesson-progress-btn" */).then(c => wrapFunctional(c.default || c))
export const TotalStudyProgressBtn = () => import('../../src/domain/fragments/reco/contents/textbook/progressBtn/TotalStudyProgressBtn.vue' /* webpackChunkName: "components/total-study-progress-btn" */).then(c => wrapFunctional(c.default || c))
export const TotalTextbookStudyEditDialog = () => import('../../src/domain/fragments/reco/contents/textbook/textbookStudy/TotalTextbookStudyEditDialog.vue' /* webpackChunkName: "components/total-textbook-study-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdditionalSchoolExamSubjectCreateDialog = () => import('../../src/domain/fragments/reco/exam/schoolExam/additional/AdditionalSchoolExamSubjectCreateDialog.vue' /* webpackChunkName: "components/additional-school-exam-subject-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdditionalSchoolExamSubjectEditDialog = () => import('../../src/domain/fragments/reco/exam/schoolExam/additional/AdditionalSchoolExamSubjectEditDialog.vue' /* webpackChunkName: "components/additional-school-exam-subject-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const SchoolExamCard = () => import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamCard.vue' /* webpackChunkName: "components/school-exam-card" */).then(c => wrapFunctional(c.default || c))
export const SchoolExamDataTable = () => import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamDataTable.vue' /* webpackChunkName: "components/school-exam-data-table" */).then(c => wrapFunctional(c.default || c))
export const SchoolExamPanel = () => import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamPanel.vue' /* webpackChunkName: "components/school-exam-panel" */).then(c => wrapFunctional(c.default || c))
export const SchoolExamSubjectsBulkRegisterDialog = () => import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamSubjectsBulkRegisterDialog.vue' /* webpackChunkName: "components/school-exam-subjects-bulk-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const DefaultSchoolExamSubjectUpdateDialog = () => import('../../src/domain/fragments/reco/exam/schoolExam/default/DefaultSchoolExamSubjectUpdateDialog.vue' /* webpackChunkName: "components/default-school-exam-subject-update-dialog" */).then(c => wrapFunctional(c.default || c))
export const SchoolExamDataTableForScoreTrend = () => import('../../src/domain/fragments/reco/exam/schoolExam/scoreTrend/SchoolExamDataTableForScoreTrend.vue' /* webpackChunkName: "components/school-exam-data-table-for-score-trend" */).then(c => wrapFunctional(c.default || c))
export const SchoolExamScoreChart = () => import('../../src/domain/fragments/reco/exam/schoolExam/scoreTrend/SchoolExamScoreChart.vue' /* webpackChunkName: "components/school-exam-score-chart" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaCard = () => import('../../src/domain/fragments/reco/guidance/koma/calendarForStudent/GuidanceKomaCard.vue' /* webpackChunkName: "components/guidance-koma-card" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaPanelsSortedByKomaTemplate = () => import('../../src/domain/fragments/reco/guidance/koma/guidanceSchedule/GuidanceKomaPanelsSortedByKomaTemplate.vue' /* webpackChunkName: "components/guidance-koma-panels-sorted-by-koma-template" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaPanelsSortedByManager = () => import('../../src/domain/fragments/reco/guidance/koma/guidanceSchedule/GuidanceKomaPanelsSortedByManager.vue' /* webpackChunkName: "components/guidance-koma-panels-sorted-by-manager" */).then(c => wrapFunctional(c.default || c))
export const AimAtReportCard = () => import('../../src/domain/fragments/reco/guidance/report/aim/AimAtReportCard.vue' /* webpackChunkName: "components/aim-at-report-card" */).then(c => wrapFunctional(c.default || c))
export const AimAtReportsRegisterDialog = () => import('../../src/domain/fragments/reco/guidance/report/aim/AimAtReportsRegisterDialog.vue' /* webpackChunkName: "components/aim-at-reports-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceTestCreateDialog = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/GuidanceTestCreateDialog.vue' /* webpackChunkName: "components/guidance-test-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceReportContentPanel = () => import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportContentPanel.vue' /* webpackChunkName: "components/guidance-report-content-panel" */).then(c => wrapFunctional(c.default || c))
export const GuidanceReportGuidanceTaskPanel = () => import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportGuidanceTaskPanel.vue' /* webpackChunkName: "components/guidance-report-guidance-task-panel" */).then(c => wrapFunctional(c.default || c))
export const GuidanceReportKomaInfoPanel = () => import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportKomaInfoPanel.vue' /* webpackChunkName: "components/guidance-report-koma-info-panel" */).then(c => wrapFunctional(c.default || c))
export const GuidanceReportPanel = () => import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportPanel.vue' /* webpackChunkName: "components/guidance-report-panel" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaTextbookStudyCard = () => import('../../src/domain/fragments/reco/guidance/report/progress/GuidanceKomaTextbookStudyCard.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-card" */).then(c => wrapFunctional(c.default || c))
export const GuidanceProgressLapInputBtn = () => import('../../src/domain/fragments/reco/guidance/report/progress/GuidanceProgressLapInputBtn.vue' /* webpackChunkName: "components/guidance-progress-lap-input-btn" */).then(c => wrapFunctional(c.default || c))
export const GuidanceReviewCard = () => import('../../src/domain/fragments/reco/guidance/report/review/GuidanceReviewCard.vue' /* webpackChunkName: "components/guidance-review-card" */).then(c => wrapFunctional(c.default || c))
export const GuidanceReviewsRegisterDialog = () => import('../../src/domain/fragments/reco/guidance/report/review/GuidanceReviewsRegisterDialog.vue' /* webpackChunkName: "components/guidance-reviews-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const FixedTeacherShiftDetailFormDialog = () => import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftDetailFormDialog.vue' /* webpackChunkName: "components/fixed-teacher-shift-detail-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const FixedTeacherShiftKomaRegisterCard = () => import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftKomaRegisterCard.vue' /* webpackChunkName: "components/fixed-teacher-shift-koma-register-card" */).then(c => wrapFunctional(c.default || c))
export const FixedTeacherShiftKomasApplyDialog = () => import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftKomasApplyDialog.vue' /* webpackChunkName: "components/fixed-teacher-shift-komas-apply-dialog" */).then(c => wrapFunctional(c.default || c))
export const FixedTeacherShiftKomasRegisterDialog = () => import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftKomasRegisterDialog.vue' /* webpackChunkName: "components/fixed-teacher-shift-komas-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const TeacherFixedShiftsPanels = () => import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/TeacherFixedShiftsPanels.vue' /* webpackChunkName: "components/teacher-fixed-shifts-panels" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanCreateDialog = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/create/StudyPlanCreateDialog.vue' /* webpackChunkName: "components/study-plan-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanTextbookRegisterDialog = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/registerTextbooks/StudyPlanTextbookRegisterDialog.vue' /* webpackChunkName: "components/study-plan-textbook-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const WeeklyStudyPlanMemoRegisterDialog = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/registerWeeklyMemo/WeeklyStudyPlanMemoRegisterDialog.vue' /* webpackChunkName: "components/weekly-study-plan-memo-register-dialog" */).then(c => wrapFunctional(c.default || c))
export const TextbookSearchBottomDrawerForStudyPlanForm = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/search/TextbookSearchBottomDrawerForStudyPlanForm.vue' /* webpackChunkName: "components/textbook-search-bottom-drawer-for-study-plan-form" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanUpdateDialog = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/update/StudyPlanUpdateDialog.vue' /* webpackChunkName: "components/study-plan-update-dialog" */).then(c => wrapFunctional(c.default || c))
export const StudyPlanUpdateForm = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/update/StudyPlanUpdateForm.vue' /* webpackChunkName: "components/study-plan-update-form" */).then(c => wrapFunctional(c.default || c))
export const TextbookCreateDialog = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/TextbookCreateDialog.vue' /* webpackChunkName: "components/textbook-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const TextbookEditDialog = () => import('../../src/domain/fragments/reco/contents/textbook/form/edit/TextbookEditDialog.vue' /* webpackChunkName: "components/textbook-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const TextbookEditForm = () => import('../../src/domain/fragments/reco/contents/textbook/form/edit/TextbookEditForm.vue' /* webpackChunkName: "components/textbook-edit-form" */).then(c => wrapFunctional(c.default || c))
export const OthersTextbookSearchBottomDrawer = () => import('../../src/domain/fragments/reco/contents/textbook/form/search/OthersTextbookSearchBottomDrawer.vue' /* webpackChunkName: "components/others-textbook-search-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const PreviousTextbookSearchBottomDrawer = () => import('../../src/domain/fragments/reco/contents/textbook/form/search/PreviousTextbookSearchBottomDrawer.vue' /* webpackChunkName: "components/previous-textbook-search-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const TextbookSearchBottomDrawer = () => import('../../src/domain/fragments/reco/contents/textbook/form/search/TextbookSearchBottomDrawer.vue' /* webpackChunkName: "components/textbook-search-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const TextbookTemplateSearchBottomDrawer = () => import('../../src/domain/fragments/reco/contents/textbook/form/search/TextbookTemplateSearchBottomDrawer.vue' /* webpackChunkName: "components/textbook-template-search-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const GuidanceProgressPagination = () => import('../../src/domain/fragments/reco/contents/textbook/textbookStudy/guidanceProgress/GuidanceProgressPagination.vue' /* webpackChunkName: "components/guidance-progress-pagination" */).then(c => wrapFunctional(c.default || c))
export const SchoolLessonProgressPagination = () => import('../../src/domain/fragments/reco/contents/textbook/textbookStudy/schoolLessonProgress/SchoolLessonProgressPagination.vue' /* webpackChunkName: "components/school-lesson-progress-pagination" */).then(c => wrapFunctional(c.default || c))
export const TotalStudyProgressPagination = () => import('../../src/domain/fragments/reco/contents/textbook/textbookStudy/totalStudyProgress/TotalStudyProgressPagination.vue' /* webpackChunkName: "components/total-study-progress-pagination" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomasApplyDialog = () => import('../../src/domain/fragments/reco/guidance/koma/register/apply/GuidanceKomasApplyDialog.vue' /* webpackChunkName: "components/guidance-komas-apply-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaCardForBulkRegistration = () => import('../../src/domain/fragments/reco/guidance/koma/register/bulkRegisterDialog/GuidanceKomaCardForBulkRegistration.vue' /* webpackChunkName: "components/guidance-koma-card-for-bulk-registration" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomasDialogForBulkRegistration = () => import('../../src/domain/fragments/reco/guidance/koma/register/bulkRegisterDialog/GuidanceKomasDialogForBulkRegistration.vue' /* webpackChunkName: "components/guidance-komas-dialog-for-bulk-registration" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaCardForSingleRegistration = () => import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/GuidanceKomaCardForSingleRegistration.vue' /* webpackChunkName: "components/guidance-koma-card-for-single-registration" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaDetailFormDialogForSingleRegistration = () => import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/GuidanceKomaDetailFormDialogForSingleRegistration.vue' /* webpackChunkName: "components/guidance-koma-detail-form-dialog-for-single-registration" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaDialogForSingleRegistration = () => import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/GuidanceKomaDialogForSingleRegistration.vue' /* webpackChunkName: "components/guidance-koma-dialog-for-single-registration" */).then(c => wrapFunctional(c.default || c))
export const GuidanceCustomTestCard = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceCustomTest/GuidanceCustomTestCard.vue' /* webpackChunkName: "components/guidance-custom-test-card" */).then(c => wrapFunctional(c.default || c))
export const GuidanceCustomTestCreateForm = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceCustomTest/GuidanceCustomTestCreateForm.vue' /* webpackChunkName: "components/guidance-custom-test-create-form" */).then(c => wrapFunctional(c.default || c))
export const GuidanceCustomTestEditDialog = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceCustomTest/GuidanceCustomTestEditDialog.vue' /* webpackChunkName: "components/guidance-custom-test-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceJukuTestCard = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestCard.vue' /* webpackChunkName: "components/guidance-juku-test-card" */).then(c => wrapFunctional(c.default || c))
export const GuidanceJukuTestCreateForm = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestCreateForm.vue' /* webpackChunkName: "components/guidance-juku-test-create-form" */).then(c => wrapFunctional(c.default || c))
export const GuidanceJukuTestEditDialog = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestEditDialog.vue' /* webpackChunkName: "components/guidance-juku-test-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceJukuTestListDialog = () => import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestListDialog.vue' /* webpackChunkName: "components/guidance-juku-test-list-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaTextbookStudyCreateDialog = () => import('../../src/domain/fragments/reco/guidance/report/progress/create/GuidanceKomaTextbookStudyCreateDialog.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaTextbookStudyCreateForm = () => import('../../src/domain/fragments/reco/guidance/report/progress/create/GuidanceKomaTextbookStudyCreateForm.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-create-form" */).then(c => wrapFunctional(c.default || c))
export const TextbookSearchBottomDrawerForGuidanceProgress = () => import('../../src/domain/fragments/reco/guidance/report/progress/create/TextbookSearchBottomDrawerForGuidanceProgress.vue' /* webpackChunkName: "components/textbook-search-bottom-drawer-for-guidance-progress" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaTextbookStudyUpdateDialog = () => import('../../src/domain/fragments/reco/guidance/report/progress/update/GuidanceKomaTextbookStudyUpdateDialog.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-update-dialog" */).then(c => wrapFunctional(c.default || c))
export const BasicInformationFormForStudyPlanCreate = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/create/steps/BasicInformationFormForStudyPlanCreate.vue' /* webpackChunkName: "components/basic-information-form-for-study-plan-create" */).then(c => wrapFunctional(c.default || c))
export const SubjectsSelectionFormForStudyPlanCreate = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/create/steps/SubjectsSelectionFormForStudyPlanCreate.vue' /* webpackChunkName: "components/subjects-selection-form-for-study-plan-create" */).then(c => wrapFunctional(c.default || c))
export const SelectedTextbooksPanelsViewForStudyPlanUpdate = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/update/selectionTextbooks/SelectedTextbooksPanelsViewForStudyPlanUpdate.vue' /* webpackChunkName: "components/selected-textbooks-panels-view-for-study-plan-update" */).then(c => wrapFunctional(c.default || c))
export const TextbooksSelectionFormForStudyPlanUpdate = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/update/selectionTextbooks/TextbooksSelectionFormForStudyPlanUpdate.vue' /* webpackChunkName: "components/textbooks-selection-form-for-study-plan-update" */).then(c => wrapFunctional(c.default || c))
export const TextbooksSelectionListViewForStudyPlanUpdate = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/update/selectionTextbooks/TextbooksSelectionListViewForStudyPlanUpdate.vue' /* webpackChunkName: "components/textbooks-selection-list-view-for-study-plan-update" */).then(c => wrapFunctional(c.default || c))
export const DetailInputFormForCreatingTextbookByOthers = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byOthers/DetailInputFormForCreatingTextbookByOthers.vue' /* webpackChunkName: "components/detail-input-form-for-creating-textbook-by-others" */).then(c => wrapFunctional(c.default || c))
export const FormForCreatingTextbookByOthers = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byOthers/FormForCreatingTextbookByOthers.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-others" */).then(c => wrapFunctional(c.default || c))
export const DetailInputFormForCreatingTextbookByOwnTextbooks = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byOwn/DetailInputFormForCreatingTextbookByOwnTextbooks.vue' /* webpackChunkName: "components/detail-input-form-for-creating-textbook-by-own-textbooks" */).then(c => wrapFunctional(c.default || c))
export const FormForCreatingTextbookByOwnTextbooks = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byOwn/FormForCreatingTextbookByOwnTextbooks.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-own-textbooks" */).then(c => wrapFunctional(c.default || c))
export const TextbookTemplateCard = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/TextbookTemplateCard.vue' /* webpackChunkName: "components/textbook-template-card" */).then(c => wrapFunctional(c.default || c))
export const FormForCreatingTextbook = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/new/FormForCreatingTextbook.vue' /* webpackChunkName: "components/form-for-creating-textbook" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaDetailFormForTransfer = () => import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/transfer/GuidanceKomaDetailFormForTransfer.vue' /* webpackChunkName: "components/guidance-koma-detail-form-for-transfer" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaTransferDialog = () => import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/transfer/GuidanceKomaTransferDialog.vue' /* webpackChunkName: "components/guidance-koma-transfer-dialog" */).then(c => wrapFunctional(c.default || c))
export const SelectedTextbooksPanelsView = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/create/steps/selectionTextbooks/SelectedTextbooksPanelsView.vue' /* webpackChunkName: "components/selected-textbooks-panels-view" */).then(c => wrapFunctional(c.default || c))
export const TextbooksSelectionFormForStudyPlanCreate = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/create/steps/selectionTextbooks/TextbooksSelectionFormForStudyPlanCreate.vue' /* webpackChunkName: "components/textbooks-selection-form-for-study-plan-create" */).then(c => wrapFunctional(c.default || c))
export const TextbooksSelectionListView = () => import('../../src/domain/fragments/reco/contents/studyPlan/form/create/steps/selectionTextbooks/TextbooksSelectionListView.vue' /* webpackChunkName: "components/textbooks-selection-list-view" */).then(c => wrapFunctional(c.default || c))
export const DetailInputFormForCreatingTextbookByTemplatesFilteredBySchool = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterBySchool/DetailInputFormForCreatingTextbookByTemplatesFilteredBySchool.vue' /* webpackChunkName: "components/detail-input-form-for-creating-textbook-by-templates-filtered-by-school" */).then(c => wrapFunctional(c.default || c))
export const FormForCreatingTextbookByTemplatesFilteredBySchool = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterBySchool/FormForCreatingTextbookByTemplatesFilteredBySchool.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-templates-filtered-by-school" */).then(c => wrapFunctional(c.default || c))
export const DetailFormForCreatingTextbookByTemplatesFilteredByTextbookTags = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/DetailFormForCreatingTextbookByTemplatesFilteredByTextbookTags.vue' /* webpackChunkName: "components/detail-form-for-creating-textbook-by-templates-filtered-by-textbook-tags" */).then(c => wrapFunctional(c.default || c))
export const FormForCreatingTextbookByTemplatesFilteredByTextbookTags = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/FormForCreatingTextbookByTemplatesFilteredByTextbookTags.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-templates-filtered-by-textbook-tags" */).then(c => wrapFunctional(c.default || c))
export const TemplateSelectionForCreatingTextbookByTemplatesFilteredByTextbookTags = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/TemplateSelectionForCreatingTextbookByTemplatesFilteredByTextbookTags.vue' /* webpackChunkName: "components/template-selection-for-creating-textbook-by-templates-filtered-by-textbook-tags" */).then(c => wrapFunctional(c.default || c))
export const TextbookTagsSelection = () => import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/TextbookTagsSelection.vue' /* webpackChunkName: "components/textbook-tags-selection" */).then(c => wrapFunctional(c.default || c))
export const GuidanceKomaCardForTransfer = () => import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/transfer/komaList/GuidanceKomaCardForTransfer.vue' /* webpackChunkName: "components/guidance-koma-card-for-transfer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
