import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

export const usePageStore = defineStore('page', () => {
  const currentPageTitle = ref('');

  const get = (): Readonly<string> => currentPageTitle.value;
  const set = (title: string): void => {
    currentPageTitle.value = title;
  };

  return { get, set };
});
