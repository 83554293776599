


// eslint-disable-next-line import/order
import { ref, provide, InjectionKey, Ref, useRouter } from '@nuxtjs/composition-api';
import { useErrorDialog, ErrorDialogKey } from '~/src/shared/composables/errorDialog';
import { SnackbarKey, useSnackbar } from '~/src/shared/composables/snackBar';
import { useOfflineErrorDialogStore } from '~/src/framework/store/errorHandling/offlineErrorDialog';
import { useNotAuthenticatedErrorDialogStore } from '~/src/framework/store/errorHandling/notAuthenticatedErrorDialog';
import { useNotAuthenticatedClassroomErrorDialogStore } from '~/src/framework/store/errorHandling/notAuthenticatedClassroomErrorDialog';
import { i18n } from '~/src/shared/locales/i18n';
import { defineComponent } from '@nuxtjs/composition-api';
import { preventZoom } from '~/src/infrastructure/browser/preventZoom';

/* 拡大縮小を禁止 */
preventZoom();
export const GlobalLoadingState: InjectionKey<Ref<boolean>> = Symbol('GlobalLoadingState');
export const GlobalOverlayState: InjectionKey<Ref<boolean>> = Symbol('GlobalOverlayState');
export const GuidanceKomasBulkRegisterModeKey: InjectionKey<Ref<boolean>> = Symbol('GuidanceKomasBulkRegisterMode');
const __sfc_main = defineComponent({
  name: 'Default'
});
__sfc_main.setup = (__props, __ctx) => {
  const KEEP_ALIVE_PAGE_PATHS = (['PagesGuidance'] as const);
  const router = useRouter();

  /**
   * providers
   */
  const isLoading = ref(false);
  provide(GlobalLoadingState, isLoading);
  const errorDialogCtx = useErrorDialog();
  provide(ErrorDialogKey, errorDialogCtx);
  const snackbarCtx = useSnackbar();
  provide(SnackbarKey, snackbarCtx);

  // 指導コマ一括登録中を管理するフラグ
  const isGuidanceKomasBulkRegisterMode = ref(false);
  provide(GuidanceKomasBulkRegisterModeKey, isGuidanceKomasBulkRegisterMode);

  /**
   *
   * error dialog
   *
   */
  /**
   * オフライン時のエラー
   */
  const offlineErrorDialogStore = useOfflineErrorDialogStore();

  /**
   * リフレッシュトークン切れエラー
   */
  const notAuthenticatedErrorDialogStore = useNotAuthenticatedErrorDialogStore();
  const closeNotAuthenticatedErrorDialog = () => {
    notAuthenticatedErrorDialogStore.close();
    router.push('/sign-in/teacher');
  };

  /**
   * 教室認証Cookieエラー
   */
  // prettier-ignore
  const notAuthenticatedClassroomErrorDialogStore = useNotAuthenticatedClassroomErrorDialogStore();
  const closeNotAuthenticatedClassroomErrorDialog = () => {
    notAuthenticatedClassroomErrorDialogStore.close();
    router.push('/sign-in/classroom');
  };
  return {
    i18n,
    KEEP_ALIVE_PAGE_PATHS,
    isLoading,
    errorDialogCtx,
    snackbarCtx,
    offlineErrorDialogStore,
    notAuthenticatedErrorDialogStore,
    closeNotAuthenticatedErrorDialog,
    notAuthenticatedClassroomErrorDialogStore,
    closeNotAuthenticatedClassroomErrorDialog
  };
};
export default __sfc_main;
