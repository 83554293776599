import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getOptionsForGuidanceKoma } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { OptionsForGuidanceKomaRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/guidanceKoma/optionsForGuidanceKoma';
import { deepCopy } from '~/src/shared/objectOperationUtil';
import { logger } from '~/src/framework/plugins/di/logger.di';

// FIXME: これらの型は必要ないので削除
export interface ManagementUserOption {
  managementUserId: number;
  name: string;
}
export interface ClassroomOption {
  id: number;
  classroomName: string;
}
export interface KomaTemplateOption {
  id: number;
  komaName: string;
}
export interface SubjectAreaOption {
  id: number;
  subjectAreaName: string;
  subjects: SubjectOption[];
}
export interface SubjectOption {
  subjectId: number;
  subjectName: string;
}

export const optionsForGuidanceKomaRepo: OptionsForGuidanceKomaRepositoryInterface = {
  fetch: async (jukuId: number, classroomId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { jukuId, classroomId },
      query: gql(getOptionsForGuidanceKoma)
    });
    if (errors?.length) return err(errors);

    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getOptionsForGuidanceKoma', data.getOptionsForGuidanceKoma);
    const resClone = deepCopy(data.getOptionsForGuidanceKoma);

    // FIXME: バックエンドでソートしたいが、暗号化・複合化の実装の都合上、現状はフロントエンドでソートする
    return ok({
      ...resClone,
      managementUsersGroupedByRole: {
        __typename: 'ManagementUsersGroupedByRole',
        mainTeachers: resClone.managementUsersGroupedByRole.mainTeachers.sort((a, b) =>
          b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
        ),
        mainClassroomMasters:
          resClone.managementUsersGroupedByRole.mainClassroomMasters.sort((a, b) =>
            b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
          ),
        subTeachers: resClone.managementUsersGroupedByRole.subTeachers.sort((a, b) =>
          b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
        ),
        subClassroomMasters:
          resClone.managementUsersGroupedByRole.subClassroomMasters.sort((a, b) =>
            b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
          ),
        managers: resClone.managementUsersGroupedByRole.managers.sort((a, b) =>
          b.furigana === '' ? -1 : a.furigana.localeCompare(b.furigana)
        )
      }
    });
  }
} as const;
