import { i18n } from '~/src/shared/locales/i18n';
import {
  NonPublicationOption,
  NON_PUBLICATION_OPTIONS
} from '~/src/domain/models/reco/exam/common';

export const validationRuleAtoms = {
  validateRange: (label: string, min: number, max: number) => (v: string) => {
    const message = i18n.validateMessages.length.range.$t({
      label,
      min,
      max
    });
    const condition = (v.length >= min && v.length <= max) || message;
    return !v || condition;
  },

  validateRequired:
    (label: string) =>
    <T>(v: T) => {
      // 0は許容にする
      if (typeof v === 'number' && isNaN(v as any))
        return i18n.validateMessages.required.$t({ label });

      if (typeof v === 'number') return true;

      return !!v || i18n.validateMessages.required.$t({ label });
    },

  validateMaxLength: (label: string, length: number) => (v: string | undefined) => {
    const message = i18n.validateMessages.length.within.$t({ label, length });
    const condition = (v?.length ?? 0) <= length || message;
    return !v || condition;
  },

  validateOnlyAlphanumeric: (label: string) => (v: string) => {
    const message = i18n.validateMessages.only.alphanumeric.$t({ label });
    const condition = !!/^[a-zA-Z0-9]+$/.test(v) || message;
    return !v || condition;
  },

  validateAtLeastOneNumber: (label: string) => (v: string) => {
    const message = i18n.validateMessages.atLeastOne.number.$t({ label });
    const condition = /[0-9]/.test(v) || message;
    return !v || condition;
  },
  validateAtLeastOneAlphabet: (label: string) => (v: string) => {
    const message = i18n.validateMessages.atLeastOne.alphabet.$t({ label });
    const condition = /[a-zA-Z]/.test(v) || message;
    return !v || condition;
  },

  validateAtLeastOneLowerCase: (label: string) => (v: string) => {
    const message = i18n.validateMessages.atLeastOne.lowerCase.$t({ label });
    const condition = /[a-z]/.test(v) || message;
    return !v || condition;
  },

  validateAtComparedPasswords: (compareTarget: string) => (v: string) =>
    v === compareTarget || i18n.validateMessages.password.different.$t(),

  validateNumber: (label?: string) => (v: number | string) =>
    v === '' ||
    v === null ||
    typeof v === 'number' ||
    i18n.validateMessages.number.type.$t({ label }),

  validateUnsignedInt: (label?: string) => (v: number | string) =>
    v === '' ||
    v === null ||
    (typeof v === 'number' && Number.isInteger(v) && v >= 0) ||
    i18n.validateMessages.number.unsignedInt.$t({ label }),

  validateNumberRange:
    (label: string, min: number, max: number) => (v: number | string) => {
      // 未入力許容にする
      if (v === '' || v === null) return true;
      const message = i18n.validateMessages.number.range.$t({ label, min, max });
      const condition = (v >= min && v <= max) || message;
      return condition;
    },

  validateLessThanThreeDigits: (label: string, max: number) => (v: string) => {
    // 未入力許容にする
    if (v === '' || v === null) return true;
    const message = i18n.validateMessages.number.digit.$t({ label, max });
    const condition = /^[0-9]{0,3}$/.test(v) || message;
    return !v || condition;
  },

  validateLessThanFiveDigits: (label: string, max: number) => (v: string) => {
    // 未入力許容にする
    if (v === '' || v === null) return true;
    const message = i18n.validateMessages.number.digit.$t({ label, max });
    const condition = /^[0-9]{0,5}$/.test(v) || message;
    return !v || condition;
  },

  /**
   * 試験成績のvalidation
   */
  validateExamScore: (label?: string) => (v: number | NonPublicationOption | null | '') =>
    v === '' ||
    v === null ||
    typeof v === 'number' ||
    (typeof v === 'object' && v.value === NON_PUBLICATION_OPTIONS[0].value) ||
    i18n.validateMessages.examScore.$t({ label }),

  validateExamScoreNumberRange:
    (label: string, min: number, max: number) =>
    (v: number | NonPublicationOption | null | '') => {
      // 未入力と未公開の選択肢は許容にする
      if (
        v === '' ||
        v === null ||
        (typeof v === 'object' && v.value === NON_PUBLICATION_OPTIONS[0].value)
      )
        return true;
      const message = i18n.validateMessages.number.range.$t({ label, min, max });
      const condition = (v >= min && v <= max) || message;
      return !v || condition;
    }
} as const;
