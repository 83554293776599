


// eslint-disable-next-line import/order
import { ref, provide, InjectionKey, Ref, useRoute, computed, useRouter, useContext } from '@nuxtjs/composition-api';
import { pagesPath } from '~/src/framework/plugins/$path';
import { useNotAuthenticatedClassroomErrorDialogStore } from '~/src/framework/store/errorHandling/notAuthenticatedClassroomErrorDialog';
import { useNotAuthenticatedErrorDialogStore } from '~/src/framework/store/errorHandling/notAuthenticatedErrorDialog';
import { useOfflineErrorDialogStore } from '~/src/framework/store/errorHandling/offlineErrorDialog';
import { useErrorDialog, ErrorDialogKey } from '~/src/shared/composables/errorDialog';
import { SnackbarKey, useSnackbar } from '~/src/shared/composables/snackBar';
import { i18n } from '~/src/shared/locales/i18n';
import { defineComponent } from '@nuxtjs/composition-api';
import { preventZoom } from '~/src/infrastructure/browser/preventZoom';
import { useError } from '~/src/shared/composables/error';
export const GlobalLoadingState: InjectionKey<Ref<boolean>> = Symbol('GlobalLoadingState');

/* 拡大縮小を禁止 */
preventZoom();
const __sfc_main = defineComponent({
  name: 'NoHeader'
});
__sfc_main.setup = (__props, __ctx) => {
  const {
    $managementUserRepo
  } = useContext();
  const router = useRouter();
  const route = useRoute();
  const isShowedNavBtn = computed(() => route.value.name === pagesPath.sign_in.teacher.$name());
  const isLoading = ref(false);
  provide(GlobalLoadingState, isLoading);
  const {
    capture
  } = useError();
  const errorDialogCtx = useErrorDialog();
  provide(ErrorDialogKey, errorDialogCtx);
  const {
    isOpenedErrorDialog,
    closeErrorDialog,
    errorMessage
  } = errorDialogCtx;
  const snackbarCtx = useSnackbar();
  provide(SnackbarKey, snackbarCtx);
  const {
    snackbarItem
  } = snackbarCtx;

  /**
   *
   * error dialog
   *
   */
  /**
   * オフライン時のエラー
   */
  const offlineErrorDialogStore = useOfflineErrorDialogStore();

  /**
   * リフレッシュトークン切れエラー
   */
  const notAuthenticatedErrorDialogStore = useNotAuthenticatedErrorDialogStore();
  const closeNotAuthenticatedErrorDialog = () => {
    notAuthenticatedErrorDialogStore.close();
    router.push('/sign-in/teacher');
  };

  /**
   * 教室認証Cookieエラー
   */
  // prettier-ignore
  const notAuthenticatedClassroomErrorDialogStore = useNotAuthenticatedClassroomErrorDialogStore();
  const closeNotAuthenticatedClassroomErrorDialog = () => {
    notAuthenticatedClassroomErrorDialogStore.close();
    router.push('/sign-in/classroom');
  };
  const logoutClassroom = async () => {
    try {
      isLoading.value = true;
      await $managementUserRepo.classroomLogout();
      snackbarCtx.openSnackbar({
        title: i18n.signIn.signOutClassroom.succeed.$t()
      });
      router.push(pagesPath.sign_in.classroom.$url());
    } catch (e) {
      capture(e);
    } finally {
      isLoading.value = false;
    }
  };
  return {
    i18n,
    isShowedNavBtn,
    isLoading,
    isOpenedErrorDialog,
    closeErrorDialog,
    errorMessage,
    snackbarCtx,
    snackbarItem,
    offlineErrorDialogStore,
    notAuthenticatedErrorDialogStore,
    closeNotAuthenticatedErrorDialog,
    notAuthenticatedClassroomErrorDialogStore,
    closeNotAuthenticatedClassroomErrorDialog,
    logoutClassroom
  };
};
export default __sfc_main;
