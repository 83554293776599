export const DATE_FORMATS = {
  fullDateTime: 'YYYY/M/D(ddd)\x20HH:mm:ss',
  fullDate: 'YYYY/M/D(ddd)',
  date: 'M/D(ddd)',
  // v-calendarやdate-picker用のフォーマット
  iso: 'YYYY-MM-DD',
  yearMonth: 'YYYY/M',
  year: 'YYYY',
  month: 'M',
  day: 'D',
  dayOfWeek: 'dd',
  time: 'HH:mm'
} as const;

// TODO: ユニットテスト
export const getDateRangeText = (start: string, end: string): string => {
  const s = dayjs(start);
  const e = dayjs(end);
  if (s.get('year') !== e.get('year'))
    return s.format(DATE_FORMATS.fullDate) + '〜' + e.format(DATE_FORMATS.fullDate);

  // MEMO:どういうふうに出し分けるか若干悩んでる
  // if (s.get('month') !== e.get('month'))
  return s.format(DATE_FORMATS.fullDate) + '〜' + e.format('M/D(ddd)');

  // if (s.get('date') !== e.get('date'))
  //   return s.format(DATE_FORMATS.fullDate) + '〜' + e.format('D(ddd)');

  // return s.format(DATE_FORMATS.fullDate);
};

// TODO: DIしたものを使うようにする
import dayjsBuf from 'dayjs';
import ja from 'dayjs/locale/ja';
dayjsBuf.locale({ ...ja });
export const dayjs = dayjsBuf;
