export const errors = {
  parse: { $t: () => `エラーが発生しました。` },
  notExist: { $t: (target?: string) => `${target ?? 'データ'}が存在しません。` },
  duplicate: { $t: () => `データが重複しています。` },
  fatal: { $t: () => `エラーが発生しました。` },
  invalidType: { $t: () => `エラーが発生しました。` },
  authentication: {
    $t: () => `ログインに失敗しました。\nユーザー名またはパスワードが違います。`
  },
  maintenanceMode: { $t: () => `只今メンテナンス中です。` },
  authorization: { $t: () => `エラーが発生しました。` },
  serviceUnavailable: {
    $t: () => `エラーが発生しました。\n時間が経ってから再度送信してください。`
  },
  other: { $t: () => `エラーが発生しました。` },
  authorizeException: { $t: () => `ユーザー名またはパスワードが違います` },
  unexpected: {
    $t: () => `予期しないエラーが発生しました。`
  },
  refreshedAuthTokenError: {
    $t: () => `認証情報の有効期限が切れました。ログイン画面に戻ります。`
  },
  classroomLoginError: {
    $t: () => `教室認証情報が切れました。教室ログイン画面に戻ります。`
  },
  roleError: {
    $t: () => `権限がないため操作することが出来ません。`
  },
  offlineError: {
    $t: () => `通信ができない状態にあります。通信ができる環境かを確認してください。`
  },
  validationForm: {
    $t: () => `入力内容に誤りがあります。\n入力内容をご確認の上、再度実行してください。`
  }
};
