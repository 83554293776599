
import { useRouter, ref, computed, useRoute, watch, inject, useContext } from '@nuxtjs/composition-api';
import { GlobalLoadingState, GuidanceKomasBulkRegisterModeKey } from '~/src/framework/layouts/default.vue';
import { pagesPath } from '~/src/framework/plugins/$path';
import { useCurrentRouteStore } from '~/src/framework/store/currentRouteState';
import { usePageStore } from '~/src/framework/store/page';
import { useUserStore } from '~/src/framework/store/user';
import { useClassroomStore } from '~/src/framework/store/classroom/classroom';
import { Student } from '~/src/domain/entities/schema';
import { useAuth } from '~/src/domain/useCases/user/auth';
import { useError } from '~/src/shared/composables/error';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const userStore = useUserStore();
  const classroomStore = useClassroomStore();
  const pageStore = usePageStore();
  const route = useRoute();
  const router = useRouter();
  const currentRouteStore = useCurrentRouteStore();
  const auth = useAuth();
  const {
    $studentRepo
  } = useContext();
  const {
    capture
  } = useError();
  const isLoading = inject(GlobalLoadingState)!;
  const isGuidanceKomasBulkRegisterMode = inject(GuidanceKomasBulkRegisterModeKey)!;
  const isOpenedMenu = ref(false);
  const openMenu = () => isOpenedMenu.value = true;
  const toggleDialog = () => isOpenedMenu.value = !isOpenedMenu.value;

  // サインアウト
  const signOut = async () => {
    try {
      isLoading.value = true;
      await auth.signOut();
      isOpenedMenu.value = false;
      router.push(pagesPath.sign_in.teacher.$url());
    } catch (e) {
      capture(e);
    } finally {
      isLoading.value = false;
    }
  };

  // ページ遷移
  const toHomePage = () => router.push(pagesPath.home.guidance_schedules.$url());
  const toPreviousPage = () => router.back();
  const toStudentInfo = () => {
    if (!studentId.value) return;
    router.push(pagesPath.home.student._studentId(studentId.value).info.$url());
  };
  const idShowedBrowserBackButton = computed(() => ![pagesPath.home.students.$name(), pagesPath.home.teachers.list.$name(), pagesPath.home.teachers.shifts.$name(), pagesPath.home.guidance_schedules.$name(), pagesPath.home.tasks.teacher.$name(), pagesPath.home.student._studentId('').guidance_report.histories.$name()].includes(currentRouteStore.get()));

  /**
   * 生徒ページ用
   */
  const isStudentPage = computed(() => !!route.value.name?.match(/home-student-studentId/));

  // 選択された生徒
  const selectedStudent = ref<Student | null>(null);
  const studentId = computed(() => route.value.params.studentId ? parseInt(route.value.params.studentId) : null);
  watch(() => studentId.value, async () => {
    if (!studentId.value) return;
    selectedStudent.value = (await $studentRepo.fetch(studentId.value)).match(data => data, _e => null);
  }, {
    immediate: true
  });
  return {
    pagesPath,
    userStore,
    classroomStore,
    pageStore,
    currentRouteStore,
    isGuidanceKomasBulkRegisterMode,
    isOpenedMenu,
    openMenu,
    toggleDialog,
    signOut,
    toHomePage,
    toPreviousPage,
    toStudentInfo,
    idShowedBrowserBackButton,
    isStudentPage,
    selectedStudent,
    studentId
  };
};
export default __sfc_main;
