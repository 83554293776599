import { Result, err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { GuidanceIndividualTaskRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/guidanceTask';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  GuidanceIndividualTasksMoveInput,
  Mutation,
  Query
} from '~/src/domain/entities/schema';
import { getGuidanceIndividualTasksByGuidanceReportId } from '~/src/infrastructure/apis/graphql/query/queries';
import {
  completeGuidanceIndividualTask,
  createGuidanceIndividualTask,
  deleteGuidanceIndividualTask,
  incompleteGuidanceIndividualTask,
  moveGuidanceIndividualTasks,
  updateGuidanceIndividualTask
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  CompleteGuidanceIndividualTaskInput,
  CreateGuidanceIndividualTaskInput,
  DeleteGuidanceIndividualTaskInput,
  GuidanceIndividualTask,
  IncompleteGuidanceIndividualTaskInput,
  UpdateGuidanceIndividualTaskInput
} from '~/src/domain/entities/schema';
import { deepCopy } from '~/src/shared/objectOperationUtil';

export const guidanceTaskRepo: GuidanceIndividualTaskRepositoryInterface = {
  fetch: async (
    guidanceReportId: number
  ): Promise<
    Result<GuidanceIndividualTask[], readonly GraphQLError[] | DataNotExistError>
  > => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { guidanceReportId },
      query: gql(getGuidanceIndividualTasksByGuidanceReportId)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getGuidanceIndividualTasksByGuidanceReportId', data);
    return ok(deepCopy(data.getGuidanceIndividualTasksByGuidanceReportId));
  },
  create: async (
    input: CreateGuidanceIndividualTaskInput
  ): Promise<Result<boolean, readonly GraphQLError[] | DataNotExistError>> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createGuidanceIndividualTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('createGuidanceIndividualTask', data);
    return ok(data.createGuidanceIndividualTask);
  },
  update: async (
    input: UpdateGuidanceIndividualTaskInput
  ): Promise<Result<boolean, readonly GraphQLError[] | DataNotExistError>> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateGuidanceIndividualTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('updateGuidanceIndividualTask', data);
    return ok(data.updateGuidanceIndividualTask);
  },
  delete: async (
    input: DeleteGuidanceIndividualTaskInput
  ): Promise<Result<boolean, readonly GraphQLError[] | DataNotExistError>> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteGuidanceIndividualTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('deleteGuidanceIndividualTask', data);
    return ok(data.deleteGuidanceIndividualTask);
  },
  complete: async (
    input: CompleteGuidanceIndividualTaskInput
  ): Promise<Result<boolean, readonly GraphQLError[] | DataNotExistError>> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(completeGuidanceIndividualTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('completeGuidanceIndividualTask', data);
    return ok(data.completeGuidanceIndividualTask);
  },
  incomplete: async (
    input: IncompleteGuidanceIndividualTaskInput
  ): Promise<Result<boolean, readonly GraphQLError[] | DataNotExistError>> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(incompleteGuidanceIndividualTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('incompleteGuidanceIndividualTask', data);
    return ok(data.incompleteGuidanceIndividualTask);
  },
  move: async (
    inputs: GuidanceIndividualTasksMoveInput[]
  ): Promise<Result<boolean, readonly GraphQLError[] | DataNotExistError>> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { inputs },
      mutation: gql(moveGuidanceIndividualTasks)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('moveGuidanceIndividualTasks', data);
    return ok(data.moveGuidanceIndividualTasks);
  }
};
