
import { computed } from '@nuxtjs/composition-api';
import { AccessStatus } from '~/src/domain/entities/schema';
interface Props {
  status: AccessStatus;
}
const __sfc_main = {};
__sfc_main.props = {
  status: {
    key: "status",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const getLabelStyle = (status: AccessStatus) => status === 'enter' ? 'background: #00A5EF;' : 'background: #757575;';
  const getLabelText = (status: AccessStatus) => status === 'enter' ? '入室中' : '退室中';
  const props = __props;
  const style = computed(() => getLabelStyle(props.status));
  const text = computed(() => getLabelText(props.status));
  return {
    style,
    text
  };
};
export default __sfc_main;
