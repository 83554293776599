import { ref } from '@nuxtjs/composition-api';

// export enum ConfirmationType {
//   LOGOUT,
//   CHANGE_CLASSROOM,
//   REPORT,
//   DELETE,
//   CANCEL,
//   RESET_REGISTRATION
// }

export const useConfirmationDialog = () => {
  const isOpenedConfirmationDialog = ref(false);
  const openConfirmationDialog = () => {
    isOpenedConfirmationDialog.value = true;
  };
  const closeConfirmationDialog = () => {
    isOpenedConfirmationDialog.value = false;
  };

  const confirmationMessage = ref('');

  const confirmedEvent = ref(() => {});

  // const confirmationType = ref<ConfirmationType>(ConfirmationType.CANCEL)

  return {
    isOpenedConfirmationDialog,
    openConfirmationDialog,
    closeConfirmationDialog,
    confirmationMessage,
    confirmedEvent
  };
};

// export type ConfirmationDialog = ReturnType<typeof useConfirmationDialog>
// // InjectionKey
// export const ConfirmationDialogKey: InjectionKey<ConfirmationDialog> =
//   Symbol('ConfirmationDialog')
