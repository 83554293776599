


import { computed, ref, useContext, useRouter, watch } from '@nuxtjs/composition-api';
import { pagesPath } from '~/src/framework/plugins/$path';
import { ClassroomOption } from '~/src/infrastructure/repositories/reco/guidance/guidanceKoma/optionsForGuidanceKoma';
import { useUserStore } from '~/src/framework/store/user';
import { VForm as VFormType } from '~/src/@types';
import { Classroom } from '~/src/domain/entities/schema';
import { useConfirmationDialog } from '~/src/shared/composables/confirmationDialog';
import { classroomValidationRules } from '~/src/domain/models/user/classroom/validation';
import { i18n } from '~/src/shared/locales/i18n';
import { useClassroomStore } from '~/src/framework/store/classroom/classroom';
import { useClassroomOptionsStore } from '~/src/framework/store/classroom/classroomOptions';
import { LocalStorageKey } from '~/src/domain/interfaces/browser/localStorage';
interface Props {
  isOpened: boolean;
}
const getClassroomToRadioOptions = (classrooms: Classroom[]): ClassroomOption[] => {
  return classrooms.map(classroom => {
    return {
      id: classroom.id,
      classroomName: classroom.classroomName
    };
  });
};
const findSelectedClassRoom = (options: ClassroomOption[], id: number) => options.find(option => option.id === id);
const __sfc_main = {};
__sfc_main.props = {
  isOpened: {
    key: "isOpened",
    required: true,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const router = useRouter();
  const userStore = useUserStore();
  const classroomStore = useClassroomStore();
  const classroomOptionsStore = useClassroomOptionsStore();
  const {
    $localStorage
  } = useContext();

  // 確認ダイアログ
  const {
    isOpenedConfirmationDialog,
    openConfirmationDialog,
    closeConfirmationDialog,
    confirmationMessage
  } = useConfirmationDialog();

  // validation
  const isValid = ref(false);
  const classroomChangeFormRef = ref<VFormType | null>(null);
  const {
    classroomValidationRule
  } = classroomValidationRules;
  const user = userStore.get();

  /**
   * userがteacherの場合
   */
  const mainClassroom = ref<Classroom | null>(null);
  const mainClassroomOptions = ref<ClassroomOption[]>([]);
  const selectedMainClassroom = ref(0);
  const subClassrooms = ref<Classroom[]>([]);
  const subClassroomOptions = ref<ClassroomOption[]>([]);
  const selectedSubClassroom = ref(0);

  /**
   * userがmanagerの場合
   */
  const selectedClassroomForManager = ref<Classroom>(classroomStore.get());

  // 選択された教室をオブジェクトでもつ
  const selectedClassroom = computed(() => {
    if (user.__typename === 'Teacher') {
      return selectedMainClassroom.value ? findSelectedClassRoom(mainClassroomOptions.value, selectedMainClassroom.value) : findSelectedClassRoom(subClassroomOptions.value, selectedSubClassroom.value);
    } else return selectedClassroomForManager.value;
  });

  /**
   * 教室切り替え
   */
  const changeClassroom = () => {
    closeConfirmationDialog();
    if (!classroomChangeFormRef.value?.validate()) return;
    if (user.__typename === 'Teacher') {
      if (selectedMainClassroom.value) {
        $localStorage.setItem(LocalStorageKey.currentClassroomId, String(selectedMainClassroom.value));
      } else {
        $localStorage.setItem(LocalStorageKey.currentClassroomId, String(selectedSubClassroom.value));
      }
    } else {
      $localStorage.setItem(LocalStorageKey.currentClassroomId, String(selectedClassroomForManager.value.id));
    }
    router.push(pagesPath.home.guidance_schedules.$url());

    // 教室をセット
    // MEMO: middlewareでもセットしているが、
    // すでに指導予定のページにいるときはmiddleware通らないので必ずここでセット
    const selectedClassroomId = (() => {
      switch (user.__typename) {
        case 'Teacher':
          return selectedMainClassroom.value || selectedSubClassroom.value;
        case 'Manager':
          return selectedClassroomForManager.value.id;
        default:
          return null;
      }
    })();
    const storeClassroom = classroomOptionsStore.get().find(classroom => classroom.id === selectedClassroomId);
    if (!storeClassroom) return;
    classroomStore.set(storeClassroom);
    emit('close');
  };

  // 教室切り替え確認
  const confirmChangingClassroom = () => {
    const classroomName = selectedClassroom.value ? selectedClassroom.value.classroomName : '';
    confirmationMessage.value = i18n.confirm.changeClassroom.$t({
      target: classroomName
    });
    openConfirmationDialog();
  };

  /**
   * 教室選択はメインとサブ合わせて一つに
   */
  watch(() => selectedSubClassroom.value, () => {
    if (selectedSubClassroom.value) {
      selectedMainClassroom.value = 0;
    }
  });
  watch(() => selectedMainClassroom.value, () => {
    if (selectedMainClassroom.value) {
      selectedSubClassroom.value = 0;
    }
  });

  /**
   * init
   */

  if (user.__typename === 'Teacher') {
    mainClassroom.value = user.mainClassroom;
    mainClassroomOptions.value = getClassroomToRadioOptions([mainClassroom.value]);
    subClassrooms.value = user.subClassrooms;
    subClassroomOptions.value = getClassroomToRadioOptions(subClassrooms.value);
  }

  // currentClassroomからどの教室がすでに選択されているかを算出
  if (mainClassroom.value && mainClassroom.value.id === classroomStore.get().id) {
    selectedMainClassroom.value = mainClassroom.value.id;
  }
  for (const classroom of subClassroomOptions.value) {
    if (classroom.id === classroomStore.get().id) {
      selectedSubClassroom.value = classroom.id;
      break;
    }
  }
  return {
    i18n,
    classroomStore,
    classroomOptionsStore,
    isOpenedConfirmationDialog,
    closeConfirmationDialog,
    confirmationMessage,
    isValid,
    classroomChangeFormRef,
    classroomValidationRule,
    user,
    mainClassroomOptions,
    selectedMainClassroom,
    subClassroomOptions,
    selectedSubClassroom,
    selectedClassroomForManager,
    changeClassroom,
    confirmChangingClassroom
  };
};
export default __sfc_main;
