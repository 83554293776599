export const validateMessages = {
  required: { $t: ({ label }: { label: string }) => `${label}は必須です` },
  requiredToSelect: {
    $t: ({ label }: { label: string }) => `${label}を選択してください`
  },
  length: {
    within: {
      $t: ({ label, length }: { label: string; length: number | number }) =>
        `${label}は${length}文字以内で入力してください`
    },
    range: {
      // prettier-ignore
      $t: ({ label, min, max }: { label: string; min: string | number; max: string | number }) =>
        `${label}は${min}文字以上、${max}文字以内で入力してください`
    }
  },
  atLeastOne: {
    number: {
      $t: ({ label }: { label: string }) =>
        `${label}は1文字以上の数字が含まれている必要があります`
    },
    alphabet: {
      $t: ({ label }: { label: string }) =>
        `${label}は1文字以上のアルファベットが含まれている必要があります`
    },
    lowerCase: {
      $t: ({ label }: { label: string }) =>
        `${label}は1文字以上の小文字のアルファベットが含まれている必要があります`
    }
  },
  only: {
    number: {
      $t: ({ label }: { label: string }) =>
        `${label}は1文字以上の数字が含まれている必要があります`
    },
    alphabet: {
      $t: ({ label }: { label: string }) =>
        `${label}は1文字以上のアルファベットが含まれている必要があります`
    },
    alphanumeric: {
      $t: ({ label }: { label: string }) => `${label}は半角英数字のみ使用できます`
    }
  },
  password: {
    required: { $t: () => 'パスワードは必須です' },
    length: { $t: () => 'パスワードは8文字以上128文字以内で入力してください' },
    character: { $t: () => 'パスワードは半角英数字のみ使用できます' },
    number: { $t: () => 'パスワードは1文字以上の数字が含まれている必要があります' },
    alphabet: {
      $t: () => 'パスワードは1文字以上のアルファベットが含まれている必要があります'
    },
    lowercase: {
      $t: () =>
        'パスワードは1文字以上の小文字のアルファベットが含まれている必要があります'
    },
    new: { $t: () => `パスワードを変更します。\n新しいパスワードを入力してください` },
    different: {
      $t: () => '新しいパスワードと新しいパスワード(確認用)が一致していません'
    }
  },
  number: {
    type: {
      $t: (label: { label?: string }) =>
        label.label ? `${label.label}は数字を入力してください` : '数字を入力してください'
    },
    range: {
      // prettier-ignore
      $t: ({ label, min, max }: { label: string; min: string | number; max: string | number }) =>
        `${label}は${min}以上、${max}以内で入力してください`
    },
    digit: {
      $t: ({ label, max }: { label: string; max: string | number }) =>
        `${label}は${max}桁以内で入力してください`
    },
    unsignedInt: {
      $t: (label: { label?: string }) =>
        label.label
          ? `${label.label}は0以上の整数を入力してください`
          : '0以上の整数を入力してください'
    }
  },
  date: {
    range: { $t: () => '正しい日付範囲を入力してください' },
    outOfTerm: { $t: () => '振替可能期間を過ぎています' },
    maximumKomaLength: {
      $t: () => `扱えるコマ数は最大で240コマまでです。`
    }
  },
  range: {
    none: { $t: () => '範囲を入力してください' },
    number: { $t: () => '範囲を正しく入力してください' },
    duplicate: { $t: () => '範囲が重複しています' },
    out: { $t: () => '範囲外です' }
  },
  transferDate: {
    outOfTerm: { $t: () => '振替可能期間を過ぎています' }
  },
  examScore: {
    $t: (label: { label?: string }) =>
      label.label
        ? `${label.label}は数字入力もしくは「なし」を選択してください`
        : '数字入力もしくは「なし」を選択してください'
  },
  textbook: {
    studyRangeLimit: { $t: () => '合計6000以下で登録してください' }
  }
} as const;
