export const noDataMessages = {
  // prettier-ignore
  unregistered: { $t: ({ label }: { label: string }) => `${label}はまだ登録されていません。` },
  notCreated: {
    $t: ({ label }: { label: string }) => `${label}はまだ作成されていません。`
  },
  notFound: { $t: ({ label }: { label: string }) => `${label}はありません。` },
  // prettier-ignore
  noSearchedResults: { $t: ({ label }: { label: string }) => `検索条件に一致する${label}がありませんでした。` },
  notSet: { $t: ({ label }: { label: string }) => `${label}は未設定です。` }
};
