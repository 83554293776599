export const getStudentTextbooksByStudentId = /* GraphQL */ `
  query getStudentTextbooksByStudentId(
    $studentId: Int!
    $options: TextbookSearchOptions!
  ) {
    getStudentTextbooksByStudentId(studentId: $studentId, options: $options) {
      id
      textbookName
      importantFlag
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      textbookTags {
        id
        tagName
        color
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
          subjects {
            id
            subjectName
          }
        }
      }
      # 進捗
      targetLap
      progressUnitName
      totalStudyMinutes

      totalTextbookStudyRanges {
        startValue
        endValue
      }

      textbookStudyComposition {
        totalTextbookStudy {
          id
          studentTextbookId
          totalTextbookStudyPeriods {
            startDate
            endDate
          }
          totalTextbookProgresses {
            progressUnit {
              value
            }
            progressLap
            progressOwner
          }
        }
        weeklyTextbookStudies {
          id
          studentTextbookId
          startDate
          endDate
          weeklyTextbookStudyPlansUnitRanges {
            startValue
            endValue
          }
        }
        guidanceKomaTextbookStudies {
          id
          studyMinutes
        }
      }
      schoolLessonProgresses {
        progressUnit {
          value
        }
        isFinishedSchoolLesson
        progressOwner
      }
    }
  }
`;

/**
 * 教科書単体取得
 */
export const getStudentTextbook = /* GraphQL */ `
  query getStudentTextbook($textbookId: Int!) {
    getStudentTextbook(textbookId: $textbookId) {
      id
      textbookName
      importantFlag
      progressUnitName
      targetLap
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
        }
      }
      textbookTags {
        id
        tagName
        color
      }
      totalTextbookStudyRanges {
        startValue
        endValue
      }

      totalStudyMinutes

      textbookStudyComposition {
        totalTextbookStudy {
          id
          studentTextbookId
          totalTextbookStudyPeriods {
            startDate
            endDate
          }
          totalTextbookProgresses {
            progressUnit {
              value
            }
            progressLap
            progressOwner
          }
        }

        weeklyTextbookStudies {
          id
          startDate
          endDate
          weeklyTextbookStudyProgressesUnitRanges {
            startValue
            endValue
          }
          weeklyTextbookStudyPlansUnitRanges {
            startValue
            endValue
          }
        }

        guidanceKomaTextbookStudies {
          id
          studentTextbook {
            id
          }
          guidanceReport {
            id
            guidanceKoma {
              id
              komaTemplate {
                komaName
              }
              komaDate
            }
            completedDate
          }
          studyMinutes
          guidanceProgresses {
            progressUnit {
              value
            }
            progressLap
            progressOwner
          }
        }
      }

      schoolLessonProgresses {
        progressUnit {
          value
        }
        isFinishedSchoolLesson
        progressOwner
      }
    }
  }
`;

/**
 * 他の生徒の教材取得
 */
export const getStudentTextbooksOfOtherStudents = /* GraphQL */ `
  query getStudentTextbooksOfOtherStudents(
    $studentId: Int!
    $options: TextbookSearchOptions!
  ) {
    getStudentTextbooksOfOtherStudents(studentId: $studentId, options: $options) {
      id
      textbookName
      importantFlag
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      textbookTags {
        id
        tagName
        color
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
          subjects {
            id
            subjectName
          }
        }
      }
      # 進捗
      targetLap
      progressUnitName

      totalTextbookStudyRanges {
        startValue
        endValue
      }

      totalStudyMinutes

      textbookStudyComposition {
        totalTextbookStudy {
          id
          studentTextbookId
          totalTextbookStudyPeriods {
            startDate
            endDate
          }
          totalTextbookProgresses {
            progressUnit {
              value
            }
            progressLap
            progressOwner
          }
        }
        guidanceKomaTextbookStudies {
          id
          studyMinutes
        }
      }
      schoolLessonProgresses {
        progressUnit {
          value
        }
        isFinishedSchoolLesson
        progressOwner
      }
    }
  }
`;

/**
 * 学習計画一覧 週毎の学習計画を取得
 */
export const getWeeklyStudyPlans = /* GraphQL */ `
  query getWeeklyStudyPlans(
    $studentGradeId: Int!
    $semesterId: Int!
    $fiscalYear: Int!
    $studentId: Int!
  ) {
    getWeeklyStudyPlans(
      studentGradeId: $studentGradeId
      semesterId: $semesterId
      fiscalYear: $fiscalYear
      studentId: $studentId
      # TODO: ページネーション対応 (初回リリース時は必須ではない)
      first: 100
      after: null
    ) {
      weeklyStudyPlans {
        id
        startDate
        endDate
        guidanceDates {
          exams {
            __typename
          }
          date
          guidanceKomas {
            id
            attendanceStatus
            subjects {
              id
              subjectName
            }
            komaTemplate {
              komaName
            }
          }
        }
        leftWeekCountsForExam
        weeklyStudyPlanMemo {
          memo
          lastUpdatedBy {
            name
          }
          updatedDate
        }
        groupedWeeklyStudyPlanTextbooksBySubjectsAreas {
          subjectArea {
            id
            subjectAreaName
          }
          groupedWeeklyStudyPlanTextbooks {
            studyPlanSubjectInfo {
              id
              displayedSubjectName
              examSubject {
                subject {
                  id
                }
              }
            }
            weeklyStudyPlanTextbooks {
              studentTextbook {
                id
                progressUnitName
                textbookId
                textbookName
                importantFlag
                textbookTags {
                  id
                  tagName
                  color
                }
              }
              studyPlanRanges {
                startValue
                endValue
              }
              lastStudiedProgressUnitValue
              completedCurrentWeekStudyRate
              completedStudyRateUntilLastWeek
              isExpired
            }
          }
        }
      }
    }
  }
`;

export const getOptionsForGuidanceKomaTextbookStudy = /* GraphQL */ `
  query getOptionsForGuidanceKomaTextbookStudy(
    $studentId: Int!
    $currentManagementUserId: Int!
    $date: String!
  ) {
    getOptionsForGuidanceKomaTextbookStudy(
      studentId: $studentId
      currentManagementUserId: $currentManagementUserId
      date: $date
    ) {
      guidanceKomas {
        id
        komaTemplate {
          id
          komaName
          komaOrder
        }
        guidanceReport {
          id
          guidanceKomaTextbookStudies {
            id
            studyMinutes
            studentTextbook {
              id
            }
            guidanceProgresses {
              progressUnit {
                value
              }
              progressLap
              progressOwner
            }
          }
          completedDate
        }
        komaDate
      }
    }
  }
`;

export const getStudyPlanSubjectInfos = /* GraphQL */ `
  query getStudyPlanSubjectInfos(
    $studentGradeId: Int!
    $semesterId: Int!
    $fiscalYear: Int!
    $studentId: Int!
  ) {
    getStudyPlanSubjectInfos(
      studentGradeId: $studentGradeId
      semesterId: $semesterId
      fiscalYear: $fiscalYear
      studentId: $studentId
    ) {
      id
      examSubject {
        id
        subject {
          id
          subjectName
          subjectArea {
            id
            subjectAreaName
          }
        }
      }
      displayedSubjectName
      studentTextbooks {
        textbookName
        importantFlag
        progressUnitName
        targetLap
        textbookId
        id
        studentGrade {
          id
          grade
        }
        semester {
          id
          semesterName
        }
        subject {
          id
          categoryTypes {
            textbook {
              id
            }
          }
          subjectName
          subjectArea {
            subjectAreaName
          }
        }
        fiscalYear
        textbookTags {
          id
          tagName
          color
        }
        totalStudyMinutes
        totalTextbookStudyRanges {
          startValue
          endValue
        }
        schoolLessonProgresses {
          isFinishedSchoolLesson
          progressOwner
          progressUnit {
            value
          }
        }
        textbookStudyComposition {
          totalTextbookStudy {
            id
            totalTextbookProgresses {
              progressUnit {
                value
              }
              progressLap
              progressOwner
            }
            totalTextbookStudyPeriods {
              startDate
              endDate
            }
          }
          weeklyTextbookStudies {
            id
            studentTextbookId
            startDate
            endDate
            weeklyTextbookStudyProgressesUnitRanges {
              startValue
              endValue
            }
            weeklyTextbookStudyPlansUnitRanges {
              startValue
              endValue
            }
          }
          guidanceKomaTextbookStudies {
            id
            studyMinutes
            guidanceProgresses {
              progressUnit {
                value
              }
              progressLap
              progressOwner
            }
          }
        }
      }
    }
  }
`;
