import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  getGuidanceDatesByStudentId,
  getGuidanceDatesForBulkUpdateGuidanceIndividualTasks
} from '~/src/infrastructure/apis/graphql/query/queries';
import { bulkRegisterGuidanceKomas } from '~/src/infrastructure/apis/graphql/query/mutations';
import { Query, Mutation } from '~/src/domain/entities/schema';
import { GuidanceKomasBulkCreateInput } from '~/src/domain/models/reco/guidance/koma/register/bulkRegister';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidanceDateRepo = {
  /**
   * 日付ごとのコマを取得(月でペ０ジング)
   */
  fetchByStudentId: async (studentId: number, startDate: string, endDate: string) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, startDate, endDate },
      query: gql(getGuidanceDatesByStudentId)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getGuidanceDatesByStudentId',
      'variables: ',
      { studentId, startDate, endDate },
      'data: ',
      data.getGuidanceDatesByStudentId
    );
    return [...data.getGuidanceDatesByStudentId];
  },

  /**
   *
   * 指導コマ移動の選択肢のための候補を取得
   *
   */
  fetchByStudentIdForMoveGuidanceIndividualTasks: async (
    studentId: number,
    startDate: string,
    endDate: string
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, startDate, endDate },
      query: gql(getGuidanceDatesForBulkUpdateGuidanceIndividualTasks)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getGuidanceDatesByStudentId',
      'variables: ',
      { studentId, startDate, endDate },
      'data: ',
      data.getGuidanceDatesByStudentId
    );
    return [...data.getGuidanceDatesByStudentId];
  },

  /**
   * 一括登録
   */
  bulkRegister: async (input: GuidanceKomasBulkCreateInput) => {
    logger?.debug('input', input);
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(bulkRegisterGuidanceKomas)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    // logger?.debug('registerGuidanceDates', data.bulkRegisterGuidanceKomas)
    // return data.bulkRegisterGuidanceKomas
  }
} as const;
