import { computed, ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { TeacherTaskCheck } from '~/src/domain/entities/schema';

/**
 * 生徒詳細画面の講師タスクが未完了のものがあれば、studentNabBtnとフッターに赤ポチを表示するためstoreを定義
 */
export const useTeacherTaskChecksStore = defineStore('teacherTaskChecks', () => {
  const teacherTaskChecks = ref<TeacherTaskCheck[]>([]);

  const get = (): Readonly<TeacherTaskCheck[]> => teacherTaskChecks.value;
  const set = (newTeacherTaskChecks: TeacherTaskCheck[]): void => {
    teacherTaskChecks.value = newTeacherTaskChecks;
  };
  const inCompletedTeacherTaskChecks = computed<TeacherTaskCheck[]>(() =>
    teacherTaskChecks.value.filter(task => !task.completedDate)
  );
  return { get, set, inCompletedTeacherTaskChecks };
});
