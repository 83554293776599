import { gql } from '@apollo/client/core';
import { logger } from '~/src/framework/plugins/di/logger.di';
import {
  Query,
  GuidanceReportCompleteInput,
  Mutation
} from '~/src/domain/entities/schema';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { completeGuidanceReport } from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  getGuidanceReportsByDate,
  getGuidanceReportHistoriesByStudentId
} from '~/src/infrastructure/apis/graphql/query/queries';
import { deepCopy } from '~/src/shared/objectOperationUtil';

export const guidanceReportRepo = {
  // 日付ごとの指導報告を取得
  fetchListByDate: async (studentId: number, date: string) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, date },
      query: gql(getGuidanceReportsByDate)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getGuidanceReportsByDate', data.getGuidanceReportsByDate);
    return deepCopy(data.getGuidanceReportsByDate);
  },

  // タイムライン取得
  fetchHistoriesByStudentId: async (
    studentId: number,
    first: number,
    after?: number | null
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, first, after },
      query: gql(getGuidanceReportHistoriesByStudentId)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getGuidanceReportHistoriesByStudentId',
      data.getGuidanceReportHistoriesByStudentId
    );
    return { ...data.getGuidanceReportHistoriesByStudentId };
  },

  // 指導報告完了
  complete: async (input: GuidanceReportCompleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(completeGuidanceReport)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('completeGuidanceReport', data.completeGuidanceReport);
    return { ...data.completeGuidanceReport };
  }
} as const;
