import { inject, useContext } from '@nuxtjs/composition-api';
import { ErrorDialogKey } from '~/src/shared/composables/errorDialog';

export const useError = () => {
  const errorDialogCtx = inject(ErrorDialogKey);
  const { $logger } = useContext();

  const capture = (e: unknown, options?: { noOpenDialog?: boolean }) => {
    $logger.error(e);

    if (options?.noOpenDialog !== true) {
      if (errorDialogCtx) {
        errorDialogCtx.openErrorDialog(errorDialogCtx.getErrorMessage(e));
      } else {
        $logger.warn(
          `[useError warn]: errorDialogCtx is undefined` + `at ${window.location.href}.`
        );
      }
    }

    $logger.captureException(e);
  };

  return { capture };
};
