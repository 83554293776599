import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  completeTeacherTaskCheck,
  incompleteTeacherTaskCheck
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  getTeacherTaskCheck,
  getTeacherTaskChecksByStudentId
} from '~/src/infrastructure/apis/graphql/query/queries';
import { Mutation, Query } from '~/src/domain/entities/schema';
import { TeacherTaskCheckRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/task/teacherTaskCheck';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const teacherTaskCheckRepo: TeacherTaskCheckRepositoryInterface = {
  fetch: async id => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { id },
      query: gql(getTeacherTaskCheck)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getTeacherTaskCheck', data.getTeacherTaskCheck);
    return ok({ ...data.getTeacherTaskCheck });
  },

  fetchByStudentId: async studentId => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId },
      query: gql(getTeacherTaskChecksByStudentId)
    });
    if (errors?.length) return err(errors);
    logger?.debug(
      'getTeacherTaskChecksByStudentId data',
      data.getTeacherTaskChecksByStudentId
    );
    return ok([...data.getTeacherTaskChecksByStudentId]);
  },

  complete: async completeTeacherTaskCheckInput => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: completeTeacherTaskCheckInput },
      mutation: gql(completeTeacherTaskCheck)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('completeTeacherTaskCheck', data.completeTeacherTaskCheck);
    return ok({ ...data.completeTeacherTaskCheck });
  },

  incomplete: async incompleteTeacherTaskCheckInput => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: incompleteTeacherTaskCheckInput },
      mutation: gql(incompleteTeacherTaskCheck)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('incompleteTeacherTaskCheck', data.incompleteTeacherTaskCheck);
    return ok({ ...data.incompleteTeacherTaskCheck });
  }
} as const;
