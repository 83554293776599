import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

// middlewareで使う
export const useOfflineErrorDialogStore = defineStore('offlineErrorDialog', () => {
  const isOpened = ref(false);

  const get = (): Readonly<boolean> => isOpened.value;

  const open = () => (isOpened.value = true);
  const close = () => (isOpened.value = false);

  return { get, open, close };
});
