import { render, staticRenderFns } from "./ClassroomChangeDialog.vue?vue&type=template&id=645bec24&scoped=true"
import script from "./ClassroomChangeDialog.vue?vue&type=script&lang=ts"
export * from "./ClassroomChangeDialog.vue?vue&type=script&lang=ts"
import style0 from "./ClassroomChangeDialog.vue?vue&type=style&index=0&id=645bec24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17._ocrbj5776pypvsmgnsodguqvgi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645bec24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextMarkedLeft: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/TextMarkedLeft.vue').default,TextBtn: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/buttons/TextBtn.vue').default,PlainDialog: require('/builds/mates-pay/app/new_reco/reco_for_teacher/src/shared/components/dialogs/PlainDialog.vue').default})
