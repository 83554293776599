import { InjectionKey, reactive } from '@nuxtjs/composition-api';

export interface SnackbarItem {
  isOpened: boolean;
  title: string;
  link?: SnackBarLink | null;
}
export interface SnackBarLink {
  text: string;
  path: string;
  query?: { [key: string]: string | string[] | null | undefined };
}
export type Snackbar = ReturnType<typeof useSnackbar>;

/**
 * InjectionKey
 */
export const SnackbarKey: InjectionKey<Snackbar> = Symbol('Snackbar');

/**
 * useCase
 */
export const useSnackbar = () => {
  const snackbarItem: SnackbarItem = reactive(createDefaultSnackbarItem());

  const openSnackbar = (item: Omit<SnackbarItem, 'isOpened'>) => {
    Object.assign(snackbarItem, { title: item.title });
    item.link && Object.assign(snackbarItem, { link: item.link });
    snackbarItem.isOpened = true;
  };

  const closeSnackbar = () => Object.assign(snackbarItem, createDefaultSnackbarItem());

  return { snackbarItem, openSnackbar, closeSnackbar };
};

const createDefaultSnackbarItem = () => ({
  isOpened: false,
  title: '',
  link: null
});
