import { LocalStorageInterface } from '~/src/domain/interfaces/browser/localStorage';

// use window.localStorage
export const localStorageWrapper: LocalStorageInterface = {
  getItem: <T extends string>(key: string) => {
    return window.localStorage.getItem(key) as T | null;
  },

  setItem: (key, value) => {
    window.localStorage.setItem(key, value);
  },

  removeItem: key => {
    window.localStorage.removeItem(key);
  }
};
