import { Student, Teacher, TeacherSharedComment } from '~/src/domain/entities/schema';

export const STUDENTS_PLACE_HOLDER = [
  {
    __typename: '',
    id: -1,
    username: '',
    name: '',
    furigana: 'あ',
    gender: '',
    latestLoginInfo: {
      __typename: '',
      currentStatus: '',
      loggedInAt: '',
      loggedOutAt: ''
    },
    studentGrade: {
      __typename: '',
      id: 1,
      grade: '小1',
      gradeOrder: 1
    },
    school: {
      __typename: '',
      id: -1,
      schoolName: '',
      furigana: ''
    }
  },
  {
    __typename: '',
    id: -2,
    username: '',
    name: '',
    furigana: 'か',
    gender: '',
    latestLoginInfo: {
      __typename: '',
      currentStatus: '',
      loggedInAt: '',
      loggedOutAt: ''
    },
    studentGrade: {
      __typename: '',
      id: -2,
      grade: '小2',
      gradeOrder: 2
    },
    school: {
      __typename: '',
      id: -2,
      schoolName: '',
      furigana: ''
    }
  },
  {
    __typename: '',
    id: -3,
    username: '',
    name: '',
    furigana: 'さ',
    gender: '',
    latestLoginInfo: {
      __typename: '',
      currentStatus: '',
      loggedInAt: '',
      loggedOutAt: ''
    },
    studentGrade: {
      __typename: '',
      id: -3,
      grade: '小3',
      gradeOrder: 3
    },
    school: {
      __typename: '',
      id: -3,
      schoolName: '',
      furigana: ''
    }
  },
  {
    __typename: '',
    id: -4,
    username: '',
    name: '',
    furigana: 'た',
    gender: '',
    latestLoginInfo: {
      __typename: '',
      currentStatus: '',
      loggedInAt: '',
      loggedOutAt: ''
    },
    studentGrade: {
      __typename: '',
      id: -4,
      grade: '小4',
      gradeOrder: 4
    },
    school: {
      __typename: '',
      id: -4,
      schoolName: '',
      furigana: ''
    }
  }
] as unknown as Student[];

export const TEACHERS_PLACE_HOLDER = [
  {
    __typename: 'Teacher',
    id: 1,
    userId: 1,
    jukuId: 1,
    jukuUserId: 1,
    managementUserId: 1,
    name: '----',
    furigana: '----',
    gender: '',
    position: 'classroomMaster',
    mainClassroom: {
      __typename: 'Classroom',
      id: 1,
      classroomName: ''
    },
    latestLoginInfo: {
      __typename: 'LatestLoginInfo',
      currentStatus: 'leave',
      loggedInAt: '',
      loggedOutAt: ''
    },
    teacherTags: []
  },
  {
    __typename: 'Teacher',
    id: 2,
    userId: 2,
    jukuId: 2,
    jukuUserId: 2,
    managementUserId: 2,
    name: '----',
    furigana: '----',
    gender: '',
    position: 'teacher',
    mainClassroom: {
      __typename: 'Classroom',
      id: 2,
      classroomName: ''
    },
    latestLoginInfo: {
      __typename: 'LatestLoginInfo',
      currentStatus: 'leave',
      loggedInAt: '',
      loggedOutAt: ''
    },
    teacherTags: []
  }
] as unknown as Teacher[];

export const TEACHER_SHARED_COMMENTS_PLACE_HOLDER = [
  {
    id: 1,
    studentId: 1,
    comment: '----',
    createdDate: '',
    managementUser: { __typename: 'Manager', id: 1, username: '----' },
    pinDate: null,
    teacherSharedCommentReactions: [],
    teacherSharedCommentReads: []
  },
  {
    id: 2,
    studentId: 2,
    comment: '----',
    createdDate: '',
    managementUser: { __typename: 'Manager', id: 2, username: '----' },
    pinDate: null,
    teacherSharedCommentReactions: [],
    teacherSharedCommentReads: []
  },
  {
    id: 3,
    studentId: 3,
    comment: '----',
    createdDate: '',
    managementUser: { __typename: 'Manager', id: 3, username: '----' },
    pinDate: null,
    teacherSharedCommentReactions: [],
    teacherSharedCommentReads: []
  },
  {
    id: 4,
    studentId: 4,
    comment: '----',
    createdDate: '',
    managementUser: { __typename: 'Manager', id: 4, username: '----' },
    pinDate: null,
    teacherSharedCommentReactions: [],
    teacherSharedCommentReads: []
  },
  {
    id: 5,
    studentId: 5,
    comment: '----',
    createdDate: '',
    managementUser: { __typename: 'Manager', id: 5, username: '----' },
    pinDate: null,
    teacherSharedCommentReactions: [],
    teacherSharedCommentReads: []
  }
] as unknown as TeacherSharedComment[];
