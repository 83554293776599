import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { updateDefaultSchoolExamSubject } from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  DefaultSchoolExamSubjectUpdateInput,
  Mutation
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { DefaultSchoolExamSubjectRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/exam/school/defaultSchoolExamSubject';

export const defaultSchoolExamSubjectRepo: DefaultSchoolExamSubjectRepositoryInterface = {
  // 編集
  update: async (input: DefaultSchoolExamSubjectUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateDefaultSchoolExamSubject)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('updateDefaultSchoolExamSubject input', input);
    logger?.debug(
      'updateDefaultSchoolExamSubject response',
      data.updateDefaultSchoolExamSubject
    );
    return ok({ ...data.updateDefaultSchoolExamSubject });
  }
} as const;
