import { err, ok } from 'neverthrow';
import axios from 'axios';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { getManagementUserByUsername } from '~/src/infrastructure/apis/graphql/query/queries';
import { Manager, Teacher } from '~/src/domain/entities/schema';
import { ManagementUserRepositoryInterface } from '~/src/domain/interfaces/repositories/user/managementUser';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { HandleApiErrorType, intoApiError } from '~/src/shared/errors/api';
import { auth } from '~/src/framework/plugins/di/auth.di';
export type ManagementUserUnion = Teacher | Manager;
interface GetManagementUserByUsername {
  getManagementUserByUsername: ManagementUserUnion;
}

export const managementUserRepo: ManagementUserRepositoryInterface = {
  fetchByUsername: async username => {
    const errOpt = {
      repositoryName: 'managementUserRepo',
      repositoryNameMethodName: 'fetchByUsername',
      clientLocation: window.location.href,
      graphOLOperationName: 'getManagementUserByUsername'
    };
    try {
      const { data, errors } = await apolloClient.query<GetManagementUserByUsername>({
        variables: { username },
        query: gql(getManagementUserByUsername)
      });
      if (errors?.[0]) {
        return err(
          intoApiError(errors[0], {
            ...errOpt,
            rowApiErrorType: HandleApiErrorType.Respond
          })
        );
      }
      logger?.debug('getManagementUserByUsername', data.getManagementUserByUsername);
      return ok({ ...data.getManagementUserByUsername });
    } catch (e) {
      return err(
        intoApiError(e, {
          ...errOpt,
          rowApiErrorType: HandleApiErrorType.Thrown
        })
      );
    }
  },

  classroomLogin: async () => {
    try {
      // amplifyからのトークン取得
      const token = await auth?.getJwtToken();
      // エンドポイント叩く
      const res = await axios({
        method: 'GET',
        // FIXME: 一時的にprocessから環境変数を参照. repositoryをfactoryにして, DIするときに$configから環境変数を受け取りたい
        // eslint-disable-next-line no-access-process
        url: process.env.CLASSROOM_LOGIN_ENDPOINT,
        headers: { authorization: `Bearer ${token}` },
        withCredentials: true
      });
      logger?.debug('classroomLogin', res);
      return ok(undefined);
    } catch (e) {
      return err(
        intoApiError(e, {
          repositoryName: 'managementUserRepo',
          repositoryNameMethodName: 'classroomLogin',
          clientLocation: window.location.href,
          // FIXME: 一時的にprocessから環境変数を参照. repositoryをfactoryにして, DIするときに$configから環境変数を受け取りたい
          // eslint-disable-next-line no-access-process
          endpoint: process.env.CLASSROOM_LOGIN_ENDPOINT,
          rowApiErrorType: HandleApiErrorType.Thrown
        })
      );
    }
  },

  classroomLogout: async () => {
    try {
      // エンドポイント叩く
      const res = await axios({
        method: 'GET',
        // FIXME: 一時的にprocessから環境変数を参照. repositoryをfactoryにして, DIするときに$configから環境変数を受け取りたい
        // eslint-disable-next-line no-access-process
        url: process.env.CLASSROOM_LOGOUT_ENDPOINT,
        withCredentials: true
      });
      logger?.debug('classroomLogout', res);
      return ok(undefined);
    } catch (e) {
      return err(
        intoApiError(e, {
          repositoryName: 'managementUserRepo',
          repositoryNameMethodName: 'classroomLogout',
          clientLocation: window.location.href,
          // FIXME: 一時的にprocessから環境変数を参照. repositoryをfactoryにして, DIするときに$configから環境変数を受け取りたい
          // eslint-disable-next-line no-access-process
          endpoint: process.env.CLASSROOM_LOGOUT_ENDPOINT,
          rowApiErrorType: HandleApiErrorType.Thrown
        })
      );
    }
  }
} as const;
