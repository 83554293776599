export const confirm = {
  signOut: { $t: () => `ログアウトします。` },
  changeClassroom: {
    $t: ({ target }: { target: string }) => `${target}に切り替えます。`
  },
  guidanceKomaRegister: {
    $t: ({ target }: { target: string }) => `${target}は選択中の教室ではありません。`
  },
  report: {
    $t: () =>
      `指導内容を送信します。\n指導内容は保護者Webに反映され、\n編集することができなくなります。`
  },
  finish: { $t: ({ target }: { target: string }) => `${target}を終了します。` },
  change: { $t: ({ target }: { target: string }) => `${target}を変更します。` },
  delete: { $t: () => `削除します。` },
  cancel: { $t: () => `キャンセルします。` },
  resetRegistration: { $t: () => `コマを未登録状態に戻します。` },
  back: { $t: () => `前の画面に戻ります。` }
};
