import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  createStudyPlan,
  registerStudyPlanStudentTextbook,
  updateStudyPlan
} from '~/src/infrastructure/apis/graphql/query/mutations';
import { getStudyPlan } from '~/src/infrastructure/apis/graphql/query/queries';
import {
  Maybe,
  Mutation,
  Query,
  StudyPlan,
  StudyPlanCreateInput,
  StudyPlanStudentTextbookRegisterInput,
  StudyPlanUpdateInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { studyPlanRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/studyPlan/studyPlan';

export const studyPlanRepo: studyPlanRepositoryInterface = {
  // 総合の学習計画を取得
  fetchByStudent: async (
    studentGradeId: number,
    semesterId: number,
    fiscalYear: number,
    studentId: number
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentGradeId, semesterId, fiscalYear, studentId },
      query: gql(getStudyPlan)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getStudyPlan', data.getStudyPlan);
    // アサーション 注釈
    return ok({ ...data.getStudyPlan } as Maybe<StudyPlan>);
  },
  // 学習計画作成
  create: async (input: StudyPlanCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createStudyPlan)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('createStudyPlan', data.createStudyPlan);
    return ok(data.createStudyPlan);
  },

  // 学習計画編集
  update: async (input: StudyPlanUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateStudyPlan)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('updateStudyPlan', data.updateStudyPlan);
    return ok(data.updateStudyPlan);
  },

  registerStudentTextbook: async (input: StudyPlanStudentTextbookRegisterInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(registerStudyPlanStudentTextbook)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug(
      'registerStudyPlanStudentTextbook',
      data.registerStudyPlanStudentTextbook
    );
    return ok(data.registerStudyPlanStudentTextbook);
  }
} as const;
