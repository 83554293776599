import { useContext } from '@nuxtjs/composition-api';
import { Result, err, ok } from 'neverthrow';

import {
  createDefaultClassroom,
  useClassroomStore
} from '~/src/framework/store/classroom/classroom';
import { useClassroomOptionsStore } from '~/src/framework/store/classroom/classroomOptions';
import { createDefaultUser, useUserStore } from '~/src/framework/store/user';
import { ApiErrorUnion } from '~/src/shared/errors/api';
import { LocalStorageKey } from '~/src/domain/interfaces/browser/localStorage';

export const useAuth = () => {
  const userStore = useUserStore();
  const classroomStore = useClassroomStore();
  const classroomOptionsStore = useClassroomOptionsStore();
  const { $auth, $localStorage, $managementUserRepo } = useContext();

  const signIn = async (userInfo: {
    id: string;
    password: string;
  }): Promise<Result<undefined, ApiErrorUnion | Error>> =>
    // signIn
    (await $auth.signIn(userInfo.id, userInfo.password)).match(
      async (user): Promise<Result<undefined, ApiErrorUnion | Error>> =>
        // fetch user info
        (await $managementUserRepo.fetchByUsername(user.username)).match(
          (managementUser): Result<undefined, ApiErrorUnion> => {
            // set to store
            switch (managementUser.__typename) {
              case 'Teacher': {
                classroomOptionsStore.set([
                  managementUser.mainClassroom,
                  ...managementUser.subClassrooms
                ]);
                return ok(undefined);
              }
              case 'Manager': {
                if (!managementUser.juku.classrooms) return ok(undefined);
                classroomOptionsStore.set([...managementUser.juku.classrooms]);
                return ok(undefined);
              }
              default: {
                return err(new Error('user role is mismatched'));
              }
            }
          },
          // error fetch user info
          e => err(e)
        ),
      // error when signIn
      e => Promise.resolve().then(() => err(e))
    );

  const signOut = async () => {
    await $auth.signOut();
    // store初期化処理
    userStore.set(createDefaultUser());
    classroomStore.set(createDefaultClassroom());
    classroomOptionsStore.set([]);
    // localStorageから教室Idを削除
    $localStorage.removeItem(LocalStorageKey.currentClassroomId);
    // localStorageから指導コマ一括編集のstateを削除
    $localStorage.removeItem(LocalStorageKey.bulkUpdateGuidanceKomasApplyEndDate);
    // localstorageから指導予定のフィルターoptionのstateを削除
    $localStorage.removeItem(LocalStorageKey.guidanceScheduleAttendanceFilterOption);
    // localstorageから指導テスト日のフィルターoptionのstateを削除
    $localStorage.removeItem(LocalStorageKey.guidanceJukuTestWithSummariesFilterOption);
  };

  return { signIn, signOut };
};
