import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  createFixedTeacherShiftKoma,
  updateFixedTeacherShiftKoma,
  deleteFixedTeacherShiftKoma,
  applyFixedTeacherShiftKomas
} from '~/src/infrastructure/apis/graphql/query/mutations';

import { getFixedTeacherShiftKomas } from '~/src/infrastructure/apis/graphql/query/queries';
import { deepCopy } from '~/src/shared/objectOperationUtil';
import {
  FixedTeacherShiftKomaCreateInput,
  FixedTeacherShiftKomaDeleteInput,
  FixedTeacherShiftKomasApplyInput,
  FixedTeacherShiftKomaUpdateInput,
  Mutation,
  Query
  // Query
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const fixedTeacherShiftKomaRepo = {
  fetchByManagementUserId: async (managementUserId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { managementUserId },
      query: gql(getFixedTeacherShiftKomas)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getFixedTeacherShiftKomas data', data.getFixedTeacherShiftKomas);
    return deepCopy(data.getFixedTeacherShiftKomas);
  },

  create: async (input: FixedTeacherShiftKomaCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createFixedTeacherShiftKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createFixedTeacherShiftKoma input', input);
    logger?.debug('createFixedTeacherShiftKoma data', data.createFixedTeacherShiftKoma);
    return { ...data.createFixedTeacherShiftKoma };
  },

  update: async (input: FixedTeacherShiftKomaUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateFixedTeacherShiftKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateFixedTeacherShiftKoma input', input);
    logger?.debug('updateFixedTeacherShiftKoma data', data.updateFixedTeacherShiftKoma);
    return { ...data.updateFixedTeacherShiftKoma };
  },

  delete: async (input: FixedTeacherShiftKomaDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteFixedTeacherShiftKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteFixedTeacherShiftKoma input', input);
    logger?.debug('deleteFixedTeacherShiftKoma data', data.deleteFixedTeacherShiftKoma);
    return { ...data.deleteFixedTeacherShiftKoma };
  },

  apply: async (input: FixedTeacherShiftKomasApplyInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(applyFixedTeacherShiftKomas)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('applyFixedTeacherShiftKomas input', input);
    logger?.debug('applyFixedTeacherShiftKomas data', data.deleteFixedTeacherShiftKoma);
    return data.applyFixedTeacherShiftKomas;
  }
} as const;
