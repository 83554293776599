export const query = {
  create: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を作成しました。` },
    failed: { $t: ({ target }: { target: string }) => `${target}の作成に失敗しました。` }
  },
  update: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を編集しました。` },
    failed: { $t: ({ target }: { target: string }) => `${target}の編集に失敗しました。` }
  },
  delete: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を削除しました` },
    failed: { $t: ({ target }: { target: string }) => `${target}の削除に失敗しました。` }
  },
  register: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を登録しました` },
    failed: { $t: ({ target }: { target: string }) => `${target}の登録に失敗しました。` }
  },
  bulkCreate: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を一括作成しました` },
    failed: {
      $t: ({ target }: { target: string }) => `${target}の一括作成に失敗しました。`
    }
  },
  bulkUpdate: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を一括編集しました` },
    failed: {
      $t: ({ target }: { target: string }) => `${target}の一括編集に失敗しました。`
    }
  },
  bulkDelete: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を一括削除しました` },
    failed: {
      $t: ({ target }: { target: string }) => `${target}の一括削除に失敗しました。`
    }
  },
  bulkRegister: {
    succeed: { $t: ({ target }: { target: string }) => `${target}を一括登録しました` },
    failed: {
      $t: ({ target }: { target: string }) => `${target}の一括登録に失敗しました。`
    }
  },
  completeGuidanceReport: {
    succeed: {
      $t: ({ target }: { target: string }) => `${target}\x20の指導報告を送信しました。`
    },
    failed: {
      $t: ({ target }: { target: string }) =>
        `${target}\x20の指導報告の送信に失敗しました。`
    }
  },
  apply: {
    succeed: { $t: ({ target }: { target: string }) => `${target}に反映しました。` }
  },
  editTaskRelation: {
    succeed: { $t: () => ` 生徒課題の割当を更新しました。` }
  },
  completeTask: {
    failed: { $t: () => ` タスク完了の登録に失敗しました。` }
  },
  incompleteTask: {
    failed: { $t: () => ` タスク完了の登録解除に失敗しました。` }
  },
  pin: {
    failed: { $t: () => ` ピン留めに失敗しました。` }
  },
  unpin: {
    failed: { $t: () => ` ピン留めの解除に失敗しました。` }
  },
  komaTransfer: {
    succeed: {
      $t: ({ komaName, transferTo }: { komaName: string; transferTo: string }) =>
        ` '${komaName}を${transferTo}に振替登録しました。`
    }
  },
  getGuidanceJukuTestWithSummaries: {
    failed: {
      $t: () =>
        ` 指導テストの行数が多いため表示できません。更に詳細に進み一覧を表示してください。`
    }
  }
} as const;
