import { gql } from '@apollo/client/core';
// import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  legacyApolloClient,
  ERROR_NOT_FOUND as ERROR_NOT_FOUND_LEGACY
} from '~/src/infrastructure/apis/graphql/legacyClient';
// import { getOptionsForGuidanceKomaTextbookStudy } from '~/src/infrastructure/apis/graphql/query/queries';
import { getOptionsForGuidanceKomaTextbookStudy as getOptionsForGuidanceKomaTextbookStudyLegacy } from '~/src/infrastructure/apis/graphql/query/legacy/queries';
// import { Query } from '~/src/domain/entities/schema';
import { Query as QueryLegacy } from '~/src/domain/entities/schema.legacy';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const optionsForGuidanceKomaTextbookStudyRepo = {
  /**
   * ==============================
   *
   * TODO:
   * 教材速度向上の為に、スキーマを変更したが全て新しいスキーマに移行するまでの間、旧スキーマのメソッドを使うようにしておく。
   * 優先度の高い箇所から段階的に新スキーマに移行していく予定。
   * [教材スピード 親issue](https://gitlab.com/mates-pay/app/new_reco/reco_for_teacher/-/issues/2167)
   *
   * ==============================
   */
  // fetch: async (studentId: number, currentManagementUserId: number, date: string) => {
  //   const { data, errors } = await apolloClient.query<Query>({
  //     variables: { studentId, currentManagementUserId, date },
  //     query: gql(getOptionsForGuidanceKomaTextbookStudy)
  //   });
  //   if (errors?.length && errors[0]?.message) {
  //     const errorObject = {
  //       networkError: { statusCode: 200 },
  //       message: errors[0].message
  //     };
  //     throw errorObject;
  //   }
  //   if (!data) throw ERROR_NOT_FOUND;
  //   logger?.debug(
  //     'getOptionsForGuidanceKomaTextbookStudy, data',
  //     data.getOptionsForGuidanceKomaTextbookStudy
  //   );
  //   return { ...data.getOptionsForGuidanceKomaTextbookStudy };
  // },

  /*
   * legacyMethods
   */
  legacy: {
    fetch: async (studentId: number, currentManagementUserId: number, date: string) => {
      const { data, errors } = await legacyApolloClient.query<QueryLegacy>({
        variables: { studentId, currentManagementUserId, date },
        query: gql(getOptionsForGuidanceKomaTextbookStudyLegacy)
      });
      if (errors?.length && errors[0]?.message) {
        const errorObject = {
          networkError: { statusCode: 200 },
          message: errors[0].message
        };
        throw errorObject;
      }
      if (!data) throw ERROR_NOT_FOUND_LEGACY;
      logger?.debug(
        'getOptionsForGuidanceKomaTextbookStudy, data',
        data.getOptionsForGuidanceKomaTextbookStudy
      );
      return { ...data.getOptionsForGuidanceKomaTextbookStudy };
    }
  }
} as const;
