import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  createGuidanceJukuTest,
  deleteGuidanceJukuTest,
  updateGuidanceJukuTest
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  Mutation,
  GuidanceJukuTestCreateInput,
  GuidanceJukuTestUpdateInput,
  GuidanceJukuTestDeleteInput,
  Query
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';
import {
  getGuidanceJukuTestHistory,
  getGuidanceJukuTestWithSummaries
} from '~/src/infrastructure/apis/graphql/query/queries';
import { HandleApiErrorType, intoApiError } from '~/src/shared/errors/api';

export const guidanceJukuTestRepo = {
  // 過去の指導テストの履歴
  fetchHistoryByStudentId: async (
    guidanceJukuTestTemplateId: number,
    studentId: number
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { guidanceJukuTestTemplateId, studentId },
      query: gql(getGuidanceJukuTestHistory)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getGuidanceJukuTestHistory data', data.getGuidanceJukuTestHistory);
    return [...data.getGuidanceJukuTestHistory];
  },

  fetchWithSummaries: async (
    parentFolderId: number,
    studentId: number,
    startDate: string,
    endDate: string
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { parentFolderId, studentId, startDate, endDate },
      query: gql(getGuidanceJukuTestWithSummaries)
    });
    if (errors?.length && errors[0]?.message) {
      const errOpt = {
        repositoryName: 'guidanceJukuTestRepo',
        repositoryNameMethodName: 'fetchWithSummaries',
        clientLocation: window.location.href,
        graphOLOperationName: 'getGuidanceJukuTestWithSummaries'
      };
      return err(
        intoApiError(errors[0], {
          ...errOpt,
          rowApiErrorType: HandleApiErrorType.Respond
        })
      );
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getGuidanceJukuTestWithSummaries data',
      data.getGuidanceJukuTestWithSummaries
    );
    return ok([...data.getGuidanceJukuTestWithSummaries]);
  },

  // 指導のテスト追加
  create: async (guidanceJukuTestCreateInput: GuidanceJukuTestCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { guidanceJukuTestCreateInput },
      mutation: gql(createGuidanceJukuTest)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createGuidanceJukuTest input', guidanceJukuTestCreateInput);
    logger?.debug('createGuidanceJukuTest response', data.createGuidanceJukuTest);
    return { ...data.createGuidanceJukuTest };
  },

  // 指導のテスト編集
  update: async (guidanceJukuTestUpdateInput: GuidanceJukuTestUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { guidanceJukuTestUpdateInput },
      mutation: gql(updateGuidanceJukuTest)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateGuidanceJukuTest input', guidanceJukuTestUpdateInput);
    logger?.debug('updateGuidanceJukuTest response', data.updateGuidanceJukuTest);
    return { ...data.updateGuidanceJukuTest };
  },

  // 指導のテスト削除

  delete: async (guidanceJukuTestDeleteInput: GuidanceJukuTestDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { guidanceJukuTestDeleteInput },
      mutation: gql(deleteGuidanceJukuTest)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteGuidanceJukuTest input', guidanceJukuTestDeleteInput);
    logger?.debug('deleteGuidanceJukuTest response', data.deleteGuidanceJukuTest);
    return data.deleteGuidanceJukuTest;
  }
} as const;
