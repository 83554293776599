var render, staticRenderFns
import script from "./nuxt-loading.vue?vue&type=script&lang=js"
export * from "./nuxt-loading.vue?vue&type=script&lang=js"
import style0 from "./nuxt-loading.vue?vue&type=style&index=0&id=b64bf7b2&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17._ocrbj5776pypvsmgnsodguqvgi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports