import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  createAdditionalSchoolExamSubject,
  deleteAdditionalSchoolExamSubject,
  updateAdditionalSchoolExamSubject
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  AdditionalSchoolExamSubjectCreateInput,
  AdditionalSchoolExamSubjectDeleteInput,
  AdditionalSchoolExamSubjectUpdateInput,
  Mutation
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const additionalSchoolExamSubjectRepo = {
  // 作成
  create: async (input: AdditionalSchoolExamSubjectCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createAdditionalSchoolExamSubject)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createAdditionalSchoolExamSubject input', input);
    logger?.debug(
      'createAdditionalSchoolExamSubject response',
      data.createAdditionalSchoolExamSubject
    );
    return { ...data.createAdditionalSchoolExamSubject };
  },

  // 編集
  update: async (input: AdditionalSchoolExamSubjectUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateAdditionalSchoolExamSubject)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateAdditionalSchoolExamSubject input', input);
    logger?.debug(
      'updateAdditionalSchoolExamSubject response',
      data.updateAdditionalSchoolExamSubject
    );
    return { ...data.updateAdditionalSchoolExamSubject };
  },

  // 削除
  delete: async (input: AdditionalSchoolExamSubjectDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteAdditionalSchoolExamSubject)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteAdditionalSchoolExamSubject input', input);
    logger?.debug(
      'deleteAdditionalSchoolExamSubject response',
      data.deleteAdditionalSchoolExamSubject
    );
    return { ...data.deleteAdditionalSchoolExamSubject };
  }
} as const;
