export const signIn = {
  signInDescription: {
    classroom: {
      $t: () => `教室にログインします\n管理者のIDとパスワードを入力して下さい`
    },
    // teacher: 'ログインします{newline}IDとパスワードを入力して下さい'
    teacher: {
      $t: () => `ログインします\nIDとパスワードを入力して下さい`
    }
  },
  signInClassroom: {
    succeed: { $t: () => ` 教室ログインに成功しました。` }
  },
  signOutClassroom: {
    succeed: { $t: () => ` 教室ログアウトに成功しました。` }
  },
  signInConfirm: {
    teacherName: { $t: ({ name }: { name: string }) => `${name}さんでログインします。` }
  },
  selectAppModeMessages: {
    classroom: {
      $t: ({ classroomName }: { classroomName: string }) =>
        `${classroomName}教室にログインしました。`
    },
    mode: {
      $t: ({ newline }: { newline: string }) =>
        `'指導管理アプリとして使いますか？${newline}入退室アプリとして使いますか？`
    }
  }
} as const;
