
interface Props {
  message: string;
  isLoading?: boolean;
}
// @ts-ignore
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: true,
    type: String
  },
  isLoading: {
    key: "isLoading",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
