import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
// import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { legacyApolloClient } from '~/src/infrastructure/apis/graphql/legacyClient';
// import { getWeeklyStudyPlans } from '~/src/infrastructure/apis/graphql/query/queries';
import { getWeeklyStudyPlans as getWeeklyStudyPlansLegacy } from '~/src/infrastructure/apis/graphql/query/legacy/queries';
import { deepCopy } from '~/src/shared/objectOperationUtil';
// import { Query } from '~/src/domain/entities/schema';
import { Query as QueryLegacy } from '~/src/domain/entities/schema.legacy';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { weeklyStudyPlanRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/studyPlan/weeklyStudyPlan';

// TODO: ページング対応
export const weeklyStudyPlanRepo: weeklyStudyPlanRepositoryInterface = {
  // // 週毎の学習計画を取得
  // fetchListByStudent: async (
  //   studentGradeId: number,
  //   semesterId: number,
  //   fiscalYear: number,
  //   studentId: number
  // ) => {
  //   const { data, errors } = await apolloClient.query<Query>({
  //     variables: { studentGradeId, semesterId, fiscalYear, studentId },
  //     query: gql(getWeeklyStudyPlans)
  //   });
  //   if (errors?.length) return err(errors);
  //   if (!data) return err(new DataNotExistError());
  //   logger?.debug(
  //     'getWeeklyStudyPlans.weeklyStudyPlans',
  //     data.getWeeklyStudyPlans.weeklyStudyPlans
  //   );
  //   // TODO: ページング
  //   return ok(deepCopy(data.getWeeklyStudyPlans.weeklyStudyPlans));
  // },
  /*
   * legacyMethods
   */
  legacy: {
    fetchListByStudent: async (
      studentGradeId: number,
      semesterId: number,
      fiscalYear: number,
      studentId: number
    ) => {
      const { data, errors } = await legacyApolloClient.query<QueryLegacy>({
        variables: { studentGradeId, semesterId, fiscalYear, studentId },
        query: gql(getWeeklyStudyPlansLegacy)
      });
      if (errors?.length) return err(errors);
      if (!data) return err(new DataNotExistError());
      logger?.debug(
        'getWeeklyStudyPlans.weeklyStudyPlans',
        data.getWeeklyStudyPlans.weeklyStudyPlans
      );
      // TODO: ページング
      return ok(deepCopy(data.getWeeklyStudyPlans.weeklyStudyPlans));
    }
  }
} as const;
