import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getLeftKomaInfo } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const leftKomaInfoRepo = {
  fetch: async (schoolExamId: number, studentId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { schoolExamId, studentId },
      query: gql(getLeftKomaInfo)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getLeftKomaInfo, data', data.getLeftKomaInfo);
    return { ...data.getLeftKomaInfo };
  }
} as const;
