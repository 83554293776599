import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getOptionsForStudyPlanForm } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const optionsForStudyPlanFormRepo = {
  // セレクトボックスに表示させる学校の定期試験や試験科目を取得
  fetchByStudent: async (
    studentGradeId: number,
    semesterId: number,
    fiscalYear: number,
    studentId: number
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentGradeId, semesterId, fiscalYear, studentId },
      query: gql(getOptionsForStudyPlanForm)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getOptionsForStudyPlanForm', data.getOptionsForStudyPlanForm);
    return { ...data.getOptionsForStudyPlanForm };
  }
} as const;
