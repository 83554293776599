import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    staticClass: "plain-dialog",
    attrs: {
      "persistent": "",
      "scrollable": "",
      "value": _vm.isOpened,
      "width": _vm.width,
      "fullscreen": _vm.fullscreen
    }
  }, [_c('BaseCard', {
    staticClass: "grey900--text overflow-auto",
    style: {
      position: 'relative',
      padding: ((_vm.BASE_CARD_PADDING_PX) + "px")
    }
  }, [_c('button', {
    staticStyle: {
      "display": "none"
    }
  }), _vm._v(" "), _c('div', {
    ref: "headerRef",
    staticClass: "d-flex justify-space-between align-center pa-2",
    style: {
      height: '3rem',
      padding: ((_vm.HEADER_PADDING_PX) + "px")
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm.isShowedPreviousBtn ? _c('PreviousBtn', {
    on: {
      "prev": function ($event) {
        return _vm.$emit('previous');
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('h1', {
    staticClass: "text-subtitle-1 font-weight-normal"
  }, [_vm._v(_vm._s(_vm.title))])], 1), _vm._v(" "), !_vm.noCloseButtonInHeader ? _c('span', {
    staticClass: "mdi mdi-close grey600--text text-h5",
    on: {
      "click": function ($event) {
        return _vm.$emit('click:close-icon');
      }
    }
  }) : _vm._e()]), _vm._v(" "), _c(VDivider, {
    staticClass: "mb-3"
  }), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 px-2 overflow-y-auto4 pb-4 limited-dialog-height",
    style: {
      'max-height': ((_vm.maxSlotHeight) + "px"),
      height: _vm.height
    }
  }, [_vm._t("default")], 2), _vm._v(" "), !_vm.noButtonActions ? _c('div', {
    ref: "buttonActionsRef",
    staticStyle: {
      "height": "2rem"
    }
  }, [_vm._t("buttonActions")], 2) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }