import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  createGuidanceCustomTest,
  deleteGuidanceCustomTest,
  updateGuidanceCustomTest
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  Mutation,
  GuidanceCustomTestCreateInput,
  GuidanceCustomTestUpdateInput,
  GuidanceCustomTestDeleteInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidanceCustomTestRepo = {
  // 指導のテスト追加
  create: async (guidanceCustomTestCreateInput: GuidanceCustomTestCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: guidanceCustomTestCreateInput },
      mutation: gql(createGuidanceCustomTest)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createGuidanceCustomTest input', guidanceCustomTestCreateInput);
    logger?.debug('createGuidanceCustomTest response', data.createGuidanceCustomTest);
    return { ...data.createGuidanceCustomTest };
  },

  // 指導のテスト編集
  update: async (guidanceCustomTestUpdateInput: GuidanceCustomTestUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: guidanceCustomTestUpdateInput },
      mutation: gql(updateGuidanceCustomTest)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateGuidanceCustomTest input', guidanceCustomTestUpdateInput);
    logger?.debug('updateGuidanceCustomTest response', data.updateGuidanceCustomTest);
    return { ...data.updateGuidanceCustomTest };
  },

  // 指導のテスト削除

  delete: async (guidanceCustomTestDeleteInput: GuidanceCustomTestDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: guidanceCustomTestDeleteInput },
      mutation: gql(deleteGuidanceCustomTest)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteGuidanceCustomTest input', guidanceCustomTestDeleteInput);
    logger?.debug('deleteGuidanceCustomTest response', data.deleteGuidanceCustomTest);
    return { ...data.deleteGuidanceCustomTest };
  }
} as const;
