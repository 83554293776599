import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { RouteName } from '~/src/framework/plugins/$path';

/**
 * routerの値を読むのが遅いので、ここはグローバルに管理する。
 * 画面遷移したとき,Activatedで更新または、遷移するイベントが発火した習慣に更新する。
 * (UX改善のため)
 */

export const useCurrentRouteStore = defineStore('currentRouteState', () => {
  const currentRoute = ref<RouteName>('');

  const get = (): Readonly<RouteName> => currentRoute.value;
  const set = (newCurrentRoute: RouteName): void => {
    currentRoute.value = newCurrentRoute;
  };

  return { get, set };
});
