export type AuthErrorUnion =
  | AuthUnexpectedResponseError
  | AuthIncorrectUsernameOrPassword
  | AuthUnexpectedError;

abstract class AuthError extends Error {}

export class AuthUnexpectedResponseError extends AuthError {
  constructor(message: string, response: unknown) {
    super(
      message +
        `\nstringified:\n\t${
          typeof response === 'object' ? JSON.stringify(response) : `${response}`
        }`
    );
    this.name = AuthUnexpectedResponseError.name;
  }
}

export class AuthIncorrectUsernameOrPassword extends AuthError {
  constructor(message: string) {
    super(message);
    this.name = AuthIncorrectUsernameOrPassword.name;
  }
}

export class AuthUnexpectedError extends AuthError {
  constructor(message: string, e: unknown) {
    super(
      message + `\nstringified:\n\t${typeof e === 'object' ? JSON.stringify(e) : `${e}`}`
    );
    this.name = AuthUnexpectedError.name;
  }
}
