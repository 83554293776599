import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VOverlay, {
    staticClass: "text-center",
    attrs: {
      "value": _vm.isLoading,
      "color": "#fafafa"
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "color": "primary",
      "width": "12"
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "mt-6 primary--text caption font-weight-bold text-center"
  }, [_vm._v("\n    " + _vm._s(_vm.message) + "\n  ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }