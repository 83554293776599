import { dayjs, DATE_FORMATS } from '~/src/shared/date';
import {
  ExamPoint,
  ExamPointInput,
  ExamRank,
  ExamRankInput,
  Maybe,
  RegularExamStatus,
  Subject
} from '~/src/domain/entities/schema';

// 成績非公開の選択肢
export interface NonPublicationOption {
  text: '未発表';
  value: false;
}
export const NON_PUBLICATION_OPTIONS: Readonly<[Readonly<NonPublicationOption>]> = [
  {
    text: '未発表',
    value: false
  }
];

interface ExamStatusOption {
  text: string;
  value: RegularExamStatus;
}

export const REGULAR_EXAM_STATUS: Readonly<ExamStatusOption[]> = [
  {
    text: '受験',
    value: 'Attendance'
  },
  {
    text: '欠席',
    value: 'Absence'
  },
  {
    text: '実施せず',
    value: 'Excluded'
  }
] as const;

export const getExamDateText = (date?: string | null): string =>
  date !== null ? dayjs(date).format('YYYY/M/D(ddd)') : '----';

export const getExamPeriodText = (
  examStartDate?: Maybe<string>,
  examEndDate?: Maybe<string>
) => {
  // 訴権期間が開始も終了も未入力の場合はテキストを表示する
  if (!examStartDate && !examEndDate) return '試験期間未入力';
  else {
    const startDate = examStartDate
      ? dayjs(examStartDate).format(DATE_FORMATS.fullDate)
      : '';
    const endDate = examEndDate ? dayjs(examEndDate).format(DATE_FORMATS.fullDate) : '';

    return `${startDate}\x20~\x20${endDate}`;
  }
};

export const getTotalScoreText = (
  totalScorePoint?: Maybe<number>,
  totalPerfectPoint?: Maybe<number>
) => {
  const score = totalScorePoint === null ? null : Math.round(totalScorePoint!);
  const perfectScore = totalPerfectPoint === null ? null : Math.round(totalPerfectPoint!);
  return `${score ?? '--'}/${perfectScore ?? '--'}点`;
};

export const getScoreValueText = (score: ExamPoint | ExamRank) => {
  if (score.value !== null) return Math.round(score.value!);
  if (score.publicationFlag) return 'ー';
  return '未発表';
};

export const getAvgPointValueText = (point: ExamPoint) => {
  if (point.value !== null) return Math.round(point.value! * 10) / 10;
  if (point.publicationFlag) return 'ー';
  return '未発表';
};

/**
 * 成績convert処理 formInput -> api
 */
export const convertToExamPointInput = (
  inputValue: number | NonPublicationOption | null | '',
  inputOnlyVerbalCheck: boolean
): ExamPointInput => {
  // 数字入力
  // prettier-ignore
  if (typeof inputValue === 'number')
    return { value: inputValue, publicationFlag: true, onlyVerbalCheck: inputOnlyVerbalCheck };

  // 「なし」選択(未公開)
  // prettier-ignore
  if (typeof inputValue === 'object' && inputValue?.value === NON_PUBLICATION_OPTIONS[0].value)
    return { value: null, publicationFlag: false, onlyVerbalCheck: inputOnlyVerbalCheck };

  // 未登録の場合
  return { value: null, publicationFlag: true, onlyVerbalCheck: inputOnlyVerbalCheck };
};
export const convertToExamRankInput = (
  inputValue: number | NonPublicationOption | null | '',
  inputOnlyVerbalCheck: boolean
): ExamRankInput => {
  // prettier-ignore
  // 数字入力
  if (typeof inputValue === 'number')
    return { value: inputValue, publicationFlag: true, onlyVerbalCheck: inputOnlyVerbalCheck };

  // prettier-ignore
  // 「なし」選択(未公開)
  if (typeof inputValue === 'object' && inputValue?.value === NON_PUBLICATION_OPTIONS[0].value)
    return { value: null, publicationFlag: false, onlyVerbalCheck: inputOnlyVerbalCheck };

  // 未登録の場合
  return { value: null, publicationFlag: true, onlyVerbalCheck: inputOnlyVerbalCheck };
};

/**
 * 成績convert処理 api -> formInput
 */
export const convertToExamPointFormInput = (
  examPoint: ExamPoint
): {
  value: number | NonPublicationOption | null;
  onlyVerbalCheck: boolean;
} => {
  // prettier-ignore
  if (!examPoint.publicationFlag)
    return { value: NON_PUBLICATION_OPTIONS[0], onlyVerbalCheck: examPoint.onlyVerbalCheck };

  if (examPoint.value !== null && examPoint.value !== undefined)
    return {
      value: Math.round(examPoint.value),
      onlyVerbalCheck: examPoint.onlyVerbalCheck
    };

  return {
    value: null,
    onlyVerbalCheck: examPoint.onlyVerbalCheck
  };
};
export const convertToExamRankFormInput = (
  examRank: ExamRank
): {
  value: number | NonPublicationOption | null;
  onlyVerbalCheck: boolean;
} => {
  if (!examRank.publicationFlag)
    return {
      value: NON_PUBLICATION_OPTIONS[0],
      onlyVerbalCheck: examRank.onlyVerbalCheck
    };
  // prettier-ignore
  if (examRank.value !== null && examRank.value !== undefined) return {
    value: examRank.value,
    onlyVerbalCheck: examRank.onlyVerbalCheck
  };
  return {
    value: null,
    onlyVerbalCheck: examRank.onlyVerbalCheck
  };
};

/**
 * フロントで扱うフォーム用の試験科目のinput
 */
export interface ExamSubjectFormInput {
  examDate: string;
  subject: Subject | null;
  displayedSubjectName: string;
  examStatus: RegularExamStatus;
  /**
   * バックスペースでinput消した場合は空のstring('')になる
   */
  scorePoint: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
  scorePerfectPoint: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
  scoreTargetPoint: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
  scoreAveragePoint: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
  evaluationPoint: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
  evaluationMaxPoint: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
  rank: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
  rankOverall: {
    value: number | NonPublicationOption | null | '';
    onlyVerbalCheck: boolean;
  };
}

export const createDefaultExamSubjectFormInput = (): ExamSubjectFormInput => ({
  examDate: '',
  subject: null,
  displayedSubjectName: '',
  scorePoint: {
    value: null,
    onlyVerbalCheck: false
  },
  scorePerfectPoint: {
    value: 100,
    onlyVerbalCheck: false
  },
  scoreTargetPoint: {
    value: null,
    onlyVerbalCheck: false
  },
  scoreAveragePoint: {
    value: null,
    onlyVerbalCheck: false
  },
  evaluationPoint: {
    value: null,
    onlyVerbalCheck: false
  },
  evaluationMaxPoint: {
    value: null,
    onlyVerbalCheck: false
  },
  rank: {
    value: null,
    onlyVerbalCheck: false
  },
  rankOverall: {
    value: null,
    onlyVerbalCheck: false
  },
  examStatus: 'Attendance'
});
