import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { accessRoom } from '~/src/infrastructure/apis/graphql/query/mutations';
import { Mutation, RoomAccessInput } from '~/src/domain/entities/schema';
import { AccessRoomRepositoryInterface } from '~/src/domain/interfaces/repositories/user/accessRoom';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const accessRoomRepo: AccessRoomRepositoryInterface = {
  accessRoom: async (input: RoomAccessInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(accessRoom)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('accessRoom response', data.accessRoom);
    return ok({ ...data.accessRoom });
  }
} as const;
