var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-status-label",
    style: _vm.style
  }, [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]);
}
var staticRenderFns = []

export { render, staticRenderFns }