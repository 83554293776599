import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

import { useTeacherSharedCommentsStore } from '~/src/framework/store/teacherSharedComments';

/**
 * FIXME: まだサブスクを実装してないのでmiddlewareで対応
 */
export default defineNuxtMiddleware(
  ({ route, pinia, $teacherSharedCommentRepo, $logger }) => {
    if (!route.params?.studentId) return;
    const teacherSharedCommentsStore = useTeacherSharedCommentsStore(pinia);

    (async () => {
      const studentId = route.params.studentId ? parseInt(route.params.studentId) : null;
      if (!studentId) return;

      const res = await $teacherSharedCommentRepo.fetchByStudentId(studentId);
      res.match(
        data => {
          teacherSharedCommentsStore.set(data);
        },
        e => {
          $logger.error(e);
          teacherSharedCommentsStore.set([]);
        }
      );
    })();
  }
);
