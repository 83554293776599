import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { bulkRegisterTeacherShifts } from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  getTeacherShiftByManagementUserIdAndDate,
  getTeacherShiftsByClassroomIdAndDateRange,
  getTeacherShiftsByManagementUserIdAndDateRange
} from '~/src/infrastructure/apis/graphql/query/queries';

import {
  Mutation,
  Query,
  TeacherShift,
  TeacherShiftsBulkRegisterInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const teacherShiftRepo = {
  /**
   * 複数講師(教室内)の取得シフト一覧を取得
   *
   * home/teacher-shiftsで使う
   */
  fetchByClassroomIdAndDateRange: async (
    classroomId: number,
    startDate: string,
    endDate: string
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { classroomId, startDate, endDate },
      query: gql(getTeacherShiftsByClassroomIdAndDateRange)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getTeacherShiftsByClassroomIdAndDateRange', 'variables: ', {
      classroomId,
      startDate,
      endDate
    });
    logger?.debug(
      'getTeacherShiftsByClassroomIdAndDateRange',
      'data: ',
      data.getTeacherShiftsByClassroomIdAndDateRange
    );
    return [...data.getTeacherShiftsByClassroomIdAndDateRange];
  },

  /**
   * 1講師のシフト一覧を取得
   *
   * 講師詳細ページで使う
   */
  fetchByManagementUserIdAndDateRange: async (
    managementUserId: number,
    startDate: string,
    endDate: string
  ) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { managementUserId, startDate, endDate },
      query: gql(getTeacherShiftsByManagementUserIdAndDateRange)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getTeacherShiftsByManagementUserIdAndDateRange',
      'variables: ',
      { managementUserId, startDate, endDate },
      'data: ',
      data.getTeacherShiftsByManagementUserIdAndDateRange
    );
    return [...data.getTeacherShiftsByManagementUserIdAndDateRange];
  },

  /**
   * 位置講師のシフト単体(一日分)を取得
   */
  fetchByManagementUserIdAndDate: async (managementUserId: number, date: string) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { managementUserId, date },
      query: gql(getTeacherShiftByManagementUserIdAndDate)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug(
      'getTeacherShiftByManagementUserIdAndDate',
      'variables: ',
      { managementUserId, date },
      'data: ',
      data.getTeacherShiftByManagementUserIdAndDate
    );
    // MEMO:
    return { ...data.getTeacherShiftByManagementUserIdAndDate } as TeacherShift | null;
  },

  /**
   * 一括登録
   */
  bulkRegister: async (input: TeacherShiftsBulkRegisterInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(bulkRegisterTeacherShifts)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('bulkRegisterTeacherShifts input', input);
    logger?.debug('bulkRegisterTeacherShifts data', data.bulkRegisterTeacherShifts);
    return data.bulkRegisterTeacherShifts;
  }
} as const;
