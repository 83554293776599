import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  getTeacher,
  getTeachersByClassroomId
} from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { TeacherRepoInterface } from '~/src/domain/interfaces/repositories/user/teacher';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const teacherRepo: TeacherRepoInterface = {
  /**
   * 講師を取得する.
   * @param username 取得する講師のusername
   */
  fetch: async id => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { id },
      query: gql(getTeacher)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getTeacher', data.getTeacher);
    return ok({ ...data.getTeacher });
  },

  /**
   * 教室ごとの講師一覧を取得する.
   * @param classroomId 絞り込む教室のID
   */
  fetchListByClassroom: async classroomId => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { classroomId },
      query: gql(getTeachersByClassroomId)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getTeachersByClassroomId', data.getTeachersByClassroomId);
    return ok([...data.getTeachersByClassroomId]);
  }
} as const;
