export const deepCopy = <T extends object>(source: T): T =>
  JSON.parse(JSON.stringify(source));

/**
 * ```ts
 * // examples
 * const o = { a: 1, b: 2 }
 * const o2 = { a: 3 }
 * assignProperties(o, o2) // ok
 *
 * const o = { a: 1, b: 2 }
 * const o2 = { c: 3 }
 * assignProperties(o, o2) // error
 * ```
 */
export const assignProperties = <T extends object>(target: T, source: T): T =>
  Object.assign(target, source);

/**
 * ```ts
 * // examples
 * const o = { a: 1, b: 2, c: 3 }
 * const omitted = omitProperties(o, ['c'])
 * $logger.log(omitted) // { a: 1, b: 2 }
 * typeof omitted // Omit<{ a: 1, b: 2, c: 3 }. 'c'>
 * ```
 */
export const omitProperties = <T extends object, U extends keyof T>(
  item: T,
  elms: U[]
): Omit<T, U> =>
  // @ts-ignore
  elms.reduce((x, y) => (({ [y]: _d, ...rest }) => rest)(x), item);

export const pickProperties = <T extends object, U extends keyof T>(
  item: T,
  elms: U[]
): Pick<T, U> => {
  const picked = {} as Pick<T, U>;
  elms.forEach(elm => {
    picked[elm] = item[elm];
  });
  return picked;
};
