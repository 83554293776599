import { Amplify } from '@aws-amplify/core';
import { Auth as AmplifyAuth } from '@aws-amplify/auth';
import { Result, err, ok } from 'neverthrow';
import { AuthInterface } from '../domain/interfaces/auth';
import {
  AuthUnexpectedError,
  AuthUnexpectedResponseError,
  AuthIncorrectUsernameOrPassword
} from '../shared/errors/auth';
/* eslint no-access-process: 0 */

export const createAuth = (config: {
  AWS_AMPLIFY_AUTH_REGION: string;
  AWS_AMPLIFY_AUTH_USER_POOL_ID: string;
  AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID: string;
  AWS_AMPLIFY_AUTH_ID_POOL_ID: string;
}): AuthInterface => {
  Amplify.configure({
    Auth: {
      region: config.AWS_AMPLIFY_AUTH_REGION,
      userPoolId: config.AWS_AMPLIFY_AUTH_USER_POOL_ID,
      userPoolWebClientId: config.AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
      identityPoolId: config.AWS_AMPLIFY_AUTH_ID_POOL_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      clientMetadata: { appName: 'forTeacher' }
    }
  });
  return {
    async signIn(username: string, password: string) {
      try {
        const unsafeUnknownRes = (await AmplifyAuth.signIn(
          username,
          password
        )) as unknown;
        return normalizeAmplifyAuthRes(unsafeUnknownRes);
      } catch (e) {
        if (e instanceof Error) {
          if (e.name === 'UserNotFoundException') {
            // ユーザー名が違う場合
            return err(new AuthIncorrectUsernameOrPassword('user not found.'));
          }
          if (e.name === 'NotAuthorizedException') {
            // パスワードが違う場合
            return err(new AuthIncorrectUsernameOrPassword('user not found.'));
          }
        }
        return err(new AuthUnexpectedError('unexpected error on auth.signIn', e));
      }
    },

    signOut() {
      return AmplifyAuth.signOut();
    },

    currentAuthenticatedUser() {
      return AmplifyAuth.currentAuthenticatedUser();
    },

    completeNewPassword(user: any, password: string) {
      return AmplifyAuth.completeNewPassword(user, password);
    },
    async getJwtToken() {
      const data = await AmplifyAuth.currentSession();
      return data.getAccessToken().getJwtToken();
    }
  };
};

const normalizeAmplifyAuthRes = (
  res: unknown
): Result<{ username: string }, AuthUnexpectedResponseError> => {
  if (
    !(
      typeof res === 'object' &&
      res !== null &&
      'username' in res &&
      typeof (res as { username: unknown }).username === 'string'
    )
  )
    return err(
      new AuthUnexpectedResponseError(
        'got unexpected response from AmplifyAuth.signIn',
        res
      )
    );

  return ok(res as { username: string });
};
