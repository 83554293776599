import { signIn } from './messages/ja/signIn';
import { components } from './messages/ja/components';
import { query } from './messages/ja/query';
import { validateMessages } from './messages/ja/validation';
import { confirm } from './messages/ja/confirm';
import { pageTitles } from './messages/ja/pageTitle';
import { errors } from './messages/ja/errorMessages';
import { noDataMessages } from './messages/ja/noDataMessages';
import { leftKomaInfoText } from './messages/ja/leftKomaInfoText';

const i18nJa = {
  // FIXME: 名前おかしくね
  components,
  validateMessages,
  query,
  errors,
  confirm,
  pageTitles,
  noDataMessages,
  signIn,
  leftKomaInfoText
} as const;

export const i18n = i18nJa; // 多言語対応したければここで条件分岐
