import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getGuidanceJukuTestTemplate } from '~/src/infrastructure/apis/graphql/query/queries';

import { deepCopy } from '~/src/shared/objectOperationUtil';
import { Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidanceJukuTestTemplateRepo = {
  fetchByFolderId: async (guidanceJukuTestTemplateFolderId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { guidanceJukuTestTemplateFolderId },
      query: gql(getGuidanceJukuTestTemplate)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getGuidanceJukuTestTemplate data', data.getGuidanceJukuTestTemplate);
    return deepCopy(data.getGuidanceJukuTestTemplate);
  }
} as const;
