var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PlainDialog', {
    staticClass: "error-dialog",
    attrs: {
      "is-opened": _vm.isOpened,
      "title": _vm.title || 'エラー',
      "width": "300"
    },
    on: {
      "click:close-icon": function ($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "buttonActions",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-end"
        }, [_c('TextBtn', {
          on: {
            "click": function ($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v("OK")])], 1)];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticClass: "msg-text"
  }, [_vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }