/**
 * ### recoのエラー仕様に記載されているエラーかつ、このリポジトリで使用するエラー
 *
 * [仕様書はこちら](https://docs.google.com/spreadsheets/d/1S7p5ypBGz4x_Sfywgh6jwGDVPTSzngfM4nRvdgUltWc/edit#gid=1709641258)
 *
 */
export type ApiErrorUnion =
  | ApiAuthenticationError
  | ApiAuthorizationError
  | ApiRoleError
  | ApiOperationRuleError
  | ApiClassroomLoginError
  | ApiProcessDataTooLargeError
  | ApiFatalError
  | ApiInternalServerError
  | ApiMaintenanceMode
  | ApiParseError
  | ApiDuplicateError
  | ApiInvalidTypeError
  | ApiNotExistError
  | ApiUnexpectedError;

abstract class ApiError extends Error {}

// 認証・認可系
export class ApiAuthenticationError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiAuthorizationError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiRoleError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiOperationRuleError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiClassroomLoginError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}

// サーバーエラー系
export class ApiFatalError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiInternalServerError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiProcessDataTooLargeError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiMaintenanceMode extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}

// バックエンドアプリケーションエラー系
export class ApiParseError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiDuplicateError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiInvalidTypeError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}
export class ApiNotExistError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}

// その他
export class ApiUnexpectedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = ApiAuthenticationError.name;
  }
}

/**
 * NOTE: apolloClientが返した`errors:GraphQLError[]`がClassではなくてただの型として使っていた(new してない)為、instanceofでの判定が不可
 *
 * そのため、errorがオブジェクトかつ、プロパティに`message`が生えていた場合はErrorとする
 *
 * また、TSのバージョンによりinでnarrowできないため、isで型を拡張
 */
const isError = (error: unknown): error is { message: string } =>
  typeof error === 'object' && error !== null && 'message' in error;

/**
 * some error object into ApiError object
 *
 * ex.
 * ```ts
 * const { data, errors } = await client.get();
 * if (errors[0]) return intoApiError(errors[0], "client.get got an error response")
 * ```
 */
export const intoApiError = (
  error: unknown,
  opt: ApiErrorMessageOption
): ApiErrorUnion => {
  const message = createApiErrorMessage(error, opt);

  if (!isError(error)) return new ApiUnexpectedError(message);

  switch (error.message) {
    case 'AuthenticationError':
      return new ApiAuthenticationError(message);
    case 'AuthorizationError':
      return new ApiAuthorizationError(message);
    case 'OperationRuleError':
      return new ApiOperationRuleError(message);
    case 'RoleError':
      return new ApiRoleError(message);
    case 'ClassroomLoginError':
      return new ApiClassroomLoginError(message);

    case 'FatalError':
      return new ApiFatalError(message);
    case 'InternalServerError':
      return new ApiInternalServerError(message);
    case 'ProcessDataTooLargeError':
      return new ApiProcessDataTooLargeError(message);
    case 'MaintenanceMode':
      return new ApiMaintenanceMode(message);

    case 'DuplicateError':
      return new ApiDuplicateError(message);
    case 'InvalidTypeError':
      return new ApiInvalidTypeError(message);
    case 'NotExistError':
      return new ApiNotExistError(message);
    case 'ParseError':
      return new ApiParseError(message);

    default:
      return new ApiUnexpectedError(message);
  }
};

type ApiErrorMessageOption = {
  /**
   * @example
   * `studentRepo`
   */
  repositoryName: string;

  /**
   * @example
   * `fetch`
   */
  repositoryNameMethodName: string;

  rowApiErrorType: HandleApiErrorType;

  /**
   * @example
   * `https://reco.education/sign-in/teacher`
   */
  clientLocation: string;

  /**
   * @example
   * `getStudentById`
   */
  graphOLOperationName?: string;

  /**
   * @example
   * `https://api.reco.education/graphql`
   */
  endpoint?: string;

  statusCode?: number;
  extraMessage?: string;
};

export enum HandleApiErrorType {
  Respond = 'respond',
  Thrown = 'thrown'
}

const createApiErrorMessage = (rowError: unknown, opt: ApiErrorMessageOption): string => {
  const stringified =
    typeof rowError === 'object' ? JSON.stringify(rowError) : `${rowError}`;

  const {
    repositoryName,
    repositoryNameMethodName,
    rowApiErrorType: handleApiErrorType,
    clientLocation,
    graphOLOperationName,
    endpoint,
    statusCode,
    extraMessage
  } = opt;

  let msg = `caught API Error at ${repositoryNameMethodName} on ${repositoryName}.`;
  msg += `\n[client location]: ${clientLocation}`;

  // -- optional data
  graphOLOperationName && (msg += `\n[GraphOL operation name]: ${graphOLOperationName}`);
  endpoint && (msg += `\n[endpoint]: ${endpoint}`);
  statusCode && (msg += `\n[statusCode]: ${statusCode}`);
  extraMessage && (msg += `\n[extra message]: ${extraMessage}`);

  msg += `\n[handle error type]: ${handleApiErrorType}`;
  msg += `\nstringified:\n\t${stringified}`;

  return msg;
};
