/* eslint-disable */
// prettier-ignore
import { Plugin } from '@nuxt/types'

// prettier-ignore
type OptionalQuery0 = {
  id?: number | null;
  date?: string | null;
}

// prettier-ignore
type OptionalQuery1 = {
  date?: string;
}

// prettier-ignore
type OptionalQuery2 = {
  studentGradeId?: number;
  semesterId?: number;
}

// prettier-ignore
type Query3 = {
  studentGradeId: number;
  semesterId?: number;
}

// prettier-ignore
export const pagesPath = {
  home: {
    guidance_schedules: {
      $url: (url?: { hash?: string }) => ({ path: '/home/guidance-schedules', hash: url?.hash }),
      $name: (): RouteName => `home-guidance-schedules`
    },
    student: {
      _studentId: (studentId: string | number) => ({
        exams: {
          original: {
            _originalExamId: (originalExamId?: string | number) => ({
              $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/exams/original${originalExamId !== undefined ? `/${originalExamId}` : ''}`, hash: url?.hash }),
              $name: (): RouteName => `home-student-studentId-exams-original-originalExamId`
            })
          },
          school: {
            _schoolExamId: (schoolExamId?: string | number) => ({
              $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/exams/school${schoolExamId !== undefined ? `/${schoolExamId}` : ''}`, hash: url?.hash }),
              $name: (): RouteName => `home-student-studentId-exams-school-schoolExamId`
            }),
            score_trend: {
              $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/exams/school/score-trend`, hash: url?.hash }),
              $name: (): RouteName => `home-student-studentId-exams-school-score-trend`
            }
          },
          $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/exams`, hash: url?.hash }),
          $name: (): RouteName => `home-student-studentId-exams`
        },
        guidance_report: {
          histories: {
            $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/guidance-report/histories`, hash: url?.hash }),
            $name: (): RouteName => `home-student-studentId-guidance-report-histories`
          },
          $url: (url?: { query?: OptionalQuery0, hash?: string }) => ({ path: `/home/student/${studentId}/guidance-report`, query: url?.query as any, hash: url?.hash }),
          $name: (): RouteName => `home-student-studentId-guidance-report`
        },
        info: {
          $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/info`, hash: url?.hash }),
          $name: (): RouteName => `home-student-studentId-info`
        },
        komas: {
          guidance_koma_calendar: {
            $url: (url?: { query?: OptionalQuery1, hash?: string }) => ({ path: `/home/student/${studentId}/komas/guidance-koma-calendar`, query: url?.query as any, hash: url?.hash }),
            $name: (): RouteName => `home-student-studentId-komas-guidance-koma-calendar`
          },
          student_contract_list: {
            _contractId: (contractId?: string | number) => ({
              $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/komas/student-contract-list${contractId !== undefined ? `/${contractId}` : ''}`, hash: url?.hash }),
              $name: (): RouteName => `home-student-studentId-komas-student-contract-list-contractId`
            }),
            $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/komas/student-contract-list`, hash: url?.hash }),
            $name: (): RouteName => `home-student-studentId-komas-student-contract-list`
          }
        },
        study_plan: {
          detail: {
            $url: (url: { query: Query3, hash?: string }) => ({ path: `/home/student/${studentId}/study-plan/detail`, query: url.query as any, hash: url.hash }),
            $name: (): RouteName => `home-student-studentId-study-plan-detail`
          },
          $url: (url?: { query?: OptionalQuery2, hash?: string }) => ({ path: `/home/student/${studentId}/study-plan`, query: url?.query as any, hash: url?.hash }),
          $name: (): RouteName => `home-student-studentId-study-plan`
        },
        tasks: {
          teacherTaskChecks: {
            _taskCheckId: (taskCheckId?: string | number) => ({
              $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/tasks/teacherTaskChecks${taskCheckId !== undefined ? `/${taskCheckId}` : ''}`, hash: url?.hash }),
              $name: (): RouteName => `home-student-studentId-tasks-teacherTaskChecks-taskCheckId`
            }),
            $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/tasks/teacherTaskChecks`, hash: url?.hash }),
            $name: (): RouteName => `home-student-studentId-tasks-teacherTaskChecks`
          },
          todo_progress: {
            $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/tasks/todo-progress`, hash: url?.hash }),
            $name: (): RouteName => `home-student-studentId-tasks-todo-progress`
          }
        },
        teacher_shared_info: {
          $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/teacher-shared-info`, hash: url?.hash }),
          $name: (): RouteName => `home-student-studentId-teacher-shared-info`
        },
        textbooks: {
          list: {
            _textbookId: (textbookId?: string | number) => ({
              $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/textbooks/list${textbookId !== undefined ? `/${textbookId}` : ''}`, hash: url?.hash }),
              $name: (): RouteName => `home-student-studentId-textbooks-list-textbookId`
            }),
            $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/textbooks/list`, hash: url?.hash }),
            $name: (): RouteName => `home-student-studentId-textbooks-list`
          },
          study_history: {
            $url: (url?: { hash?: string }) => ({ path: `/home/student/${studentId}/textbooks/study-history`, hash: url?.hash }),
            $name: (): RouteName => `home-student-studentId-textbooks-study-history`
          }
        }
      })
    },
    students: {
      $url: (url?: { hash?: string }) => ({ path: '/home/students', hash: url?.hash }),
      $name: (): RouteName => `home-students`
    },
    tasks: {
      teacher: {
        _taskId: (taskId?: string | number) => ({
          $url: (url?: { hash?: string }) => ({ path: `/home/tasks/teacher${taskId !== undefined ? `/${taskId}` : ''}`, hash: url?.hash }),
          $name: (): RouteName => `home-tasks-teacher-taskId`
        }),
        $url: (url?: { hash?: string }) => ({ path: '/home/tasks/teacher', hash: url?.hash }),
        $name: (): RouteName => `home-tasks-teacher`
      }
    },
    teachers: {
      _teacherId: (teacherId?: string | number) => ({
        $url: (url?: { hash?: string }) => ({ path: `/home/teachers${teacherId !== undefined ? `/${teacherId}` : ''}`, hash: url?.hash }),
        $name: (): RouteName => `home-teachers-teacherId`
      }),
      list: {
        $url: (url?: { hash?: string }) => ({ path: '/home/teachers/list', hash: url?.hash }),
        $name: (): RouteName => `home-teachers-list`
      },
      shifts: {
        $url: (url?: { hash?: string }) => ({ path: '/home/teachers/shifts', hash: url?.hash }),
        $name: (): RouteName => `home-teachers-shifts`
      }
    }
  },
  sign_in: {
    classroom: {
      $url: (url?: { hash?: string }) => ({ path: '/sign-in/classroom', hash: url?.hash }),
      $name: (): RouteName => `sign-in-classroom`
    },
    teacher: {
      $url: (url?: { hash?: string }) => ({ path: '/sign-in/teacher', hash: url?.hash }),
      $name: (): RouteName => `sign-in-teacher`
    }
  },
  $url: (url?: { hash?: string }) => ({ path: '/', hash: url?.hash }),
  $name: (): RouteName => ``
}

// prettier-ignore
export type PagesPath = typeof pagesPath

// prettier-ignore
export type RouteName = '' | 'home-guidance-schedules' | 'home-student-studentId-exams' | 'home-student-studentId-exams-original-originalExamId' | 'home-student-studentId-exams-school-schoolExamId' | 'home-student-studentId-exams-school-score-trend' | 'home-student-studentId-guidance-report' | 'home-student-studentId-guidance-report-histories' | 'home-student-studentId-info' | 'home-student-studentId-komas-guidance-koma-calendar' | 'home-student-studentId-komas-student-contract-list' | 'home-student-studentId-komas-student-contract-list-contractId' | 'home-student-studentId-study-plan' | 'home-student-studentId-study-plan-detail' | 'home-student-studentId-tasks-teacherTaskChecks' | 'home-student-studentId-tasks-teacherTaskChecks-taskCheckId' | 'home-student-studentId-tasks-todo-progress' | 'home-student-studentId-teacher-shared-info' | 'home-student-studentId-textbooks-list' | 'home-student-studentId-textbooks-list-textbookId' | 'home-student-studentId-textbooks-study-history' | 'home-students' | 'home-tasks-teacher' | 'home-tasks-teacher-taskId' | 'home-teachers-teacherId' | 'home-teachers-list' | 'home-teachers-shifts' | 'sign-in-classroom' | 'sign-in-teacher'

// prettier-ignore
declare module 'vue/types/vue' {
  interface Vue {
    $pagesPath: PagesPath
  }
}

// prettier-ignore
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $pagesPath: PagesPath
  }

  interface Context {
    $pagesPath: PagesPath
  }
}

// prettier-ignore
declare module 'vuex/types/index' {
  interface Store<S> {
    $pagesPath: PagesPath
  }
}

// prettier-ignore
const pathPlugin: Plugin = (_, inject) => {
  inject('pagesPath', pagesPath)
}

// prettier-ignore
export default pathPlugin
