import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { registerGuidancePolicy } from '~/src/infrastructure/apis/graphql/query/mutations';
import { GuidancePolicyRegisterInput, Mutation } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidancePolicyRepo = {
  update: async (guidancePolicyRegisterInput: GuidancePolicyRegisterInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { guidancePolicyRegisterInput },
      mutation: gql(registerGuidancePolicy)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('registerGuidancePolicy input', registerGuidancePolicy);
    logger?.debug('registerGuidancePolicy data', data.registerGuidancePolicy);
    return { ...data.registerGuidancePolicy };
  }
} as const;
