import { ref, computed } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { TeacherSharedComment } from '~/src/domain/entities/schema';
import { useUserStore } from '~/src/framework/store/user';
import { getUnreadTeacherSharedCommentIds } from '~/src/domain/models/user/teacherSharedInfo/common';
import { TEACHER_SHARED_COMMENTS_PLACE_HOLDER } from '~/src/domain/models/user/common/placeHolder';

export const useTeacherSharedCommentsStore = defineStore('teacherSharedComments', () => {
  const userStore = useUserStore();

  const teacherSharedComments = ref<TeacherSharedComment[]>(
    TEACHER_SHARED_COMMENTS_PLACE_HOLDER
  );

  const get = (): Readonly<TeacherSharedComment[]> => teacherSharedComments.value;
  const set = (newComments: TeacherSharedComment[]): void => {
    teacherSharedComments.value = newComments;
  };

  const unreadIds = computed<number[]>(() =>
    getUnreadTeacherSharedCommentIds(
      teacherSharedComments.value,
      userStore.get().managementUserId
    )
  );

  return { get, set, unreadIds };
});
