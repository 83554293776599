import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { registerGuidanceReviews } from '~/src/infrastructure/apis/graphql/query/mutations';
import { Mutation } from '~/src/domain/entities/schema';
import { GuidanceReviewRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/guidanceReport/guidanceReview';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidanceReviewRepo: GuidanceReviewRepositoryInterface = {
  register: async (guidanceReportId, guidanceReviews) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { guidanceReportId, guidanceReviews },
      mutation: gql(registerGuidanceReviews)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('registerGuidanceReviews', data.registerGuidanceReviews);
    return ok([...data.registerGuidanceReviews]);
  }
} as const;
