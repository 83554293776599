import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
// import { apolloClient } from '~/src//infrastructure/apis/graphql/client';
import { legacyApolloClient } from '~/src/infrastructure/apis/graphql/legacyClient';
// import { getStudyPlanSubjectInfos } from '~/src/infrastructure/apis/graphql/query/queries';
import { getStudyPlanSubjectInfos as getStudyPlanSubjectInfosLegacy } from '~/src/infrastructure/apis/graphql/query/legacy/queries';
// import { Query } from '~/src/domain/entities/schema';
import { Query as QueryLegacy } from '~/src/domain/entities/schema.legacy';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { studyPlanSubjectInfoRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/studyPlan/studyPlanSubjectInfo';

export const studyPlanSubjectInfoRepo: studyPlanSubjectInfoRepositoryInterface = {
  // // バックエンドが学習計画の情報からたどる
  // fetchByStudyPlan: async (
  //   studentGradeId: number,
  //   semesterId: number,
  //   fiscalYear: number,
  //   studentId: number
  // ) => {
  //   const { data, errors } = await apolloClient.query<Query>({
  //     variables: { studentGradeId, semesterId, fiscalYear, studentId },
  //     query: gql(getStudyPlanSubjectInfos)
  //   });
  //   if (errors?.length) return err(errors);
  //   if (!data) return err(new DataNotExistError());
  //   logger?.debug('getStudyPlanSubjectInfos', data.getStudyPlanSubjectInfos);
  //   return ok([...data.getStudyPlanSubjectInfos]);
  // },
  /*
   * legacyMethods
   */
  legacy: {
    fetchByStudyPlan: async (
      studentGradeId: number,
      semesterId: number,
      fiscalYear: number,
      studentId: number
    ) => {
      const { data, errors } = await legacyApolloClient.query<QueryLegacy>({
        variables: { studentGradeId, semesterId, fiscalYear, studentId },
        query: gql(getStudyPlanSubjectInfosLegacy)
      });
      if (errors?.length) return err(errors);
      if (!data) return err(new DataNotExistError());
      logger?.debug('getStudyPlanSubjectInfos', data.getStudyPlanSubjectInfos);
      return ok([...data.getStudyPlanSubjectInfos]);
    }
  }
} as const;
