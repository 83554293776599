
import { computed, ref } from '@nuxtjs/composition-api';
interface Props {
  isOpened: boolean;
  title: string;
  fullscreen: boolean;
  width?: string | number;
  height?: string | number;
  isShowedPreviousBtn?: boolean;
  isEdit?: boolean;
  noButtonActions?: boolean;
  noCloseButtonInHeader?: boolean;
}

// @ts-ignore
const __sfc_main = {};
__sfc_main.props = {
  isOpened: {
    key: "isOpened",
    required: true,
    type: Boolean
  },
  title: {
    key: "title",
    required: true,
    type: String
  },
  fullscreen: {
    key: "fullscreen",
    required: false,
    type: Boolean,
    default: false
  },
  width: {
    key: "width",
    required: false,
    type: [String, Number],
    default: '588px'
  },
  height: {
    key: "height",
    required: false,
    type: [String, Number]
  },
  isShowedPreviousBtn: {
    key: "isShowedPreviousBtn",
    required: false,
    type: Boolean,
    default: false
  },
  isEdit: {
    key: "isEdit",
    required: false,
    type: Boolean,
    default: false
  },
  noButtonActions: {
    key: "noButtonActions",
    required: false,
    type: Boolean,
    default: false
  },
  noCloseButtonInHeader: {
    key: "noCloseButtonInHeader",
    required: false,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const headerRef = ref<HTMLElement | null>(null);
  const HEADER_PADDING_PX = 8;
  const headerHeightPx = computed(() => headerRef.value?.clientHeight ? headerRef.value.clientHeight + HEADER_PADDING_PX * 2 : 0);
  const buttonActionsRef = ref<HTMLElement | null>(null);
  const buttonActionsHeightPx = computed(() => buttonActionsRef.value?.clientHeight ?? 0);
  const BASE_CARD_PADDING_PX = 12;
  const maxSlotHeight = computed(() =>
  // NOTE: fullscreenでない時のslotコンテンツの最大は仮にinnerHeightの0.8倍としている. これ以上大きくするとslotとdialogの二重スクロールが生じる.
  // window.innerHeightの1倍では少しoverflowしてしまうので, 仕方なく0.97倍としている.
  window.innerHeight * (props.fullscreen ? 0.97 : 0.8) - (headerHeightPx.value + buttonActionsHeightPx.value));
  return {
    headerRef,
    HEADER_PADDING_PX,
    buttonActionsRef,
    BASE_CARD_PADDING_PX,
    maxSlotHeight
  };
};
export default __sfc_main;
