import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VBtn, _vm._g({
    staticClass: "rounded-btn font-weight-bold",
    class: {
      'white--text': !_vm.outlined
    },
    attrs: {
      "outlined": _vm.outlined,
      "width": _vm.isWide ? '16rem' : _vm.mini ? '4.5rem' : '7.5rem',
      "height": _vm.height,
      "color": _vm.color,
      "disabled": _vm.disabled,
      "depressed": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('click');
      }
    }
  }, _vm.on), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }