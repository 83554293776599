import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VBtn, {
    staticClass: "font-weight-bold mr-4",
    attrs: {
      "height": "1.75rem",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('prev');
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-chevron-left")]), _vm._v("\n  戻る\n")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }