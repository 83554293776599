import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  bulkRegisterOriginalExamSubjects,
  createOriginalExamSubject,
  deleteOriginalExamSubject,
  updateOriginalExamSubject
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  Mutation,
  OriginalExamSubjectCreateInput,
  OriginalExamSubjectDeleteInput,
  OriginalExamSubjectsBulkRegisterInput,
  OriginalExamSubjectUpdateInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { OriginalExamSubjectRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/exam/original/originalExamSubject';
import { DataNotExistError } from '~/src/shared/errors/errors';

export const originalExamSubjectRepo: OriginalExamSubjectRepositoryInterface = {
  // 作成
  create: async (input: OriginalExamSubjectCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createOriginalExamSubject)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('createOriginalExamSubject input', input);
    logger?.debug('createOriginalExamSubject response', data.createOriginalExamSubject);
    return ok({ ...data.createOriginalExamSubject });
  },

  // 編集
  update: async (input: OriginalExamSubjectUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateOriginalExamSubject)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('updateOriginalExamSubject input', input);
    logger?.debug('updateOriginalExamSubject response', data.updateOriginalExamSubject);
    return ok({ ...data.updateOriginalExamSubject });
  },

  // 削除
  delete: async (input: OriginalExamSubjectDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteOriginalExamSubject)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('deleteOriginalExamSubject input', input);
    logger?.debug('deleteOriginalExamSubject response', data.deleteOriginalExamSubject);
    return ok({ ...data.deleteOriginalExamSubject });
  },

  // 一括登録
  bulkRegister: async (inputs: OriginalExamSubjectsBulkRegisterInput[]) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { inputs },
      mutation: gql(bulkRegisterOriginalExamSubjects)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('bulkRegisterOriginalExamSubjects inputs', inputs);
    logger?.debug(
      'bulkRegisterOriginalExamSubjects response',
      data.bulkRegisterOriginalExamSubjects
    );
    return ok([...data.bulkRegisterOriginalExamSubjects]);
  }
} as const;
