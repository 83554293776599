import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { registerWeeklyStudyPlanMemo } from '~/src/infrastructure/apis/graphql/query/mutations';
import { Mutation, WeeklyStudyPlanMemoRegisterInput } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { WeeklyStudyPlanMemoRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/studyPlan/weeklyStudyPlanMemo';
import { DataNotExistError } from '~/src/shared/errors/errors';

export const weeklyStudyPlanMemoRepo: WeeklyStudyPlanMemoRepositoryInterface = {
  register: async (input: WeeklyStudyPlanMemoRegisterInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(registerWeeklyStudyPlanMemo)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('registerWeeklyStudyPlanMemo', data.registerWeeklyStudyPlanMemo);
    return ok({ ...data.registerWeeklyStudyPlanMemo });
  }
} as const;
