var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-center align-center",
    staticStyle: {
      "margin-top": "30vh"
    }
  }, [_c('div', [_c('i', {
    staticClass: "mdi mdi-emoticon-dead-outline primary--text text-h2 text-center d-block mb-4"
  }), _vm._v(" "), _c('h1', {
    staticClass: "text-center text-h5 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.errorMessage))]), _vm._v(" "), _c('RoundedBtn', {
    staticClass: "mt-4 mx-auto d-block",
    attrs: {
      "is-wide": ""
    },
    on: {
      "click": _vm.toTop
    }
  }, [_vm._v("\n      トップへ戻る\n    ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }