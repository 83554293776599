const middleware = {}

middleware['checkAuthentication'] = require('../src/framework/middleware/checkAuthentication.ts')
middleware['checkAuthentication'] = middleware['checkAuthentication'].default || middleware['checkAuthentication']

middleware['fetchTeacherSharedComments'] = require('../src/framework/middleware/fetchTeacherSharedComments.ts')
middleware['fetchTeacherSharedComments'] = middleware['fetchTeacherSharedComments'].default || middleware['fetchTeacherSharedComments']

middleware['fetchTeacherTaskChecks'] = require('../src/framework/middleware/fetchTeacherTaskChecks.ts')
middleware['fetchTeacherTaskChecks'] = middleware['fetchTeacherTaskChecks'].default || middleware['fetchTeacherTaskChecks']

middleware['initSessionReplay'] = require('../src/framework/middleware/initSessionReplay.ts')
middleware['initSessionReplay'] = middleware['initSessionReplay'].default || middleware['initSessionReplay']

export default middleware
