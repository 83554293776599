import * as Sentry from '@sentry/browser';
import {
  maskPropertiesOfEncryptionRequirements,
  sanitizerForReplayFrameEvent
} from './sanitize';
import { LoggerInterface } from '~/src/domain/interfaces/logger.interface';

type LoggerEnv = {
  sentryDsn: string;
  stageName: string;
  appVersion: string;
};

export class Logger implements LoggerInterface {
  private sentry = Sentry;
  private replay: Sentry.Replay | null = null;
  private isInitializedSentry = false;

  constructor(private env: LoggerEnv) {}

  initErrorTracker(username: string): void {
    if (this.isInitializedSentry) return;
    this.sentry.init({
      dsn: this.env.sentryDsn,
      environment: this.env.stageName,
      release: this.env.appVersion,
      integrations: [new this.sentry.BrowserTracing()],
      ignoreErrors: [/The user is not authenticated/, /Unexpected token '<'/],
      tracesSampleRate: 0.01,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1
    });
    this.sentry.configureScope(scope => scope.setUser({ id: username }));
    this.isInitializedSentry = true;
    this.log('sentry initialized.');
  }

  initSessionReplay(username: string): void {
    if (this.replay) return;
    const replay = new this.sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        /^https:\/\/(api|api-sub)\.(dev-reco\.net|stg-reco\.jp|reco\.education)\/.*/,
        /^https:\/\/cognito.*/,
        new RegExp('^' + window.location.origin.replaceAll('.', '\\.') + '/.*')
      ],
      networkCaptureBodies: true,
      beforeAddRecordingEvent: event => {
        return sanitizerForReplayFrameEvent(event);
      }
    });
    this.sentry.configureScope(scope => scope.setUser({ id: username }));
    this.sentry.getCurrentHub().getClient()?.addIntegration?.(replay);
    this.replay = replay;
    this.log('sentry replay initialized.');
  }

  identify(username: string, data: Record<string, string>): void {
    this.sentry.configureScope(scope =>
      scope.setUser({
        username
      })
    );
  }

  stopSessionReplay(): void {
    if (!this.replay) return;
    this.replay.stop();
  }

  restartSessionReplay(): void {
    if (!this.replay) return;
    this.replay.start();
  }

  captureException(e: unknown): void {
    this.sentry.captureException(e);
  }

  log(summary: string, ...props: any[]): void {
    const payload: Sentry.Breadcrumb = {
      message: `[LOG]: ${summary}`,
      level: 'log',
      type: 'debug'
    };
    if (props.length) {
      payload.data = props.map(it => maskPropertiesOfEncryptionRequirements(it));
    }
    this.sentry.addBreadcrumb(payload);
  }

  debug(summary: string, ...props: any[]): void {
    const payload: Sentry.Breadcrumb = {
      message: `[DEBUG]: ${summary}`,
      level: 'debug',
      type: 'debug'
    };
    if (props.length) {
      payload.data = props.map(it => maskPropertiesOfEncryptionRequirements(it));
    }
    this.sentry.addBreadcrumb(payload);
  }

  info(summary: string, ...props: any[]): void {
    const payload: Sentry.Breadcrumb = {
      message: `[INFO]: ${summary}`,
      level: 'info',
      type: 'debug'
    };
    if (props.length) {
      payload.data = props.map(it => maskPropertiesOfEncryptionRequirements(it));
    }
    this.sentry.addBreadcrumb(payload);
  }

  warn(summary: string, ...props: any[]): void {
    const payload: Sentry.Breadcrumb = {
      message: `[WARN]: ${summary}`,
      level: 'warning',
      type: 'debug'
    };
    if (props.length) {
      payload.data = props.map(it => maskPropertiesOfEncryptionRequirements(it));
    }
    this.sentry.addBreadcrumb(payload);
  }

  error(summary: string, ...props: any[]): void {
    const payload: Sentry.Breadcrumb = {
      message: `[ERROR]: ${summary}`,
      level: 'error',
      type: 'error'
    };
    if (props.length) {
      payload.data = props.map(it => maskPropertiesOfEncryptionRequirements(it));
    }
    this.sentry.addBreadcrumb(payload);
  }
}
