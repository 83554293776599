














import { defineComponent, computed, useRouter } from '@nuxtjs/composition-api';
import { preventZoom } from '~/src/infrastructure/browser/preventZoom';
import { pagesPath } from '~/src/framework/plugins/$path';

/* 拡大縮小を禁止 */
preventZoom();

export default defineComponent({
  layout: 'no_header',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const errorMessage = computed(() =>
      props.error.statusCode === 404
        ? 'ページが見つかりませんでした。'
        : 'エラーが発生しました。'
    );
    const router = useRouter();
    const toTop = () => router.push(pagesPath.$url());

    return {
      errorMessage,
      toTop
    };
  }
});
