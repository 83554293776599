import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  getTeacherTasksByClassroomId,
  getTeacherTask
} from '~/src/infrastructure/apis/graphql/query/queries';
import {
  completeTeacherTask,
  incompleteTeacherTask,
  editTeacherTaskStudents,
  createTeacherTask,
  updateTeacherTask,
  deleteTeacherTask
} from '~/src/infrastructure/apis/graphql/query/mutations';
import { Mutation, Query } from '~/src/domain/entities/schema';
import { deepCopy } from '~/src/shared/objectOperationUtil';
import { TeacherTaskRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/task/teacherTask';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const teacherTaskRepo: TeacherTaskRepositoryInterface = {
  /**
   * 単体取得
   */
  fetch: async id => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { id },
      query: gql(getTeacherTask)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getTeacherTask', data.getTeacherTask);
    return ok(deepCopy(data.getTeacherTask));
  },

  /**
   * タスク一覧を取得する.
   * @param classroomId taskを持っているユーザーのid
   * @return TeacherTask[]
   */
  fetchByClassroomId: async classroomId => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { classroomId },
      query: gql(getTeacherTasksByClassroomId)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getTeacherTasksByClassroomId', data.getTeacherTasksByClassroomId);
    return ok([...data.getTeacherTasksByClassroomId]);
  },

  /**
   * タスク作成
   */
  create: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createTeacherTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('createTeacherTask', data.createTeacherTask);
    return ok(deepCopy(data.createTeacherTask));
  },

  update: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateTeacherTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('updateTeacherTask', data.updateTeacherTask);
    return ok(deepCopy(data.updateTeacherTask));
  },

  delete: async input => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteTeacherTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('deleteTeacherTask', data.deleteTeacherTask);
    return ok(deepCopy(data.deleteTeacherTask));
  },

  /**
   * タスク完了・未完了
   */
  complete: async completeTeacherTaskInput => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: completeTeacherTaskInput },
      mutation: gql(completeTeacherTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('completeTeacherTask', data.completeTeacherTask);
    return ok({ ...data.completeTeacherTask });
  },

  incomplete: async incompleteTeacherTaskInput => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: incompleteTeacherTaskInput },
      mutation: gql(incompleteTeacherTask)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('incompleteTeacherTask', data.incompleteTeacherTask);
    return ok({ ...data.incompleteTeacherTask });
  },

  /**
   * 講師課題に紐付く生徒を編集
   */
  editTeacherRelation: async editTeacherTasksInput => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input: editTeacherTasksInput },
      mutation: gql(editTeacherTaskStudents)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('editTeacherTaskStudents', data.editTeacherTaskStudents);
    return ok([...data.editTeacherTaskStudents]);
  }
} as const;
