import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { getParentReportTagsByJukuId } from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { ParentReportTagRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/guidance/guidanceReport/parentReportTag';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const parentReportTagRepo: ParentReportTagRepositoryInterface = {
  /**
   * 保護者報告タグ一覧取得
   */
  fetchByJukuId: async jukuId => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { jukuId },
      query: gql(getParentReportTagsByJukuId)
    });
    if (errors?.length) return err(errors);
    logger?.debug('getParentReportTagsByJukuId', data.getParentReportTagsByJukuId);
    return ok([...data.getParentReportTagsByJukuId]);
  }
} as const;
