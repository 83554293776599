import { maskPropertiesOfEncryptionRequirements } from './sanitize';
import { LoggerInterface } from '~/src/domain/interfaces/logger.interface';

export class LocalLogger implements LoggerInterface {
  initErrorTracker(): void {
    /** do nothing */
  }

  initSessionReplay(): void {
    /** do nothing */
  }

  identify(_uid: string, _data: Record<string, string>): void {
    /** do nothing */
  }

  stopSessionReplay(): void {}

  restartSessionReplay(): void {}

  captureException(_e: unknown): void {
    /** do nothing */
  }

  log(...props: any[]): void {
    console.log(...props.map(it => maskPropertiesOfEncryptionRequirements(it)));
  }

  debug(...props: any[]): void {
    console.debug(...props.map(it => maskPropertiesOfEncryptionRequirements(it)));
  }

  error(...props: any[]): void {
    console.error(...props.map(it => maskPropertiesOfEncryptionRequirements(it)));
  }

  warn(...props: any[]): void {
    console.warn(...props.map(it => maskPropertiesOfEncryptionRequirements(it)));
  }
}
