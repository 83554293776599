import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { EnvVars } from '~/env/env';
import { AuthInterface } from '~/src/domain/interfaces/auth';
import { createAuth } from '~/src/infrastructure/auth';

declare module '@nuxt/types' {
  interface Context {
    $auth: AuthInterface;
  }
}

// eslint-disable-next-line import/no-mutable-exports
export let auth: AuthInterface | undefined;

const AuthProvider = defineNuxtPlugin(({ $config }, provide) => {
  const {
    AWS_AMPLIFY_AUTH_REGION,
    AWS_AMPLIFY_AUTH_USER_POOL_ID,
    AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    AWS_AMPLIFY_AUTH_ID_POOL_ID
  } = $config as EnvVars.T;
  provide(
    'auth',
    (auth = createAuth({
      AWS_AMPLIFY_AUTH_REGION,
      AWS_AMPLIFY_AUTH_USER_POOL_ID,
      AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
      AWS_AMPLIFY_AUTH_ID_POOL_ID
    }))
  );
});

export default AuthProvider;
