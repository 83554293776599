import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getOptionForLeftKomaInfo } from '~/src/infrastructure/apis/graphql/query/queries';
import { ExamSearchOptions, Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const optionsForLeftKomaInfoRepo = {
  fetch: async (studentId: number, examSearchOptions: ExamSearchOptions) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, examSearchOptions },
      query: gql(getOptionForLeftKomaInfo)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getOptionForLeftKomaInfo, data', data.getOptionForLeftKomaInfo);
    return { ...data.getOptionForLeftKomaInfo };
  }
} as const;
