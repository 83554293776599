import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { LocalStorageInterface } from '~/src/domain/interfaces/browser/localStorage';
import { localStorageWrapper } from '~/src/infrastructure/browser/localStorage';

declare module '@nuxt/types' {
  interface Context {
    $localStorage: LocalStorageInterface;
  }
}

const LocalStorageProvider = defineNuxtPlugin((_, provide) => {
  provide('localStorage', localStorageWrapper);
});

export default LocalStorageProvider;
