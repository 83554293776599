import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { LoggerInterface } from '~/src/domain/interfaces/logger.interface';
import { LocalLogger } from '~/src/infrastructure/logger/localLogger';
import { Logger } from '~/src/infrastructure/logger/logger';
import { DevLogger } from '~/src/infrastructure/logger/devLogger';
import { useUserStore } from '~/src/framework/store/user';
import { EnvVars } from '~/env/env';

declare module '@nuxt/types' {
  interface Context {
    $logger: LoggerInterface;
  }
}

// FIXME: 設計変更に伴ってlogger objectを一時的にexportしている。将来的には全てDIされたものを使う (Nuxtのctxから参照する)
// eslint-disable-next-line import/no-mutable-exports
export let logger: LoggerInterface | undefined;

const LoggerProvider = defineNuxtPlugin(({ $config }, provide) => {
  // eslint-disable-next-line camelcase
  const { STAGE_NAME, npm_package_name, SENTRY_DSN } = $config as EnvVars.T;
  const user = useUserStore();

  // NOTE: logger.initSessionReplay is lazy called on middleware
  // logger.initSessionReplay()

  provide(
    'logger',
    (logger = (() => {
      const stageName = STAGE_NAME;
      // eslint-disable-next-line camelcase
      const appVersion = npm_package_name;
      const sentryDsn = SENTRY_DSN;
      switch (stageName) {
        case 'production':
          return new Logger({ stageName, appVersion, sentryDsn });
        case 'development':
        case 'staging':
          return new DevLogger({ stageName, appVersion, sentryDsn });
        case 'local':
          return new LocalLogger();
        default: {
          const _exhaustiveCheck: never = stageName;
          console.warn(`Unexpected stageName: ${_exhaustiveCheck}`);
          return new LocalLogger();
        }
      }
    })())
  );

  logger.initErrorTracker(user.get().username);
});

export default LoggerProvider;
