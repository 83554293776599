import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { dayjs } from '~/src/shared/date';
import { JukuConfig } from '~/src/domain/entities/schema';

const createDefaultJukuConfig = (): JukuConfig => ({
  __typename: 'JukuConfig',
  id: -1,
  jukuId: -1,
  lineLinkFlag: false,
  parentContactFlag: false,
  currentFiscalYear: parseInt(dayjs().format('YYYY')),
  promotionPlanDate: '',
  promotionHistories: [],
  studyplusLinkFlag: false
});

export const useJukuConfigStore = defineStore('jukuConfig', () => {
  const jukuConfig = ref(createDefaultJukuConfig());

  const get = (): Readonly<JukuConfig> => jukuConfig.value;
  const set = (newJukuConfig: JukuConfig): void => {
    jukuConfig.value = newJukuConfig;
  };

  return { get, set };
});
