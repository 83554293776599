import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { editStudentTags } from '~/src/infrastructure/apis/graphql/query/mutations';
import { getStudentTagsByJukuId } from '~/src/infrastructure/apis/graphql/query/queries';
import { StudentTag, EditStudentTagsInput } from '~/src/domain/entities/schema';
import { StudentTagRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/tag/studentTag';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { DataNotExistError } from '~/src/shared/errors/errors';

/**
 * type
 */
interface GetStudentTagsByJukuId {
  getStudentTagsByJukuId: StudentTag[];
}
interface EditStudentTags {
  editStudentTags: StudentTag[];
}

/**
 * repo
 */
export const studentTagRepo: StudentTagRepositoryInterface = {
  /**
   * 生徒タグ一覧を取得する.
   */
  fetchByJukuId: async (jukuId: number) => {
    const { data, errors } = await apolloClient.query<GetStudentTagsByJukuId>({
      variables: { jukuId },
      query: gql(getStudentTagsByJukuId)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getStudentTagsByJukuId', data.getStudentTagsByJukuId);
    return ok([...data.getStudentTagsByJukuId]);
  },

  /**
   * 生徒タグつけ外し
   */
  update: async (input: EditStudentTagsInput) => {
    const { data, errors } = await apolloClient.mutate<EditStudentTags>({
      variables: { input },
      mutation: gql(editStudentTags)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('editStudentTags input', input);
    logger?.debug('editStudentTags data', data.editStudentTags);
    return ok([...data.editStudentTags]);
  }
} as const;
