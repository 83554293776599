import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _625bd2b8 = () => interopDefault(import('../src/pages/home.vue' /* webpackChunkName: "src/pages/home" */))
const _3ca2b9fc = () => interopDefault(import('../src/pages/home/guidance-schedules.vue' /* webpackChunkName: "src/pages/home/guidance-schedules" */))
const _fd9ef15a = () => interopDefault(import('../src/pages/home/students.vue' /* webpackChunkName: "src/pages/home/students" */))
const _1f475939 = () => interopDefault(import('../src/pages/home/tasks/teacher/index.vue' /* webpackChunkName: "src/pages/home/tasks/teacher/index" */))
const _6055d271 = () => interopDefault(import('../src/pages/home/teachers/list.vue' /* webpackChunkName: "src/pages/home/teachers/list" */))
const _3faea1c4 = () => interopDefault(import('../src/pages/home/teachers/shifts.vue' /* webpackChunkName: "src/pages/home/teachers/shifts" */))
const _7b320e74 = () => interopDefault(import('../src/pages/home/tasks/teacher/_taskId.vue' /* webpackChunkName: "src/pages/home/tasks/teacher/_taskId" */))
const _f2795ff4 = () => interopDefault(import('../src/pages/home/student/_studentId.vue' /* webpackChunkName: "src/pages/home/student/_studentId" */))
const _a8d9b2a4 = () => interopDefault(import('../src/pages/home/student/_studentId/exams/index.vue' /* webpackChunkName: "src/pages/home/student/_studentId/exams/index" */))
const _39c97ac3 = () => interopDefault(import('../src/pages/home/student/_studentId/guidance-report/index.vue' /* webpackChunkName: "src/pages/home/student/_studentId/guidance-report/index" */))
const _a705ee72 = () => interopDefault(import('../src/pages/home/student/_studentId/info.vue' /* webpackChunkName: "src/pages/home/student/_studentId/info" */))
const _64e3c689 = () => interopDefault(import('../src/pages/home/student/_studentId/study-plan/index.vue' /* webpackChunkName: "src/pages/home/student/_studentId/study-plan/index" */))
const _4206b3bc = () => interopDefault(import('../src/pages/home/student/_studentId/teacher-shared-info.vue' /* webpackChunkName: "src/pages/home/student/_studentId/teacher-shared-info" */))
const _7b36ada3 = () => interopDefault(import('../src/pages/home/student/_studentId/guidance-report/histories.vue' /* webpackChunkName: "src/pages/home/student/_studentId/guidance-report/histories" */))
const _5cd7002a = () => interopDefault(import('../src/pages/home/student/_studentId/komas/guidance-koma-calendar.vue' /* webpackChunkName: "src/pages/home/student/_studentId/komas/guidance-koma-calendar" */))
const _e10c4566 = () => interopDefault(import('../src/pages/home/student/_studentId/komas/student-contract-list/index.vue' /* webpackChunkName: "src/pages/home/student/_studentId/komas/student-contract-list/index" */))
const _602fcbac = () => interopDefault(import('../src/pages/home/student/_studentId/study-plan/detail.vue' /* webpackChunkName: "src/pages/home/student/_studentId/study-plan/detail" */))
const _46bc640b = () => interopDefault(import('../src/pages/home/student/_studentId/tasks/teacherTaskChecks/index.vue' /* webpackChunkName: "src/pages/home/student/_studentId/tasks/teacherTaskChecks/index" */))
const _08347cec = () => interopDefault(import('../src/pages/home/student/_studentId/tasks/todo-progress.vue' /* webpackChunkName: "src/pages/home/student/_studentId/tasks/todo-progress" */))
const _3ecb236c = () => interopDefault(import('../src/pages/home/student/_studentId/textbooks/list/index.vue' /* webpackChunkName: "src/pages/home/student/_studentId/textbooks/list/index" */))
const _257f3d15 = () => interopDefault(import('../src/pages/home/student/_studentId/textbooks/study-history.vue' /* webpackChunkName: "src/pages/home/student/_studentId/textbooks/study-history" */))
const _09362c4a = () => interopDefault(import('../src/pages/home/student/_studentId/exams/school/score-trend.vue' /* webpackChunkName: "src/pages/home/student/_studentId/exams/school/score-trend" */))
const _64adb300 = () => interopDefault(import('../src/pages/home/student/_studentId/exams/original/_originalExamId.vue' /* webpackChunkName: "src/pages/home/student/_studentId/exams/original/_originalExamId" */))
const _1d738cf4 = () => interopDefault(import('../src/pages/home/student/_studentId/exams/school/_schoolExamId.vue' /* webpackChunkName: "src/pages/home/student/_studentId/exams/school/_schoolExamId" */))
const _05eb56b2 = () => interopDefault(import('../src/pages/home/student/_studentId/komas/student-contract-list/_contractId.vue' /* webpackChunkName: "src/pages/home/student/_studentId/komas/student-contract-list/_contractId" */))
const _0c0f0f96 = () => interopDefault(import('../src/pages/home/student/_studentId/tasks/teacherTaskChecks/_taskCheckId.vue' /* webpackChunkName: "src/pages/home/student/_studentId/tasks/teacherTaskChecks/_taskCheckId" */))
const _5cd9e50a = () => interopDefault(import('../src/pages/home/student/_studentId/textbooks/list/_textbookId.vue' /* webpackChunkName: "src/pages/home/student/_studentId/textbooks/list/_textbookId" */))
const _86872e9e = () => interopDefault(import('../src/pages/home/teachers/_teacherId.vue' /* webpackChunkName: "src/pages/home/teachers/_teacherId" */))
const _befd1ef8 = () => interopDefault(import('../src/pages/sign-in/classroom.vue' /* webpackChunkName: "src/pages/sign-in/classroom" */))
const _06a4be5a = () => interopDefault(import('../src/pages/sign-in/teacher.vue' /* webpackChunkName: "src/pages/sign-in/teacher" */))
const _5fae015d = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "src/pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/home",
    component: _625bd2b8,
    name: "home",
    children: [{
      path: "guidance-schedules",
      component: _3ca2b9fc,
      name: "home-guidance-schedules"
    }, {
      path: "students",
      component: _fd9ef15a,
      name: "home-students"
    }, {
      path: "tasks/teacher",
      component: _1f475939,
      name: "home-tasks-teacher"
    }, {
      path: "teachers/list",
      component: _6055d271,
      name: "home-teachers-list"
    }, {
      path: "teachers/shifts",
      component: _3faea1c4,
      name: "home-teachers-shifts"
    }, {
      path: "tasks/teacher/:taskId",
      component: _7b320e74,
      name: "home-tasks-teacher-taskId"
    }, {
      path: "student/:studentId?",
      component: _f2795ff4,
      name: "home-student-studentId",
      children: [{
        path: "exams",
        component: _a8d9b2a4,
        name: "home-student-studentId-exams"
      }, {
        path: "guidance-report",
        component: _39c97ac3,
        name: "home-student-studentId-guidance-report"
      }, {
        path: "info",
        component: _a705ee72,
        name: "home-student-studentId-info"
      }, {
        path: "study-plan",
        component: _64e3c689,
        name: "home-student-studentId-study-plan"
      }, {
        path: "teacher-shared-info",
        component: _4206b3bc,
        name: "home-student-studentId-teacher-shared-info"
      }, {
        path: "guidance-report/histories",
        component: _7b36ada3,
        name: "home-student-studentId-guidance-report-histories"
      }, {
        path: "komas/guidance-koma-calendar",
        component: _5cd7002a,
        name: "home-student-studentId-komas-guidance-koma-calendar"
      }, {
        path: "komas/student-contract-list",
        component: _e10c4566,
        name: "home-student-studentId-komas-student-contract-list"
      }, {
        path: "study-plan/detail",
        component: _602fcbac,
        name: "home-student-studentId-study-plan-detail"
      }, {
        path: "tasks/teacherTaskChecks",
        component: _46bc640b,
        name: "home-student-studentId-tasks-teacherTaskChecks"
      }, {
        path: "tasks/todo-progress",
        component: _08347cec,
        name: "home-student-studentId-tasks-todo-progress"
      }, {
        path: "textbooks/list",
        component: _3ecb236c,
        name: "home-student-studentId-textbooks-list"
      }, {
        path: "textbooks/study-history",
        component: _257f3d15,
        name: "home-student-studentId-textbooks-study-history"
      }, {
        path: "exams/school/score-trend",
        component: _09362c4a,
        name: "home-student-studentId-exams-school-score-trend"
      }, {
        path: "exams/original/:originalExamId?",
        component: _64adb300,
        name: "home-student-studentId-exams-original-originalExamId"
      }, {
        path: "exams/school/:schoolExamId?",
        component: _1d738cf4,
        name: "home-student-studentId-exams-school-schoolExamId"
      }, {
        path: "komas/student-contract-list/:contractId?",
        component: _05eb56b2,
        name: "home-student-studentId-komas-student-contract-list-contractId"
      }, {
        path: "tasks/teacherTaskChecks/:taskCheckId",
        component: _0c0f0f96,
        name: "home-student-studentId-tasks-teacherTaskChecks-taskCheckId"
      }, {
        path: "textbooks/list/:textbookId",
        component: _5cd9e50a,
        name: "home-student-studentId-textbooks-list-textbookId"
      }]
    }, {
      path: "teachers/:teacherId?",
      component: _86872e9e,
      name: "home-teachers-teacherId"
    }]
  }, {
    path: "/sign-in/classroom",
    component: _befd1ef8,
    name: "sign-in-classroom"
  }, {
    path: "/sign-in/teacher",
    component: _06a4be5a,
    name: "sign-in-teacher"
  }, {
    path: "/",
    component: _5fae015d,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
