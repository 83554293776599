
interface Props {
  outlined?: boolean;
  isWide?: boolean;
  mini?: boolean;
  disabled?: boolean;
  on?: Object;
  height?: number | string;
  color?: string;
}
// @ts-ignore
const __sfc_main = {};
__sfc_main.props = {
  outlined: {
    key: "outlined",
    required: false,
    type: Boolean,
    default: false
  },
  isWide: {
    key: "isWide",
    required: false,
    type: Boolean,
    default: false
  },
  mini: {
    key: "mini",
    required: false,
    type: Boolean
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  },
  on: {
    key: "on",
    required: false,
    type: Object,
    default: undefined
  },
  height: {
    key: "height",
    required: false,
    type: [Number, String],
    default: '2rem'
  },
  color: {
    key: "color",
    required: false,
    type: String,
    default: 'primary'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
