import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import { getOptionsForTextbook } from '~/src/infrastructure/apis/graphql/query/queries';
import { OptionsForTextbook } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

interface GetOptionsForTextbook {
  getOptionsForTextbook: OptionsForTextbook;
}

export const TARGET_LAP_OPTIONS = [1, 2, 3] as const;

export const optionsForTextbookRepo = {
  /**
   * 教科書登録時フォームに必要な選択肢をまとめて取得
   */
  fetch: async (jukuId: number, studentId: number) => {
    const { data, errors } = await apolloClient.query<GetOptionsForTextbook>({
      variables: { jukuId, studentId },
      query: gql(getOptionsForTextbook)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getOptionsForTextbook', data.getOptionsForTextbook);
    return { ...data.getOptionsForTextbook };
  }
} as const;
