import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  // bulkRegisterGuidanceKomas,
  bulkUpdateGuidanceKomas,
  createGuidanceKoma,
  deleteGuidanceKoma,
  transferGuidanceKoma,
  updateGuidanceKoma
} from '~/src/infrastructure/apis/graphql/query/mutations';
import { getGuidanceKomasByClassroomByDate } from '~/src/infrastructure/apis/graphql/query/queries';
import {
  CreateGuidanceKomaInput,
  DeleteGuidanceKomaInput,
  GuidanceKoma,
  Mutation,
  Query,
  TransferGuidanceKomaInput,
  UpdateGuidanceKomaInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const guidanceKomaRepo = {
  // 教室と日付でコマ取得
  fetchByClassroomByDate: async (
    classroomId: number,
    komaDate: string
  ): Promise<GuidanceKoma[]> => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { classroomId, komaDate },
      query: gql(getGuidanceKomasByClassroomByDate)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getGuidanceKomasByClassroomByDate variables', classroomId, komaDate);
    logger?.debug(
      'getGuidanceKomasByClassroomByDate data',
      data.getGuidanceKomasByClassroomByDate
    );
    return [...data.getGuidanceKomasByClassroomByDate];
  },

  create: async (input: CreateGuidanceKomaInput): Promise<GuidanceKoma> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createGuidanceKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createGuidanceKoma', data.createGuidanceKoma);
    return { ...data.createGuidanceKoma };
  },

  update: async (input: UpdateGuidanceKomaInput): Promise<GuidanceKoma> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateGuidanceKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateGuidanceKoma input', input);
    logger?.debug('updateGuidanceKoma', data.updateGuidanceKoma);
    return { ...data.updateGuidanceKoma };
  },

  delete: async (input: DeleteGuidanceKomaInput): Promise<GuidanceKoma> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteGuidanceKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteGuidanceKoma', data.deleteGuidanceKoma);
    return { ...data.deleteGuidanceKoma };
  },

  /**
   * 一括編集
   */
  // FIXME:
  // bulkUpdate: async (input: GuidanceKomasBulkUpdateInput) => {
  bulkUpdate: async (input: object) => {
    logger?.debug('bulkUpdateGuidanceKomas input', input);
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(bulkUpdateGuidanceKomas)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    // logger?.debug('bulkUpdateGuidanceKomas data', data.bulkUpdateGuidanceKomas)
    // return data.bulkUpdateGuidanceKomas
  },

  transfer: async (input: TransferGuidanceKomaInput): Promise<GuidanceKoma> => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(transferGuidanceKoma)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('transferGuidanceKoma', data.transferGuidanceKoma);
    return data.transferGuidanceKoma;
  }
} as const;
