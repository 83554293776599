import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  getKomaTemplatesByJukuId,
  getKomaTemplatesByClassroomId
} from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const komaTemplateRepo = {
  // TODO: mock
  fetchByJukuId: async (jukuId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { jukuId },
      query: gql(getKomaTemplatesByJukuId)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getKomaTemplatesByJukuId', data.getKomaTemplatesByJukuId);
    return [...data.getKomaTemplatesByJukuId];
  },
  fetchByClassroomId: async (classroomId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { classroomId },
      query: gql(getKomaTemplatesByClassroomId)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getKomaTemplatesByClassroomId', data.getKomaTemplatesByClassroomId);
    return [...data.getKomaTemplatesByClassroomId];
  }
} as const;
