export const preventZoom = () => {
  // ピンチイン/ピンチアウト無効化
  document.body.addEventListener(
    'touchstart',
    e => {
      if (e.touches && e.touches.length > 1) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
  document.body.addEventListener(
    'touchmove',
    e => {
      if (e.touches && e.touches.length > 1) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
  // ダブルタップでのズーム無効化
  let lastTouch = 0;
  document.documentElement.addEventListener(
    'touchend',
    e => {
      const now = new Date().getTime();
      if (now - lastTouch < 350) {
        e.preventDefault();
      }
      lastTouch = now;
    },
    false
  );
};
