import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PlainDialog', {
    attrs: {
      "id": "classroom-change-dialog",
      "title": "教室切り替え",
      "width": "500",
      "is-opened": _vm.isOpened
    },
    on: {
      "click:close-icon": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('p', {
    staticClass: "mb-4"
  }, [_vm._v("現在" + _vm._s(_vm.classroomStore.get().classroomName) + "でログイン中です。")]), _vm._v(" "), _c(VForm, {
    ref: "classroomChangeFormRef",
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_vm.user.__typename === 'Teacher' ? _c('div', [_c('TextMarkedLeft', {
    staticClass: "classroom-label",
    attrs: {
      "font-size": "text-subtitle-1"
    }
  }, [_c('p', {
    staticClass: "mb-4"
  }, [_vm._v("メイン教室")])]), _vm._v(" "), _c(VRadioGroup, {
    staticClass: "mt-2",
    model: {
      value: _vm.selectedMainClassroom,
      callback: function ($$v) {
        _vm.selectedMainClassroom = $$v;
      },
      expression: "selectedMainClassroom"
    }
  }, _vm._l(_vm.mainClassroomOptions, function (option) {
    return _c(VRadio, {
      key: option.id,
      attrs: {
        "label": option.classroomName,
        "value": option.id
      }
    });
  }), 1), _vm._v(" "), _c('TextMarkedLeft', {
    staticClass: "classroom-label",
    attrs: {
      "font-size": "text-subtitle-1"
    }
  }, [_c('p', {
    staticClass: "mb-4"
  }, [_vm._v("サブ教室")])]), _vm._v(" "), _c(VRadioGroup, {
    staticClass: "mt-2",
    model: {
      value: _vm.selectedSubClassroom,
      callback: function ($$v) {
        _vm.selectedSubClassroom = $$v;
      },
      expression: "selectedSubClassroom"
    }
  }, _vm._l(_vm.subClassroomOptions, function (option) {
    return _c(VRadio, {
      key: option.id,
      attrs: {
        "label": option.classroomName,
        "value": option.id
      }
    });
  }), 1)], 1) : _c('div', [_c('p', {
    staticClass: "text-caption mb-1 ml-1 text-left"
  }, [_vm._v("教室")]), _vm._v(" "), _c(VAutocomplete, {
    attrs: {
      "items": _vm.classroomOptionsStore.get(),
      "no-data-text": _vm.i18n.errors.notExist.$t(),
      "return-object": "",
      "item-value": "id",
      "item-text": "classroomName",
      "placeholder": "教室",
      "dense": "",
      "outlined": "",
      "rules": _vm.classroomValidationRule.classroom
    },
    model: {
      value: _vm.selectedClassroomForManager,
      callback: function ($$v) {
        _vm.selectedClassroomForManager = $$v;
      },
      expression: "selectedClassroomForManager"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "py-3 d-flex justify-end",
    attrs: {
      "id": "student-list-actions"
    }
  }, [_c('TextBtn', {
    attrs: {
      "text": "切り替え",
      "disabled": !_vm.isValid,
      "ripple": false
    },
    on: {
      "click": _vm.confirmChangingClassroom
    }
  })], 1), _vm._v(" "), _c('LazyConfirmationDialog', {
    attrs: {
      "is-opened": _vm.isOpenedConfirmationDialog,
      "message": _vm.confirmationMessage
    },
    on: {
      "click:ok": _vm.changeClassroom,
      "close": _vm.closeConfirmationDialog
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }