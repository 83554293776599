import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { DayjsInterface } from '~/src/domain/interfaces/dayjs';
import { dayjs } from '~/src/infrastructure/dayjs';

declare module '@nuxt/types' {
  interface Context {
    $dayjs: DayjsInterface;
  }
}

const DayjsProvider = defineNuxtPlugin((_, provide) => {
  provide('dayjs', dayjs);
});

export default DayjsProvider;
