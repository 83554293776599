import { gql } from '@apollo/client/core';
import { apolloClient, ERROR_NOT_FOUND } from '~/src/infrastructure/apis/graphql/client';
import {
  createOriginalExam,
  deleteOriginalExam,
  updateOriginalExam
} from '~/src/infrastructure/apis/graphql/query/mutations';
import {
  getOriginalExamsByStudentId,
  getOriginalExamById
} from '~/src/infrastructure/apis/graphql/query/queries';
import {
  Query,
  Mutation,
  OriginalExamCreateInput,
  OriginalExamUpdateInput,
  OriginalExamDeleteInput
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';

export const originalExamRepo = {
  /**
   * query
   */
  // 定期試験単体取得
  fetch: async (id: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { id },
      query: gql(getOriginalExamById)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getOriginalExamById variables', id);
    logger?.debug('getOriginalExamById data', data.getOriginalExamById);
    return { ...data.getOriginalExamById };
  },

  // 定期試験一覧取得
  fetchByStudentId: async (studentId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId },
      query: gql(getOriginalExamsByStudentId)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('getOriginalExamsByStudentId', data.getOriginalExamsByStudentId);
    return [...data.getOriginalExamsByStudentId];
  },

  /**
   * mutation
   */
  // オリジナル試験を作成する
  create: async (input: OriginalExamCreateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(createOriginalExam)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('createOriginalExam input', input);
    logger?.debug('createOriginalExam response', data.createOriginalExam);
    return { ...data.createOriginalExam };
  },

  // オリジナル試験を編集する
  update: async (input: OriginalExamUpdateInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(updateOriginalExam)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('updateOriginalExam input', input);
    logger?.debug('updateOriginalExam response', data.updateOriginalExam);
    return { ...data.updateOriginalExam };
  },

  // オリジナル試験を削除する
  delete: async (input: OriginalExamDeleteInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(deleteOriginalExam)
    });
    if (errors?.length && errors[0]?.message) {
      const errorObject = {
        networkError: { statusCode: 200 },
        message: errors[0].message
      };
      throw errorObject;
    }
    if (!data) throw ERROR_NOT_FOUND;
    logger?.debug('deleteOriginalExam input', input);
    logger?.debug('deleteOriginalExam response', data.deleteOriginalExam);
    return { ...data.deleteOriginalExam };
  }
} as const;
