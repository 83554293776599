
interface Props {
  isOpened: boolean;
  errorMessage: string;
  title?: string;
}
// @ts-ignore
const __sfc_main = {};
__sfc_main.props = {
  isOpened: {
    key: "isOpened",
    required: true,
    type: Boolean
  },
  errorMessage: {
    key: "errorMessage",
    required: true,
    type: String
  },
  title: {
    key: "title",
    required: false,
    type: String,
    default: 'エラー'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
