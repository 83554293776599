import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VAppBar, {
    staticClass: "app-header",
    attrs: {
      "app": "",
      "color": "white",
      "height": "74px",
      "max-height": "74px",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center full-width"
  }, [_c('div', {
    staticStyle: {
      "width": "170px"
    }
  }, [_vm.idShowedBrowserBackButton && !_vm.isGuidanceKomasBulkRegisterMode ? _c(VBtn, {
    attrs: {
      "text": "",
      "color": "light-blue",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        return _vm.toPreviousPage();
      }
    }
  }, [_c('span', {
    staticClass: "mdi mdi-chevron-left text-h5"
  }), _vm._v("\n        戻る\n      ")]) : _vm.$route.name === _vm.pagesPath.home.student._studentId('').guidance_report.histories.$name() ? _c(VBtn, {
    staticClass: "no-active",
    attrs: {
      "text": "",
      "ripple": false,
      "color": "light-blue",
      "to": _vm.studentId ? _vm.pagesPath.home.student._studentId(_vm.studentId).guidance_report.$url() : '',
      "replace": "",
      "nuxt": ""
    }
  }, [_c('span', {
    staticClass: "mdi mdi-chevron-left text-h5"
  }), _vm._v("\n        指導報告へ戻る\n      ")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center align-center",
    staticStyle: {
      "width": "320px"
    }
  }, [_c('span', {
    staticClass: "mr-4 mdi mdi-home text-h5 primary--text",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.toHomePage();
      }
    }
  }), _vm._v(" "), _vm.isStudentPage && _vm.selectedStudent ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('LoginStatusLabel', {
    staticClass: "mr-4",
    attrs: {
      "status": _vm.selectedStudent.latestLoginInfo.currentStatus
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "cursor": "pointer"
    }
  }, [_c('h3', {
    staticClass: "title mr-2 text-ellipsis",
    class: {
      'primary--text': _vm.currentRouteStore.get() !== _vm.pagesPath.home.student._studentId('').info.$name()
    },
    staticStyle: {
      "max-width": "20vw"
    },
    on: {
      "click": _vm.toStudentInfo
    }
  }, [_vm._v("\n            " + _vm._s(_vm.pageStore.get()) + "\n          ")]), _vm._v(" "), _vm.currentRouteStore.get() !== _vm.pagesPath.home.student._studentId('').info.$name() ? _c('span', {
    staticClass: "mdi mdi-chevron-right primary--text text-h5"
  }) : _vm._e()])], 1) : _c('div', [_c('h3', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.pageStore.get()))])])]), _vm._v(" "), _c('UserLogin', {
    attrs: {
      "class-room": _vm.classroomStore.get().classroomName,
      "user-name": _vm.userStore.get().name,
      "is-opened": _vm.isOpenedMenu
    },
    on: {
      "openMenu": function ($event) {
        return _vm.openMenu();
      },
      "toggleDialog": function ($event) {
        return _vm.toggleDialog();
      },
      "signOut": _vm.signOut
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }