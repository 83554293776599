import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  getTextbookTemplatesBySchoolId,
  getTextbookTemplatesByTextbookTagIds
} from '~/src/infrastructure/apis/graphql/query/queries';
import { Query } from '~/src/domain/entities/schema';
import { TextbookTemplateRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/textbook/textbookTemplate';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { DataNotExistError } from '~/src/shared/errors/errors';

export const textbookTemplateRepo: TextbookTemplateRepositoryInterface = {
  fetchBySchoolId: async schoolId => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { schoolId },
      query: gql(getTextbookTemplatesBySchoolId)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getTextbookTemplatesBySchoolId', data.getTextbookTemplatesBySchoolId);
    return ok([...data.getTextbookTemplatesBySchoolId]);
  },

  fetchByTextbookTagIds: async textbookTagIds => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { textbookTagIds },
      query: gql(getTextbookTemplatesByTextbookTagIds)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug(
      'getTextbookTemplatesByTextbookTagIds',
      data.getTextbookTemplatesByTextbookTagIds
    );
    return ok([...data.getTextbookTemplatesByTextbookTagIds]);
  }
} as const;
