import { DATE_FORMATS, dayjs } from '~/src/shared/date';
import {
  TeacherSharedComment,
  TeacherSharedCommentReaction
} from '~/src/domain/entities/schema';

export const formatDate = (date: string) => dayjs(date).format(DATE_FORMATS.fullDateTime);

// リアクションしたManagementUseの表示
export const getReactedManagementUserText = (
  reactions: TeacherSharedCommentReaction[]
): [string, string[]] => {
  const managementUserNames = reactions.map(
    reaction => `${reaction.managementUser.name}先生`
  );
  if (managementUserNames.length > 3) {
    // リアクションしたuserの数が3人より多い場合は省略して表示
    // MEMO: 先生の名前の長さによって変わるかも。mockで扱うデータの場合、3人までが妥当
    const displayedUserNames = managementUserNames.filter((_, index) => index < 3);
    // 省略されて表示される先生を配列で保持
    const hiddenUserNames = managementUserNames.filter(
      name => !displayedUserNames.includes(name)
    );
    return [displayedUserNames.join('、'), hiddenUserNames];
  } else {
    return [managementUserNames.join('、'), []];
  }
};

/**
 * 未読の講師共有コメントのidを取得する
 */
export const getUnreadTeacherSharedCommentIds = (
  comments: TeacherSharedComment[],
  currentUserManagementUserId: number
): number[] =>
  comments
    .filter(
      comment => !checkTeacherSharedCommentIsRead(comment, currentUserManagementUserId)
    )
    .map(comment => comment.id);

const checkTeacherSharedCommentIsRead = (
  comment: TeacherSharedComment,
  currentUserManagementUserId: number
): boolean => {
  const found = comment.teacherSharedCommentReads.find(
    commentRead =>
      commentRead.managementUser.managementUserId === currentUserManagementUserId
  );
  return Boolean(found?.readDate);
};
