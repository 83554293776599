
import { ref } from '@nuxtjs/composition-api';
import { useConfirmationDialog } from '~/src/shared/composables/confirmationDialog';
import { i18n } from '~/src/shared/locales/i18n';
interface Props {
  classRoom: string;
  userName?: string;
  isOpened: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  classRoom: {
    key: "classRoom",
    required: true,
    type: String
  },
  userName: {
    key: "userName",
    required: false,
    type: String
  },
  isOpened: {
    key: "isOpened",
    required: true,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  // 確認ダイアログ
  const {
    isOpenedConfirmationDialog,
    openConfirmationDialog,
    closeConfirmationDialog,
    confirmationMessage
  } = useConfirmationDialog();
  const confirmSigningOut = () => {
    confirmationMessage.value = i18n.confirm.signOut.$t();
    openConfirmationDialog();
  };
  const signOut = () => {
    closeConfirmationDialog();
    emit('signOut');
  };

  /** 教室切り替えダイアログ */
  const isOpenedClassroomChangeDialog = ref(false);
  const openClassroomChangeDialog = () => {
    isOpenedClassroomChangeDialog.value = true;
    emit('toggleDialog');
  };
  const closeClassroomChangeDialog = () => isOpenedClassroomChangeDialog.value = false;
  return {
    isOpenedConfirmationDialog,
    closeConfirmationDialog,
    confirmationMessage,
    confirmSigningOut,
    signOut,
    isOpenedClassroomChangeDialog,
    openClassroomChangeDialog,
    closeClassroomChangeDialog
  };
};
export default __sfc_main;
