import { VApp } from 'vuetify/lib/components/VApp';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VApp, {
    staticClass: "overflow-y-hidden",
    attrs: {
      "dark": ""
    }
  }, [_vm.isShowedNavBtn ? _c('button', {
    staticClass: "primary--text pa-6 d-flex align-center",
    attrs: {
      "id": "classroom-logout-nav"
    },
    on: {
      "click": _vm.logoutClassroom
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left text-h5"
  }), _vm._v(" "), _c('span', [_vm._v(" 教室ログインに戻る")])]) : _vm._e(), _vm._v(" "), _c('nuxt'), _vm._v(" "), _c('Loading', {
    attrs: {
      "is-loading": _vm.isLoading,
      "message": "読み込んでいます"
    }
  }), _vm._v(" "), _c('NotificationSnackBar', {
    attrs: {
      "title": _vm.snackbarItem.title,
      "link": _vm.snackbarItem.link
    },
    on: {
      "close": _vm.snackbarCtx.closeSnackbar
    },
    model: {
      value: _vm.snackbarItem.isOpened,
      callback: function ($$v) {
        _vm.$set(_vm.snackbarItem, "isOpened", $$v);
      },
      expression: "snackbarItem.isOpened"
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.isOpenedErrorDialog,
      "error-message": _vm.errorMessage
    },
    on: {
      "close": _vm.closeErrorDialog
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.offlineErrorDialogStore.get(),
      "error-message": _vm.i18n.errors.offlineError.$t()
    },
    on: {
      "close": _vm.offlineErrorDialogStore.close
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.notAuthenticatedErrorDialogStore.get(),
      "error-message": _vm.i18n.errors.refreshedAuthTokenError.$t()
    },
    on: {
      "close": _vm.closeNotAuthenticatedErrorDialog
    }
  }), _vm._v(" "), _c('ErrorDialog', {
    attrs: {
      "is-opened": _vm.notAuthenticatedClassroomErrorDialogStore.get(),
      "error-message": _vm.i18n.errors.classroomLoginError.$t()
    },
    on: {
      "close": _vm.closeNotAuthenticatedClassroomErrorDialog
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }