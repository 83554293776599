import { err, ok } from 'neverthrow';
import { gql } from '@apollo/client/core';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import { Mutation, TotalTextbookStudyRegisterInput } from '~/src/domain/entities/schema';
import { registerTotalTextbookStudy } from '~/src/infrastructure/apis/graphql/query/mutations';
import { DataNotExistError } from '~/src/shared/errors/errors';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { TotalTextbookStudyRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/contents/textbook/totalTextbookStudy';

export const totalTextbookStudyRepo: TotalTextbookStudyRepositoryInterface = {
  register: async (input: TotalTextbookStudyRegisterInput) => {
    const { data, errors } = await apolloClient.mutate<Mutation>({
      variables: { input },
      mutation: gql(registerTotalTextbookStudy)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('registerTotalTextbookStudy input', input);
    logger?.debug('registerTotalTextbookStudy response', data.registerTotalTextbookStudy);
    return ok(data.registerTotalTextbookStudy);
  }
} as const;
