import { ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
/**
 * cognitoの'The user is not authenticated'エラー時に、
 * storeにユーザーがセットされていれば、
 * ユーザーがアプリ操作時にリフレッシュトークンが切れたと判定し、
 * 認証が切れたことを通知するダイアログを開く。
 * 認証情報とストアのチェックはmiddlewareで行う。
 */

export const useNotAuthenticatedErrorDialogStore = defineStore(
  'notAuthenticatedErrorDialog',
  () => {
    const isOpened = ref(false);

    const get = (): Readonly<boolean> => isOpened.value;

    const open = () => (isOpened.value = true);
    const close = () => (isOpened.value = false);

    return { get, open, close };
  }
);
