
interface Props {
  fontSize?: string;
}
// @ts-ignore
const __sfc_main = {};
__sfc_main.props = {
  fontSize: {
    key: "fontSize",
    required: false,
    type: String,
    default: 'text-body-1'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
