import { gql } from '@apollo/client/core';
import { err, ok } from 'neverthrow';
import { apolloClient } from '~/src/infrastructure/apis/graphql/client';
import {
  getSchoolExamById,
  getSchoolExamsByStudentId,
  getSchoolExamSubjectsForScoreTrend,
  getSchoolExamByStudentTextbookId
} from '~/src/infrastructure/apis/graphql/query/queries';
import {
  ExamSearchOptions,
  Query,
  SchoolExamSubjectForScoreTrendUnion
} from '~/src/domain/entities/schema';
import { logger } from '~/src/framework/plugins/di/logger.di';
import { SchoolExamRepositoryInterface } from '~/src/domain/interfaces/repositories/reco/exam/school/schoolExam';
import { DataNotExistError } from '~/src/shared/errors/errors';

export const schoolExamRepo: SchoolExamRepositoryInterface = {
  fetch: async (id: number, studentId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { id, studentId },
      query: gql(getSchoolExamById)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getSchoolExamById variables', id);
    logger?.debug('getSchoolExamById data', data.getSchoolExamById);
    return ok({ ...data.getSchoolExamById });
  },
  fetchByStudentId: async (studentId: number, examSearchOptions: ExamSearchOptions) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentId, examSearchOptions },
      query: gql(getSchoolExamsByStudentId)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug('getSchoolExamsByStudentId', data.getSchoolExamsByStudentId);
    return ok([...data.getSchoolExamsByStudentId]);
  },
  /**
   * 成績推移用のスキーマの学校試験を取得
   */
  fetchByStudentIdForScoreTrend: async (
    studentId: number,
    studentGradeId: number,
    fiscalYear: number
  ) => {
    const { data, errors } = await apolloClient.query<{
      getSchoolExamSubjectsForScoreTrend: SchoolExamSubjectForScoreTrendUnion[];
    }>({
      variables: { studentId, studentGradeId, fiscalYear },
      query: gql(getSchoolExamSubjectsForScoreTrend)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug(
      'getSchoolExamSubjectsForScoreTrend variables',
      studentId,
      studentGradeId,
      fiscalYear
    );
    logger?.debug(
      'getSchoolExamSubjectsForScoreTrend data',
      data.getSchoolExamSubjectsForScoreTrend
    );
    return ok([...data.getSchoolExamSubjectsForScoreTrend]);
  },
  fetchByStudentTextbookId: async (studentTextbookId: number) => {
    const { data, errors } = await apolloClient.query<Query>({
      variables: { studentTextbookId },
      query: gql(getSchoolExamByStudentTextbookId)
    });
    if (errors?.length) return err(errors);
    if (!data) return err(new DataNotExistError());
    logger?.debug(
      'getSchoolExamByStudentTextbookId data',
      data.getSchoolExamByStudentTextbookId
    );
    return data?.getSchoolExamByStudentTextbookId
      ? ok({ ...data.getSchoolExamByStudentTextbookId })
      : ok(null);
  }
} as const;
