import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VBtn, {
    staticClass: "font-weight-bold px-2",
    class: _vm.colorRed ? 'red--text' : 'primary--text',
    attrs: {
      "disabled": _vm.disabled,
      "loading": _vm.loading,
      "ripple": false,
      "text": "",
      "nuxt": _vm.nuxt,
      "to": _vm.to,
      "replace": _vm.replace
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("default"), _vm._v("\n  " + _vm._s(_vm.text) + "\n")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }